//	Foundation grid: http://foundation.zurb.com/docs/components/grid.html
//------------------------------------------------------------------------------

@import 'foundation-sites/_vendor/sassy-lists/stylesheets/helpers/missing-dependencies';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/helpers/true';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/functions/contain';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/functions/purge';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/functions/remove';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/functions/replace';
@import 'foundation-sites/_vendor/sassy-lists/stylesheets/functions/to-list';

@import 'foundation-sites/scss/global';
@import 'foundation-sites/scss/util/util';
@import 'foundation-sites/scss/grid/grid';

@include foundation-grid;

// Prevent foundation outputting general styles
$include-html-global-classes: false;

// Set rem base to that specced in app.scss
$rem-base: $base-font-size;

// Foundation grid settings
// (Update these to suit)
// @see http://www.gridsystemgenerator.com/gs01.php?GridWidth=1224&GridColumns=12&GridMarginLeft=32&GridMarginRight=32
$row-width-px: 1440px;
$column-gutter-px: 80px;
$total-columns: 12;

// Foundation MQ settings
// These are the default foundation MQs - alter them if necessary
// $small-range: (0em, 40em);
// $medium-range: (40.063em, 64em);
// $large-range: (64.063em, 90em);
// $xlarge-range: (90.063em, 120em);
// $xxlarge-range: (120.063em, 99999999em);

// For non-IE & IE > 8
@if $lte-ie-version > 8 {
	$row-width: rem-calc($row-width-px);
	$column-gutter: rem-calc($column-gutter-px);
} @else {
	$row-width: $row-width-px;
	$column-gutter: $column-gutter-px;
}

@media only screen and (max-width: 1024px) {
  .column, .columns {
    @include rem(padding-left, 30px);
    @include rem(padding-right, 30px);
  }
  .row .row {
	  @include rem(margin-left, -30px);
	  @include rem(margin-right, -30px);
  }
}
@media only screen and (max-width: 768px) {
  .column, .columns {
    @include rem(padding-left, 25px);
    @include rem(padding-right, 25px);
  }
  .row .row {
	  @include rem(margin-left, -25px);
	  @include rem(margin-right, -25px);
  }
  .ipad-fix {
	  width: 100%!important;
  }
}
@media only screen and (max-width: 640px) {
	.row .row {
		margin-left: 0;
		margin-right: 0;
	}
  .column, .columns {
    @include rem(padding-left, 20px);
     @include rem(padding-right, 20px);
    .column, .columns {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.grid-x {
  @include rem(padding-left, 12px);
  @include rem(padding-right, 12px);
  font-size: 0;
}

.grid-x--center {
  text-align: center;
}

.grid-x--flush {
  padding-left: 0;
  padding-right: 0;

  > .grid-x__item {
    padding-left: 0;
    padding-right: 0;
  }
}

.grid-x__item {
  @include rem(padding-left, 12px);
  @include rem(padding-right, 12px);
  vertical-align: top;
  display: inline-block;
  font-size: 1rem;
  text-align: left;
}

.overflow {
  position: relative;
  overflow: hidden;
}
