.divider-header {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;

  h2 {
    @include whitney;
    position: relative;
    z-index: 1;
    display: inline-block;
    background-color: white;
    font-size: 25px;
    letter-spacing: 0.28em;
    text-transform: uppercase;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 500;
  }

  &::after {
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 0;
    transform: translatey(-50%);
    content: '';
    background-color: $grey-f;
  }
}
