.breadcrumbs {
  a,
  span {
    display: inline-block;
    padding-right: 5px;
  }

  a:not(:first-child),
  span {
    padding-left: 5px;
  }
}
