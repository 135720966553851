.tiles {
  display: flex;
  flex-wrap: wrap;
  padding: 13px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.tiles__item {
  display: flex;
  flex-wrap: wrap;
  padding: 13px;

  &:not(.tiles__item--auto) {
    width: 100%;
  }

  @include breakpoint(medium up) {
    &:not(.tiles__item--auto) {
      width: 33.33%;
    }
  }

  @include breakpoint(large up) {
    &:not(.tiles__item--auto) {
      width: 25%;
    }
  }
}

.tiles__inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  .tiles__item--card & {
    border: 1px solid #d9d9d9;
  }
}

.tiles__image {
  position: relative;
  overflow: hidden;
  background-color: $gold;
  background-size: cover;
  background-position: center;
  width: 100%;

  .tiles__item--card & {
    border-bottom: 1px solid #d9d9d9;
    background-color: $white;
  }

  &::after {
    display: block;
    content: '';
    padding-bottom: percentage(229/328);
  }

  img {
    @include absolute-center;
    max-height: 50%;
    max-width: 50%;
  }
}

.tiles__button {
  @include absolute-center;
  width: 100%;
  max-width: 150px;
  opacity: 0;
  transition: 0.25s;

  .tiles__inner:hover &{
    opacity: 1;
  }

  a {
    text-align: center;
    width: 100%;
  }
}

.tiles__content {
  padding: 20px;
  background-color: $white;
  border: 1px solid $white;
  transition: 0.25s;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  .tiles__item:not(.tiles__item--card) .tiles__inner:hover & {
    border-color: $black;
  }

  h3 {
    @include utopia;
    font-size: 22px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 15px
  }

  h4 {
    @include utopia;
    font-size: 18px;
    font-weight: 500;
    font-style: italic;
    margin-bottom: 5px;
    min-height: 28px;
    width: 100%;
  }

  h4 a {
    color: $black;
  }

  p {
    width: 100%;
  }
}

.tiles__hidden {
  display: none;

  .tiles__content.revealed & {
    display: block !important;
  }
}

.tiles__show {
  display: block;

  .tiles__content.revealed & {
    display: none !important;
  }
}

.tiles__link {
  width: 100%;
  align-self: flex-end;
  margin-top: 20px;
  text-align: left;
  margin-right: auto;
  display: inline-block;

  &:focus {
    outline: 0;
  }

  p,
  a,
  button {
    font-size: 16px;
    cursor: pointer;
    color: $red;
    display: inline-block;
    text-decoration: underline;
  }

  &:hover p,
  a:hover,
  button:hover {
    color: $black;
    text-decoration: none;
  }

  .tiles__content.revealed & span:first-child {
    display: none;
  }

  .tiles__content:not(.revealed) & span:last-child {
    display: none;
  }
}
