  .icon-ui-added-logistics {
    $icons: map-merge($icons, (
      icon-ui-added-logistics: (42.08 / 91.63),
    )) !global;
    width: 91.63px;

    &:before {
      padding-top: map-get($icons, icon-ui-added-logistics) * 100%;
    }
  }
  .icon-ui-agreement {
    $icons: map-merge($icons, (
      icon-ui-agreement: (63 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-agreement) * 100%;
    }
  }
  .icon-ui-aid {
    $icons: map-merge($icons, (
      icon-ui-aid: (56 / 72),
    )) !global;
    width: 72px;

    &:before {
      padding-top: map-get($icons, icon-ui-aid) * 100%;
    }
  }
  .icon-ui-aid2 {
    $icons: map-merge($icons, (
      icon-ui-aid2: (63 / 48),
    )) !global;
    width: 48px;

    &:before {
      padding-top: map-get($icons, icon-ui-aid2) * 100%;
    }
  }
  .icon-ui-airline-airport-code {
    $icons: map-merge($icons, (
      icon-ui-airline-airport-code: (66 / 63),
    )) !global;
    width: 63px;

    &:before {
      padding-top: map-get($icons, icon-ui-airline-airport-code) * 100%;
    }
  }
  .icon-ui-arabisation {
    $icons: map-merge($icons, (
      icon-ui-arabisation: (99 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-arabisation) * 100%;
    }
  }
  .icon-ui-arrange-delivery {
    $icons: map-merge($icons, (
      icon-ui-arrange-delivery: (51 / 78),
    )) !global;
    width: 78px;

    &:before {
      padding-top: map-get($icons, icon-ui-arrange-delivery) * 100%;
    }
  }
  .icon-ui-arrow-bottom {
    $icons: map-merge($icons, (
      icon-ui-arrow-bottom: (6.5 / 12),
    )) !global;
    width: 12px;

    &:before {
      padding-top: map-get($icons, icon-ui-arrow-bottom) * 100%;
    }
  }
  .icon-ui-arrow-left {
    $icons: map-merge($icons, (
      icon-ui-arrow-left: (18 / 10),
    )) !global;
    width: 10px;

    &:before {
      padding-top: map-get($icons, icon-ui-arrow-left) * 100%;
    }
  }
  .icon-ui-arrow-right {
    $icons: map-merge($icons, (
      icon-ui-arrow-right: (18 / 10),
    )) !global;
    width: 10px;

    &:before {
      padding-top: map-get($icons, icon-ui-arrow-right) * 100%;
    }
  }
  .icon-ui-assessment {
    $icons: map-merge($icons, (
      icon-ui-assessment: (66 / 47),
    )) !global;
    width: 47px;

    &:before {
      padding-top: map-get($icons, icon-ui-assessment) * 100%;
    }
  }
  .icon-ui-aviation {
    $icons: map-merge($icons, (
      icon-ui-aviation: (61 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-aviation) * 100%;
    }
  }
  .icon-ui-aviation-marine {
    $icons: map-merge($icons, (
      icon-ui-aviation-marine: (48 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-aviation-marine) * 100%;
    }
  }
  .icon-ui-bar-coding {
    $icons: map-merge($icons, (
      icon-ui-bar-coding: (99 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-bar-coding) * 100%;
    }
  }
  .icon-ui-bar-coding-injecting {
    $icons: map-merge($icons, (
      icon-ui-bar-coding-injecting: (99 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-bar-coding-injecting) * 100%;
    }
  }
  .icon-ui-blister-packing {
    $icons: map-merge($icons, (
      icon-ui-blister-packing: (99 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-blister-packing) * 100%;
    }
  }
  .icon-ui-boat {
    $icons: map-merge($icons, (
      icon-ui-boat: (42 / 64.7),
    )) !global;
    width: 64.7px;

    &:before {
      padding-top: map-get($icons, icon-ui-boat) * 100%;
    }
  }
  .icon-ui-book-shipment {
    $icons: map-merge($icons, (
      icon-ui-book-shipment: (51 / 81),
    )) !global;
    width: 81px;

    &:before {
      padding-top: map-get($icons, icon-ui-book-shipment) * 100%;
    }
  }
  .icon-ui-box {
    $icons: map-merge($icons, (
      icon-ui-box: (56.7 / 52.8),
    )) !global;
    width: 52.8px;

    &:before {
      padding-top: map-get($icons, icon-ui-box) * 100%;
    }
  }
  .icon-ui-bulk-retail {
    $icons: map-merge($icons, (
      icon-ui-bulk-retail: (99 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-bulk-retail) * 100%;
    }
  }
  .icon-ui-capability {
    $icons: map-merge($icons, (
      icon-ui-capability: (65 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-capability) * 100%;
    }
  }
  .icon-ui-cargo {
    $icons: map-merge($icons, (
      icon-ui-cargo: (70 / 68),
    )) !global;
    width: 68px;

    &:before {
      padding-top: map-get($icons, icon-ui-cargo) * 100%;
    }
  }
  .icon-ui-cargo-prep {
    $icons: map-merge($icons, (
      icon-ui-cargo-prep: (68.03 / 73.7),
    )) !global;
    width: 73.7px;

    &:before {
      padding-top: map-get($icons, icon-ui-cargo-prep) * 100%;
    }
  }
  .icon-ui-cargo-tracking {
    $icons: map-merge($icons, (
      icon-ui-cargo-tracking: (66 / 63),
    )) !global;
    width: 63px;

    &:before {
      padding-top: map-get($icons, icon-ui-cargo-tracking) * 100%;
    }
  }
  .icon-ui-chair {
    $icons: map-merge($icons, (
      icon-ui-chair: (64.77 / 55.29),
    )) !global;
    width: 55.29px;

    &:before {
      padding-top: map-get($icons, icon-ui-chair) * 100%;
    }
  }
  .icon-ui-chart {
    $icons: map-merge($icons, (
      icon-ui-chart: (66 / 51),
    )) !global;
    width: 51px;

    &:before {
      padding-top: map-get($icons, icon-ui-chart) * 100%;
    }
  }
  .icon-ui-chevron-down {
    $icons: map-merge($icons, (
      icon-ui-chevron-down: (254.92 / 263.53),
    )) !global;
    width: 263.53px;

    &:before {
      padding-top: map-get($icons, icon-ui-chevron-down) * 100%;
    }
  }
  .icon-ui-ck-tri {
    $icons: map-merge($icons, (
      icon-ui-ck-tri: (35 / 90),
    )) !global;
    width: 90px;

    &:before {
      padding-top: map-get($icons, icon-ui-ck-tri) * 100%;
    }
  }
  .icon-ui-clock {
    $icons: map-merge($icons, (
      icon-ui-clock: (66 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-clock) * 100%;
    }
  }
  .icon-ui-close {
    $icons: map-merge($icons, (
      icon-ui-close: (15 / 15),
    )) !global;
    width: 15px;

    &:before {
      padding-top: map-get($icons, icon-ui-close) * 100%;
    }
  }
  .icon-ui-commercial {
    $icons: map-merge($icons, (
      icon-ui-commercial: (51 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-commercial) * 100%;
    }
  }
  .icon-ui-compass {
    $icons: map-merge($icons, (
      icon-ui-compass: (66 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-compass) * 100%;
    }
  }
  .icon-ui-consulting {
    $icons: map-merge($icons, (
      icon-ui-consulting: (349 / 482.1),
    )) !global;
    width: 482.1px;

    &:before {
      padding-top: map-get($icons, icon-ui-consulting) * 100%;
    }
  }
  .icon-ui-consumer_electrics {
    $icons: map-merge($icons, (
      icon-ui-consumer_electrics: (100 / 100),
    )) !global;
    width: 100px;

    &:before {
      padding-top: map-get($icons, icon-ui-consumer_electrics) * 100%;
    }
  }
  .icon-ui-contact {
    $icons: map-merge($icons, (
      icon-ui-contact: (65 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-contact) * 100%;
    }
  }
  .icon-ui-customs-compliance {
    $icons: map-merge($icons, (
      icon-ui-customs-compliance: (74 / 57),
    )) !global;
    width: 57px;

    &:before {
      padding-top: map-get($icons, icon-ui-customs-compliance) * 100%;
    }
  }
  .icon-ui-delivery {
    $icons: map-merge($icons, (
      icon-ui-delivery: (62 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-delivery) * 100%;
    }
  }
  .icon-ui-delivery-procon {
    $icons: map-merge($icons, (
      icon-ui-delivery-procon: (65 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-delivery-procon) * 100%;
    }
  }
  .icon-ui-directional-arrow {
    $icons: map-merge($icons, (
      icon-ui-directional-arrow: (512 / 512),
    )) !global;
    width: 512px;

    &:before {
      padding-top: map-get($icons, icon-ui-directional-arrow) * 100%;
    }
  }
  .icon-ui-download {
    $icons: map-merge($icons, (
      icon-ui-download: (23.33 / 23),
    )) !global;
    width: 23px;

    &:before {
      padding-top: map-get($icons, icon-ui-download) * 100%;
    }
  }
  .icon-ui-downloads {
    $icons: map-merge($icons, (
      icon-ui-downloads: (66 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-downloads) * 100%;
    }
  }
  .icon-ui-earth-cog {
    $icons: map-merge($icons, (
      icon-ui-earth-cog: (65 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-earth-cog) * 100%;
    }
  }
  .icon-ui-education {
    $icons: map-merge($icons, (
      icon-ui-education: (64 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-education) * 100%;
    }
  }
  .icon-ui-email {
    $icons: map-merge($icons, (
      icon-ui-email: (21 / 31),
    )) !global;
    width: 31px;

    &:before {
      padding-top: map-get($icons, icon-ui-email) * 100%;
    }
  }
  .icon-ui-error {
    $icons: map-merge($icons, (
      icon-ui-error: (278.2 / 164),
    )) !global;
    width: 164px;

    &:before {
      padding-top: map-get($icons, icon-ui-error) * 100%;
    }
  }
  .icon-ui-exhibitions {
    $icons: map-merge($icons, (
      icon-ui-exhibitions: (140 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-exhibitions) * 100%;
    }
  }
  .icon-ui-external {
    $icons: map-merge($icons, (
      icon-ui-external: (15 / 15),
    )) !global;
    width: 15px;

    &:before {
      padding-top: map-get($icons, icon-ui-external) * 100%;
    }
  }
  .icon-ui-facebook {
    $icons: map-merge($icons, (
      icon-ui-facebook: (16 / 8),
    )) !global;
    width: 8px;

    &:before {
      padding-top: map-get($icons, icon-ui-facebook) * 100%;
    }
  }
  .icon-ui-fba {
    $icons: map-merge($icons, (
      icon-ui-fba: (51.09 / 382),
    )) !global;
    width: 382px;

    &:before {
      padding-top: map-get($icons, icon-ui-fba) * 100%;
    }
  }
  .icon-ui-fba-arrow {
    $icons: map-merge($icons, (
      icon-ui-fba-arrow: (413 / 362.33),
    )) !global;
    width: 362.33px;

    &:before {
      padding-top: map-get($icons, icon-ui-fba-arrow) * 100%;
    }
  }
  .icon-ui-field360 {
    $icons: map-merge($icons, (
      icon-ui-field360: (195 / 181),
    )) !global;
    width: 181px;

    &:before {
      padding-top: map-get($icons, icon-ui-field360) * 100%;
    }
  }
  .icon-ui-financial {
    $icons: map-merge($icons, (
      icon-ui-financial: (66 / 60),
    )) !global;
    width: 60px;

    &:before {
      padding-top: map-get($icons, icon-ui-financial) * 100%;
    }
  }
  .icon-ui-flag-home {
    $icons: map-merge($icons, (
      icon-ui-flag-home: (230.59 / 177.54),
    )) !global;
    width: 177.54px;

    &:before {
      padding-top: map-get($icons, icon-ui-flag-home) * 100%;
    }
  }
  .icon-ui-freight {
    $icons: map-merge($icons, (
      icon-ui-freight: (66 / 64),
    )) !global;
    width: 64px;

    &:before {
      padding-top: map-get($icons, icon-ui-freight) * 100%;
    }
  }
  .icon-ui-global {
    $icons: map-merge($icons, (
      icon-ui-global: (66 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-global) * 100%;
    }
  }
  .icon-ui-graph-up {
    $icons: map-merge($icons, (
      icon-ui-graph-up: (34 / 58),
    )) !global;
    width: 58px;

    &:before {
      padding-top: map-get($icons, icon-ui-graph-up) * 100%;
    }
  }
  .icon-ui-health_personal_care {
    $icons: map-merge($icons, (
      icon-ui-health_personal_care: (100 / 100),
    )) !global;
    width: 100px;

    &:before {
      padding-top: map-get($icons, icon-ui-health_personal_care) * 100%;
    }
  }
  .icon-ui-healthcare {
    $icons: map-merge($icons, (
      icon-ui-healthcare: (66 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-healthcare) * 100%;
    }
  }
  .icon-ui-history {
    $icons: map-merge($icons, (
      icon-ui-history: (65 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-history) * 100%;
    }
  }
  .icon-ui-homecare {
    $icons: map-merge($icons, (
      icon-ui-homecare: (100 / 100),
    )) !global;
    width: 100px;

    &:before {
      padding-top: map-get($icons, icon-ui-homecare) * 100%;
    }
  }
  .icon-ui-import-export {
    $icons: map-merge($icons, (
      icon-ui-import-export: (39 / 57),
    )) !global;
    width: 57px;

    &:before {
      padding-top: map-get($icons, icon-ui-import-export) * 100%;
    }
  }
  .icon-ui-industries {
    $icons: map-merge($icons, (
      icon-ui-industries: (66 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-industries) * 100%;
    }
  }
  .icon-ui-insurance {
    $icons: map-merge($icons, (
      icon-ui-insurance: (67 / 47),
    )) !global;
    width: 47px;

    &:before {
      padding-top: map-get($icons, icon-ui-insurance) * 100%;
    }
  }
  .icon-ui-inventory-clipboard {
    $icons: map-merge($icons, (
      icon-ui-inventory-clipboard: (44 / 44),
    )) !global;
    width: 44px;

    &:before {
      padding-top: map-get($icons, icon-ui-inventory-clipboard) * 100%;
    }
  }
  .icon-ui-labeling-stickers {
    $icons: map-merge($icons, (
      icon-ui-labeling-stickers: (99 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-labeling-stickers) * 100%;
    }
  }
  .icon-ui-layers {
    $icons: map-merge($icons, (
      icon-ui-layers: (75.61 / 45.21),
    )) !global;
    width: 45.21px;

    &:before {
      padding-top: map-get($icons, icon-ui-layers) * 100%;
    }
  }
  .icon-ui-linkedin {
    $icons: map-merge($icons, (
      icon-ui-linkedin: (16 / 17),
    )) !global;
    width: 17px;

    &:before {
      padding-top: map-get($icons, icon-ui-linkedin) * 100%;
    }
  }
  .icon-ui-liquor_tobacco_nicotine {
    $icons: map-merge($icons, (
      icon-ui-liquor_tobacco_nicotine: (100 / 100),
    )) !global;
    width: 100px;

    &:before {
      padding-top: map-get($icons, icon-ui-liquor_tobacco_nicotine) * 100%;
    }
  }
  .icon-ui-location {
    $icons: map-merge($icons, (
      icon-ui-location: (66 / 48),
    )) !global;
    width: 48px;

    &:before {
      padding-top: map-get($icons, icon-ui-location) * 100%;
    }
  }
  .icon-ui-lock {
    $icons: map-merge($icons, (
      icon-ui-lock: (70.7 / 64.9),
    )) !global;
    width: 64.9px;

    &:before {
      padding-top: map-get($icons, icon-ui-lock) * 100%;
    }
  }
  .icon-ui-logistics {
    $icons: map-merge($icons, (
      icon-ui-logistics: (61 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-logistics) * 100%;
    }
  }
  .icon-ui-logo-au {
    $icons: map-merge($icons, (
      icon-ui-logo-au: (112.94 / 299.57),
    )) !global;
    width: 299.57px;

    &:before {
      padding-top: map-get($icons, icon-ui-logo-au) * 100%;
    }
  }
  .icon-ui-logo-ck {
    $icons: map-merge($icons, (
      icon-ui-logo-ck: (74 / 258),
    )) !global;
    width: 258px;

    &:before {
      padding-top: map-get($icons, icon-ui-logo-ck) * 100%;
    }
  }
  .icon-ui-logo-editable {
    $icons: map-merge($icons, (
      icon-ui-logo-editable: (50 / 175),
    )) !global;
    width: 175px;

    &:before {
      padding-top: map-get($icons, icon-ui-logo-editable) * 100%;
    }
  }
  .icon-ui-logo-fba {
    $icons: map-merge($icons, (
      icon-ui-logo-fba: (74 / 258),
    )) !global;
    width: 258px;

    &:before {
      padding-top: map-get($icons, icon-ui-logo-fba) * 100%;
    }
  }
  .icon-ui-logo-footer {
    $icons: map-merge($icons, (
      icon-ui-logo-footer: (38 / 134.9),
    )) !global;
    width: 134.9px;

    &:before {
      padding-top: map-get($icons, icon-ui-logo-footer) * 100%;
    }
  }
  .icon-ui-logo-icon {
    $icons: map-merge($icons, (
      icon-ui-logo-icon: (37.87 / 52.45),
    )) !global;
    width: 52.45px;

    &:before {
      padding-top: map-get($icons, icon-ui-logo-icon) * 100%;
    }
  }
  .icon-ui-magnifying-glass {
    $icons: map-merge($icons, (
      icon-ui-magnifying-glass: (14 / 16),
    )) !global;
    width: 16px;

    &:before {
      padding-top: map-get($icons, icon-ui-magnifying-glass) * 100%;
    }
  }
  .icon-ui-mail {
    $icons: map-merge($icons, (
      icon-ui-mail: (21 / 31),
    )) !global;
    width: 31px;

    &:before {
      padding-top: map-get($icons, icon-ui-mail) * 100%;
    }
  }
  .icon-ui-marine {
    $icons: map-merge($icons, (
      icon-ui-marine: (37 / 67),
    )) !global;
    width: 67px;

    &:before {
      padding-top: map-get($icons, icon-ui-marine) * 100%;
    }
  }
  .icon-ui-market {
    $icons: map-merge($icons, (
      icon-ui-market: (56 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-market) * 100%;
    }
  }
  .icon-ui-military {
    $icons: map-merge($icons, (
      icon-ui-military: (66 / 57),
    )) !global;
    width: 57px;

    &:before {
      padding-top: map-get($icons, icon-ui-military) * 100%;
    }
  }
  .icon-ui-news-feed {
    $icons: map-merge($icons, (
      icon-ui-news-feed: (18 / 18),
    )) !global;
    width: 18px;

    &:before {
      padding-top: map-get($icons, icon-ui-news-feed) * 100%;
    }
  }
  .icon-ui-our-team {
    $icons: map-merge($icons, (
      icon-ui-our-team: (500 / 500),
    )) !global;
    width: 500px;

    &:before {
      padding-top: map-get($icons, icon-ui-our-team) * 100%;
    }
  }
  .icon-ui-pack-design {
    $icons: map-merge($icons, (
      icon-ui-pack-design: (99 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-pack-design) * 100%;
    }
  }
  .icon-ui-packing {
    $icons: map-merge($icons, (
      icon-ui-packing: (52 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-packing) * 100%;
    }
  }
  .icon-ui-paper-plane {
    $icons: map-merge($icons, (
      icon-ui-paper-plane: (17 / 19),
    )) !global;
    width: 19px;

    &:before {
      padding-top: map-get($icons, icon-ui-paper-plane) * 100%;
    }
  }
  .icon-ui-petcare {
    $icons: map-merge($icons, (
      icon-ui-petcare: (100 / 100),
    )) !global;
    width: 100px;

    &:before {
      padding-top: map-get($icons, icon-ui-petcare) * 100%;
    }
  }
  .icon-ui-photos {
    $icons: map-merge($icons, (
      icon-ui-photos: (66 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-photos) * 100%;
    }
  }
  .icon-ui-pie-chart {
    $icons: map-merge($icons, (
      icon-ui-pie-chart: (66 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-pie-chart) * 100%;
    }
  }
  .icon-ui-plane {
    $icons: map-merge($icons, (
      icon-ui-plane: (39.21 / 41.71),
    )) !global;
    width: 41.71px;

    &:before {
      padding-top: map-get($icons, icon-ui-plane) * 100%;
    }
  }
  .icon-ui-play {
    $icons: map-merge($icons, (
      icon-ui-play: (38 / 38),
    )) !global;
    width: 38px;

    &:before {
      padding-top: map-get($icons, icon-ui-play) * 100%;
    }
  }
  .icon-ui-pouching-sampling {
    $icons: map-merge($icons, (
      icon-ui-pouching-sampling: (99 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-pouching-sampling) * 100%;
    }
  }
  .icon-ui-procurement-consulting {
    $icons: map-merge($icons, (
      icon-ui-procurement-consulting: (65 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-procurement-consulting) * 100%;
    }
  }
  .icon-ui-procurement-process {
    $icons: map-merge($icons, (
      icon-ui-procurement-process: (618.54 / 777),
    )) !global;
    width: 777px;

    &:before {
      padding-top: map-get($icons, icon-ui-procurement-process) * 100%;
    }
  }
  .icon-ui-product-logistic {
    $icons: map-merge($icons, (
      icon-ui-product-logistic: (27.89 / 43.06),
    )) !global;
    width: 43.06px;

    &:before {
      padding-top: map-get($icons, icon-ui-product-logistic) * 100%;
    }
  }
  .icon-ui-promotional-packaging {
    $icons: map-merge($icons, (
      icon-ui-promotional-packaging: (99 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-promotional-packaging) * 100%;
    }
  }
  .icon-ui-question {
    $icons: map-merge($icons, (
      icon-ui-question: (73.3 / 54),
    )) !global;
    width: 54px;

    &:before {
      padding-top: map-get($icons, icon-ui-question) * 100%;
    }
  }
  .icon-ui-questions {
    $icons: map-merge($icons, (
      icon-ui-questions: (70 / 95),
    )) !global;
    width: 95px;

    &:before {
      padding-top: map-get($icons, icon-ui-questions) * 100%;
    }
  }
  .icon-ui-quote {
    $icons: map-merge($icons, (
      icon-ui-quote: (64 / 63.73),
    )) !global;
    width: 63.73px;

    &:before {
      padding-top: map-get($icons, icon-ui-quote) * 100%;
    }
  }
  .icon-ui-reduce-risk {
    $icons: map-merge($icons, (
      icon-ui-reduce-risk: (44 / 44),
    )) !global;
    width: 44px;

    &:before {
      padding-top: map-get($icons, icon-ui-reduce-risk) * 100%;
    }
  }
  .icon-ui-register {
    $icons: map-merge($icons, (
      icon-ui-register: (65 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-register) * 100%;
    }
  }
  .icon-ui-retail_food_drink {
    $icons: map-merge($icons, (
      icon-ui-retail_food_drink: (100 / 100),
    )) !global;
    width: 100px;

    &:before {
      padding-top: map-get($icons, icon-ui-retail_food_drink) * 100%;
    }
  }
  .icon-ui-reverse-logistics {
    $icons: map-merge($icons, (
      icon-ui-reverse-logistics: (58.72 / 52.45),
    )) !global;
    width: 52.45px;

    &:before {
      padding-top: map-get($icons, icon-ui-reverse-logistics) * 100%;
    }
  }
  .icon-ui-sea-freight-schedule {
    $icons: map-merge($icons, (
      icon-ui-sea-freight-schedule: (66 / 63),
    )) !global;
    width: 63px;

    &:before {
      padding-top: map-get($icons, icon-ui-sea-freight-schedule) * 100%;
    }
  }
  .icon-ui-services {
    $icons: map-merge($icons, (
      icon-ui-services: (66 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-services) * 100%;
    }
  }
  .icon-ui-share {
    $icons: map-merge($icons, (
      icon-ui-share: (21 / 24),
    )) !global;
    width: 24px;

    &:before {
      padding-top: map-get($icons, icon-ui-share) * 100%;
    }
  }
  .icon-ui-shrink-wraping {
    $icons: map-merge($icons, (
      icon-ui-shrink-wraping: (99 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-shrink-wraping) * 100%;
    }
  }
  .icon-ui-signpost {
    $icons: map-merge($icons, (
      icon-ui-signpost: (71 / 74),
    )) !global;
    width: 74px;

    &:before {
      padding-top: map-get($icons, icon-ui-signpost) * 100%;
    }
  }
  .icon-ui-sleeving {
    $icons: map-merge($icons, (
      icon-ui-sleeving: (99 / 98),
    )) !global;
    width: 98px;

    &:before {
      padding-top: map-get($icons, icon-ui-sleeving) * 100%;
    }
  }
  .icon-ui-solution {
    $icons: map-merge($icons, (
      icon-ui-solution: (66 / 66),
    )) !global;
    width: 66px;

    &:before {
      padding-top: map-get($icons, icon-ui-solution) * 100%;
    }
  }
  .icon-ui-spiral {
    $icons: map-merge($icons, (
      icon-ui-spiral: (68.32 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-spiral) * 100%;
    }
  }
  .icon-ui-strategic-alliances {
    $icons: map-merge($icons, (
      icon-ui-strategic-alliances: (65 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-strategic-alliances) * 100%;
    }
  }
  .icon-ui-tax {
    $icons: map-merge($icons, (
      icon-ui-tax: (64 / 63.73),
    )) !global;
    width: 63.73px;

    &:before {
      padding-top: map-get($icons, icon-ui-tax) * 100%;
    }
  }
  .icon-ui-tenders {
    $icons: map-merge($icons, (
      icon-ui-tenders: (67 / 67),
    )) !global;
    width: 67px;

    &:before {
      padding-top: map-get($icons, icon-ui-tenders) * 100%;
    }
  }
  .icon-ui-thin-cross {
    $icons: map-merge($icons, (
      icon-ui-thin-cross: (16 / 17),
    )) !global;
    width: 17px;

    &:before {
      padding-top: map-get($icons, icon-ui-thin-cross) * 100%;
    }
  }
  .icon-ui-tick {
    $icons: map-merge($icons, (
      icon-ui-tick: (226.78 / 250.7),
    )) !global;
    width: 250.7px;

    &:before {
      padding-top: map-get($icons, icon-ui-tick) * 100%;
    }
  }
  .icon-ui-timeline {
    $icons: map-merge($icons, (
      icon-ui-timeline: (65 / 65),
    )) !global;
    width: 65px;

    &:before {
      padding-top: map-get($icons, icon-ui-timeline) * 100%;
    }
  }
  .icon-ui-toys {
    $icons: map-merge($icons, (
      icon-ui-toys: (100 / 100),
    )) !global;
    width: 100px;

    &:before {
      padding-top: map-get($icons, icon-ui-toys) * 100%;
    }
  }
  .icon-ui-training {
    $icons: map-merge($icons, (
      icon-ui-training: (62 / 67),
    )) !global;
    width: 67px;

    &:before {
      padding-top: map-get($icons, icon-ui-training) * 100%;
    }
  }
  .icon-ui-travel-events {
    $icons: map-merge($icons, (
      icon-ui-travel-events: (69 / 70),
    )) !global;
    width: 70px;

    &:before {
      padding-top: map-get($icons, icon-ui-travel-events) * 100%;
    }
  }
  .icon-ui-truck {
    $icons: map-merge($icons, (
      icon-ui-truck: (40.51 / 64.84),
    )) !global;
    width: 64.84px;

    &:before {
      padding-top: map-get($icons, icon-ui-truck) * 100%;
    }
  }
  .icon-ui-twitter {
    $icons: map-merge($icons, (
      icon-ui-twitter: (13.4 / 17),
    )) !global;
    width: 17px;

    &:before {
      padding-top: map-get($icons, icon-ui-twitter) * 100%;
    }
  }
  .icon-ui-unit-cost {
    $icons: map-merge($icons, (
      icon-ui-unit-cost: (38 / 40),
    )) !global;
    width: 40px;

    &:before {
      padding-top: map-get($icons, icon-ui-unit-cost) * 100%;
    }
  }
  .icon-ui-unlock {
    $icons: map-merge($icons, (
      icon-ui-unlock: (66 / 61),
    )) !global;
    width: 61px;

    &:before {
      padding-top: map-get($icons, icon-ui-unlock) * 100%;
    }
  }
  .icon-ui-van-plus {
    $icons: map-merge($icons, (
      icon-ui-van-plus: (34 / 54),
    )) !global;
    width: 54px;

    &:before {
      padding-top: map-get($icons, icon-ui-van-plus) * 100%;
    }
  }
  .icon-ui-warning {
    $icons: map-merge($icons, (
      icon-ui-warning: (50.18 / 57.89),
    )) !global;
    width: 57.89px;

    &:before {
      padding-top: map-get($icons, icon-ui-warning) * 100%;
    }
  }
  .icon-ui-waving-hand {
    $icons: map-merge($icons, (
      icon-ui-waving-hand: (60.9 / 51.4),
    )) !global;
    width: 51.4px;

    &:before {
      padding-top: map-get($icons, icon-ui-waving-hand) * 100%;
    }
  }
  .icon-ui-wheel {
    $icons: map-merge($icons, (
      icon-ui-wheel: (64.54 / 64.54),
    )) !global;
    width: 64.54px;

    &:before {
      padding-top: map-get($icons, icon-ui-wheel) * 100%;
    }
  }
  .icon-ui-wheels {
    $icons: map-merge($icons, (
      icon-ui-wheels: (65.89 / 91.15),
    )) !global;
    width: 91.15px;

    &:before {
      padding-top: map-get($icons, icon-ui-wheels) * 100%;
    }
  }
  .icon-ui-youtube {
    $icons: map-merge($icons, (
      icon-ui-youtube: (17 / 24),
    )) !global;
    width: 24px;

    &:before {
      padding-top: map-get($icons, icon-ui-youtube) * 100%;
    }
  }
