// Base font size in pixels, if not already defined.
// Should be the same as the font-size of the html element.
// If the html element's font-size is set to 100%, this would be 16px.
$base-font-size: 16px !default;

//	Type
//------------------------------------------------------------------------------

/**
 * font-smoothing
 * @param $value Accepted values: [on | off]
 * Enable/disable OSX font-smoothing (support: Webkit, Moz)
 */
@mixin font-smoothing($value: on) {
	@if $value == on {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	} @else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

/**
 * css-hyphens
 * @param $val Accepted values: [none | manual | auto]
 * @see http://typeplate.com/#hyphenation
 */
@mixin css-hyphens($value) {
	-webkit-hyphens: $value; // Safari 5.1-6, iOS 4.2-6
	-moz-hyphens: $value; // Firefox 16-20
	-ms-hyphens: $value; // IE10
	-o-hyphens: $value; // Presto
	hyphens: $value;
}

/**
 * em
 * Convert px value to em equivalent
 * @param $value Input px value
 * @param $px-reference Reference px size (font-size of self/parent)
 */
@function em($value, $px-reference: 16px) {
	@return ($value / $px-reference) + em;
}

/**
 * font-face
 * Font-face declaration utility - taken from Bourbon
 * @param $font-family Name to reference in font-family rules
 * @param $file-path Path to font (including filename without extension)
 * @param $weight Weight to reference in font-weight rules (default: normal)
 * @param $style Style to reference in font-style rules (default: normal)
 * @see https://github.com/thoughtbot/bourbon/blob/3dca2ecccaaa3db9cea8d74c8221fbde7e6284d8/app/assets/stylesheets/css3/_font-face.scss
 */
@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
	@font-face {
	font-family: $font-family;
	font-weight: $weight;
	font-style: $style;

		src:	url('#{$file-path}.eot');
		src:	url('#{$file-path}.eot?#iefix')						format('embedded-opentype'),
					url('#{$file-path}.woff')									format('woff'),
					url('#{$file-path}.ttf')									format('truetype'),
					url('#{$file-path}.svg##{$font-family}')	format('svg');
	}
}

/**
 * placeholder
 * @todo Add standard unprefixed version
 */
@mixin placeholder {
	&::-webkit-input-placeholder { @content; }
	&:-moz-placeholder { @content; } // FF < 19
	&::-moz-placeholder { @content; } // FF >= 19
	&:-ms-input-placeholder { @content; }
}

/**
 * selection
 */
@mixin selection {
	&::-moz-selection { @content; }
	&::selection { @content; }
}

//	Layout
//------------------------------------------------------------------------------

/**
 * clearfix
 */
@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

/**
 * center-justify
 */
@mixin center-justify-parent {
	text-align: justify;

	&:after {
		@include inline-block-gap-fix-parent;
		content: "";
    display: inline-block;
		width: 100%;
	}
}

/**
 * center-justify-child
 */
@mixin center-justify {
	@include inline-block-gap-fix;
}

/**
 * inline-block-gap-fix-parent
 * @see https://github.com/yui/pure/blob/master/src/grids/css/grids-core.css
 */
@mixin inline-block-gap-fix-parent {
	letter-spacing: -0.31em;
	text-rendering: optimizeSpeed;
	font-family: "FreeSans", "Arimo", "Droid Sans", "Helvetica", "Arial", sans-serif;

	// http://www.ractoon.com/2013/04/internet-explorer-9-font-glitch/
	.lte-ie9 & {
		font-family: "FreeSans", "Arimo", "Droid Sans", "Arial", sans-serif;
	}

	.opera-only :-o-prefocus, & {
		word-spacing: -0.43em;
	}
}

/**
 * inline-block-gap-fix-style
 */
@mixin inline-block-gap-fix-style {
	letter-spacing: normal;
	word-spacing: normal;
	text-rendering: auto;
}

/**
 * inline-block-gap-fix
 * @see https://github.com/yui/pure/blob/master/src/grids/css/grids-core.css
 */
@mixin inline-block-gap-fix {
	@include inline-block-gap-fix-style;
	display: inline-block;
}

/**
 * vertical-center-parent
 */
@mixin v-center-parent {
	@include inline-block-gap-fix-parent;
	height: 100%;

	&:before {
		@include inline-block-gap-fix;
		content: "";
		height: 100%;
		vertical-align: middle;
	}
}

/**
 * vertical-center
 */
@mixin v-center {
	@include inline-block-gap-fix;
	vertical-align: middle;
}

/**
 * visually-hidden
 */
@mixin visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

//	Other
//------------------------------------------------------------------------------

/**
 * hardware-acceleration
 */
@mixin hardware-acceleration {
	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);
	will-change: transform, opacity;
}

/**
 * un-hardware-acceleration
 */
@mixin un-hardware-acceleration {
	backface-visibility: visible;
	transform: none;
	will-change: auto;
}

/**
 * disable-interaction
 */
@mixin disable-interaction {
	cursor: default;
	user-select: none;
	pointer-events: none;
}

/**
 * enable-interaction
 */
@mixin enable-interaction {
	cursor: auto;
	user-select: all;
	pointer-events: all;
}

/**
 * Apply rules only to hi-res devices
 */
@mixin retina {
	@media
	(-webkit-min-device-pixel-ratio: 1.25),
	(min-resolution: 120dpi) {
		@content;
	}
}

/**
 * opacity
 */
@mixin opacity($value, $important: "") {
	@if $important == true {
		$important: " !important";
	}
	$filter-value: $value * 100;

	opacity: $value #{$important};
	filter: alpha(opacity=$filter-value) #{$important};
}

/**
 * Apply rules only to Firefox
 */
@mixin firefox {
	body:not(:-moz-handler-blocked) & {
		@content;
	}
}

//	Units
//------------------------------------------------------------------------------

$guss-rem-baseline: $base-font-size;

/**
 * strip-units
 * @param $number
 * @see http://stackoverflow.com/a/12335841
 */
@function strip-units($number) {
	@return $number / ($number * 0 + 1);
}


/**
 * rem
 * @param $px-value
 */
@function rem($px-value) {
	@return $px-value / ($base-font-size / (1rem * 1));
}

/**
 * rem
 * @see https://github.com/bitmanic/rem
 */
@mixin rem($property, $px-values) {
	// Print the first line in pixel values
	#{$property}: $px-values;

	// If there is only one (numeric) value, return the property/value line for it.
	@if type-of($px-values) == "number" {
		#{$property}: rem($px-values);
	} @else {
		// Create an empty list that we can dump values into
		$rem-values: ();

		@each $value in $px-values {
			// If the value is zero or not a number, return it
			@if $value == 0 or type-of( $value ) != "number" {
				$rem-values: append($rem-values, $value);
			} @else {
				$rem-values: append($rem-values, rem($value));
			}
		}

		// Return the property and its list of converted values
		#{$property}: $rem-values;
	}
}

// Media Query Mixin

@mixin mq($point, $IE9: false, $query1: min, $query2: width) {
		@if $IE9 == true{
				.lt-ie9 & {
						@content;
				}
		}

		@media (#{$query1}-#{$query2}: $point / $doc-font-size +em) {
				@content;
		}
}

// HiDPI Media Query Mixin

@mixin hidpi {
	$hidpi-debug: false !default;
	@if $hidpi-debug {
		@content;
	} @else {
		@media (-webkit-min-device-pixel-ratio: 1.3),
					(-o-min-device-pixel-ratio: 2.6/2),
					(min--moz-device-pixel-ratio: 1.3),
					(min-device-pixel-ratio: 1.3),
					(min-resolution: 1.3dppx) {
			@content;
		}
	}
}


// Clearfix Mixin
// Mixin over Extend cos GZip

@mixin clearfix {
  &:after {
    content: " ";
    display: block;
    clear: both;
  }
}


// Opacity

@mixin opacity($value, $important: "") {
  @if $important == true {
    $important: " !important";
  }
  $filter-value: $value * 100;
  opacity: $value #{$important};
  filter: alpha(opacity=$filter-value) #{$important};
}

%opacity-0 {
  @include opacity(0);
}

%opacity-1 {
  @include opacity(1);
}

%opacity-2 {
  @include opacity(0.2);
}

%opacity-3 {
  @include opacity(0.3);
}

%opacity-4 {
  @include opacity(0.4);
}

%opacity-5 {
  @include opacity(0.5);
}

%opacity-6 {
  @include opacity(0.6);
}

%opacity-7 {
  @include opacity(0.7);
}

%opacity-8 {
  @include opacity(0.8);
}

%opacity-9 {
  @include opacity(0.9);
}


//  Centering
//------------------------------------------------------------------------------

@mixin center($x: true, $y: true) {
  @if $x == true {
    left: 50%;
    $x: -50%;
  } @else {
    $x: 0;
  }
  @if $y == true {
    top: 50%;
    $y: -50%;
  } @else {
    $y: 0;
  }
  transform: translate($x, $y);

}

//  Text
//------------------------------------------------------------------------------
@mixin hellip($width) {
   white-space: nowrap;
   width: $width;                 /* IE6 needs any width */
   overflow: hidden;              /* "overflow" value must be different from  visible"*/
   -o-text-overflow: ellipsis;    /* Opera < 11*/
   text-overflow:    ellipsis;    /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
}


%background-cover {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}


//  scrollbars
//------------------------------------------------------------------------------
@mixin scrollbars($size, $background-color) {
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
    background: darken($background-color, 2);
  }

  ::-webkit-scrollbar-track {
    background: rgba($background-color, .5);
  }

  & {
	  scrollbar-face-color: darken($background-color, 2);
    scrollbar-track-color: rgba($background-color, .5);
  }
}


//  Images
//------------------------------------------------------------------------------
/* How to use it: @include img-bg('/media/img/assets/icon-secret-in-game', 20px 16px, center 10px); */

@mixin bg-img($image, $size, $pos: center, $format: png) {
	background-repeat: no-repeat;
	background-position: $pos;
	background-size: $size;


	@if $format == "svg" {
		background-image: url($image+'.png');
		background-image: url($image+'.svg'), none;
	} @else {
		background-image: url($image+'.'+$format);
	}

	@if $format != "svg" {
		@media 	(min--moz-device-pixel-ratio: 1.3),
	         	(-o-min-device-pixel-ratio: 2.6/2),
			 	(-webkit-min-device-pixel-ratio: 1.3),
			 	(min-device-pixel-ratio: 1.3),
			 	(min-resolution: 1.3dppx) { //detect retina
	    background-image: url($image+'@2x.'+$format);
	    background-size: $size;
	  }
	}
}

.relative {
  position: relative;
}
