.tick {
  position: relative;
  padding-top: 50px;

  @include mq($iphone, false, max) {
    padding-top: 0;
  }
}

.tick__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  animation: risingTick 3s ease-in-out;
  animation-fill-mode: backwards;
  will-change: opacity, transform;

  @include mq($iphone, false, max) {
    position: relative;
    animation: none;
  }

  img,
  svg {
    width: 650px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: -75px;

    @include mq($ipad, false, max) {
      width: 400px;
      margin-top: 0;
    }
  }

  polygon {
    animation: fadingTick 2s 2s ease-in-out;
    animation-fill-mode: backwards;
  }
}

@keyframes risingTick {
  0% { opacity: 0; transform: translateY(100px);}
  100% { opacity: 1; transform: translateY(0);}
}

@keyframes fadingTick {
  0% { opacity: 0;}
  100% { opacity: 1;}
}
