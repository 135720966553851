@mixin whitney($weight: 400, $style: normal) {
  font-family: 'Whitney A', 'Whitney B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  @if $weight == 400 or $weight == 'normal' {
    font-weight: 400; font-style: normal;
  } @else if $weight == 500 or $weight == 'medium' {
    font-weight: 500;
  } @else if $weight == 600 or $weight == 'semi' or $weight == 'semibold' {
    font-weight: 600;
  } @else if $weight == 700 or $weight == 'bold' {
    font-weight: 700;
  }
  @if $style == 'normal' {
    font-style: normal;
  } @else {
    font-style: italic;
  }
}

@mixin opensans($weight: 400, $style: normal) {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  @if $weight == 600 or $weight == 'semibold' or $weight == 'bold' {
    font-weight: 600;
  }
  @if $style == 'normal' {
    font-style: normal;
  } @else {
    font-style: italic;
  }
}

@mixin utopia($weight: 400, $style: normal) {
  font-family: "utopia-std", Georgia, serif;
  @if $weight == 400 or $weight == 'regular' or $weight == 'normal' {
    font-weight: 400;
  } @else if $weight == 600 or $weight == 'semi' or $weight == 'semibold' {
    font-weight: 600;
  } @else if $weight == 700 or $weight == 'bold' {
    font-weight: 700;
  }
  @if $style == 'normal' {
    font-style: normal;
  } @else {
    font-style: italic;
  }
}
