.divider {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;

  .divider-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 0 20px;
    height: auto;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    z-index: 10;
  }

  .icon {
    color: darken($grey-f, 15);
    width: 200px;
    height: 80px;
  }

  &::after {
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 0;
    transform: translatey(-50%);
    content: '';
    background-color: darken($grey-f, 15);
  }
}
