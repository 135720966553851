.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.service {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin-bottom: 2.5rem;
}

.service-heading {
  font-weight: 500!important;
}

.service-inner {
  position: relative;
  display: block;
  width: 100%;
  padding: 60px 40px 50px 40px;
  border: 7px solid #f2f2f2;
  color: inherit;
  min-height: 260px;

  .icon {
    height: 60px;
    height: 3.75rem;
    color: #e2001a;
  }

  &--large .icon {
    height: 90px;
    width: 140px;
    margin-bottom: 15px;
  }
}

.misc-list {
  min-height: 230px;

  .icon {
    min-width: 80px!important;
  }
}

.service-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  text-align: center;
  background-color: #f2f2f2;
  overflow: auto;
  transition: 0.25s;
  opacity: 0;
  visibility: hidden;

  @include mq($iphone, false, min) {
    .service-inner:hover & {
      opacity: 1;
      visibility: visible;
    }
  }

  @include mq($iphone, false, max) {
    .service-inner.is-active & {
      opacity: 1;
      visibility: visible;
    }
  }

  h1 {
    color: #e2001a;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
  }
}
