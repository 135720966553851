$icons: () !global;

// Get relative icon width based on aspect ratio
@function icon-width($width, $icon) {
  @return $width + ($width * (1 / map-get($icons, $icon)));
};

// Get icon width for a certain height (necessary to scale by width due to aspect ratio layout)
@function icon-height($height, $icon) {
  @return $height / map-get($icons, $icon);
};

.icon {
  position: relative;
  display: inline-block;
  max-width: 100%;

  // Top padding is applied to :before in Sass files generated by gulp sprites
  // task, providing an intrinsic aspect ratio box in which to position the SVG
  // @see sprites/tmpl.scss for the Sass template used for the generation
  &::before {
    display: block;
    content: '';
  }

  // Transparent layer above SVG to prevent event bubbling/delegation issues
  // when clicking an icon
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  // Position SVG absolutely inside intrinsic aspect ratio box
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .btn & {
    vertical-align: middle;
  }
}

@import 'ui/sprite';
