//	Print stylesheet
//------------------------------------------------------------------------------
.wow {
	animation-name: none!important;
	visibility: visible!important;
	display: block!important;
}

body {
	margin: 0!important;
	padding: 0!important;
	background-color: #FFF!important;
	color: #000!important;
	width: 100%!important;
}

.l-footer, .l-header, .block-cta, .block-suppliers, .side-nav-container {
	display: none!important;
}

#internal {
  table.table-internal {
    tr {
      background-color: none;

      &:nth-child(odd) {
        background-color: #FFF;
      }

      & > td {
        width: 70%;

        &:first-child {
          width: 30%;
        }
      }
    }
  }

  @media (max-width: 48em) {
    .table-events tbody td {
      float: left;
    }
  }
}

.news-more,
.cookies,
.list-share,
.list-links-inline,
.block-other-news {
  display: none;
}
