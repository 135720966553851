.dropdown {
  margin-left: 0 !important;
  margin-right: 0 !important;
  transition: 0.25s;

  @include mq($ipad, false, min) {
    padding-right: 15px;
    padding-top: 8px;
    padding-left: 15px;

    &.open {
      background-color: $black;
    }
  }
}

.dropdown__link {
  position: relative;
  transition: 0.25s;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .17em;
  width: 100%;
  padding-right: 15px;
  transition: 0.25s;

  @include mq($ipad, false, min) {
    .dropdown.open > .dropdown__trigger > & {
      color: $white;
    }

    .dropdown__trigger > & {
      position: relative;
      z-index: 2;
    }
  }

  .dropdown:not(.js-subnav) & {
    padding-right: 0 !important;
  }
}

.dropdown__inner {
  z-index: 10;
  display: none;
  opacity: 0;
  visibility: hidden;
  color: black;
  transition: 0.25s;

  .highlight & a {
    color: black !important;
  }

  // .dropdown:first-child &,
  .dropdown.open & {
    display: block;
    visibility: visible;
    opacity: 1;
  }

  @include mq($ipad, false, min) {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translatex(-50%);
    width: 100%;
    display: block;
  }
}

.dropdown__close {
  position: absolute !important;
  padding: 0!important;
  top: 15px;
  right: 15px;

  @include mq($ipad, false, max) {
    display: none !important;
  }

  i {
    width: 16px;
  }
}

// Dropdown content
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

.dropdown__list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  background-color: white;
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  @include mq($ipad, false, min) {
    padding: 20px;
    border-bottom: 8px solid $black;
    border-left: 8px solid $black;
    border-right: 8px solid $black;
  }
}

.dropdown__featured,
.dropdown__list__links {
  width: 100%;

  @include mq($ipad, false, min) {
    padding: 20px;
  }
}

.dropdown__list__links {
  @include mq($ipad, false, max) {
    background-color: #f2f2f2;
  }

  @include mq($ipad, false, min) {
    width: 60%;
    border-right: 1px solid #d9d9d9;
  }
}

.dropdown__featured {
  display: none;

  @include mq($ipad, false, min) {
    display: block;
    width: 40%;
  }

  > * + * {
    margin-top: 20px;
  }

  h3 {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  p {
    line-height: 1.7;
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
  }

  img {
    width: 100%;
  }

  a {
    font-size: 16px;
    display: inline-block;
    border-bottom: 1px solid;
    text-transform: none;
    letter-spacing: normal;
    padding-right: 0 !important;
    color: $red;
  }
}

// Dropdown trigger
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

.dropdown__trigger {
  position: relative;
  display: block;
  width: 100%;

  @include mq($ipad, false, max) {
    padding-left: 10px;
    border-bottom: 1px solid #d9d9d9;
  }

  @include mq($ipad, false, min) {
    display: inline-block;
    width: auto;

    .dropdown__item & {
      text-transform: none;
      font-weight: 700;
      letter-spacing: 0;
      font-size: 16px;
    }

    .dropdown__item.open & {
      margin-bottom: 5px;
    }
  }

  .dropdown__trigger__label {
    position: relative;
    text-transform: normal;
    display: inline-block;
    padding: 3px 13px 5px 0;
  }

  a {
    color: black;
  }

  .dropdown.js-subnav > & > a {
    @include mq($ipad, false, min) {
      padding-right: 13px;
    }
  }
}

.dropdown__trigger__chevron {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  height: calc(100% + 1px);
  z-index: 1;
  cursor: pointer;

  @include mq($ipad, false, min) {
    .dropdown__list__links & {
      display: none;
    }
  }

  @include mq($ipad, false, max) {
    right: 0;
    width: 30px;
    height: 100%;
    background-color: $grey-f;

    .highlight .dropdown > .dropdown__trigger & {
      background-color: $black-50;
    }
  }

  &:before {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 0;
    width: 0;
    border-width: 8px 4px 0px 4px;
    border-color: red transparent transparent transparent;
    border-style: solid;
    content: '';
    transition: transform .3s cubic-bezier(0.070, 0.635, 0.415, 0.990);
    transform: translate(-50%, -50%);
  }

  @include mq($ipad, false, min) {
    &::before {
      right: -5px;
      left: auto;
    }

    .dropdown > .dropdown__trigger &::before {
      top: 33%;
    }
  }
}

// Dropdown item
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ //

.dropdown__item {
  position: relative;
  display: block !important;
  margin: 0 !important;

  @include mq($ipad, false, min) {
    .dropdown__list__links > & {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #d9d9d9;
      padding-right: 25px;
      max-width: 480px;
    }
  }

  &:hover > a,
  &:hover > button {
    color: $red;
  }

  a,
  button {
    position: relative;
    text-transform: normal;
    display: inline-block;
    padding: 3px 13px 5px 0;
  }
}

.dropdown__item__dropdown {
  z-index: 10;
  transition: 0.25s;
  display: none;

  .dropdown__item.open & {
    display: block;
  }

  @include mq($ipad, false, min) {
    display: block;
    margin-bottom: 5px;
  }

  a {
    position: relative;
    font-weight: normal;
    display: block;
    width: 100%;
    letter-spacing: 0;
    text-transform: none;
    padding: 5px 5px 6px 25px;
    transition: 0.25s;
    font-size: 14px;

    @include mq($ipad, false, min) {
      font-size: 16px;
    }
  }

  a:before {
    position: absolute;
    left: 10px;
    top: 50%;
    height: 0;
    width: 0;
    border-width: 8px 4px 0px 4px;
    border-color: #979797 transparent transparent transparent;
    border-style: solid;
    transition: transform .3s cubic-bezier(0.070, 0.635, 0.415, 0.990);
    transform: translate(0, -50%) rotate(-90deg);

    @include mq($ipad, false, min) {
      content: '';
    }
  }

  a:hover {
    background-color: #f2f2f2;
    color: $red;
  }

  a:hover::before {
    border-color: $black transparent transparent transparent;
  }
}
