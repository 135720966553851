.service-columned-content {
  ul > li::before {
    position: relative;
    top: 2px;
    font-size: 1.5rem;
    line-height: 1;
  }

  li {
    border-bottom: 1px solid #f2f2f2;
  }
}

.service-columned-content__image {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 15px;

  h2 {
    @include whitney;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    color: white;
    font-size: 44px;
    z-index: 1;
    text-shadow: 5px 5px 5px rgba(black, 0.35);
  }
}
