.hover-blocks {}

.hover-blocks__item {
  position: relative;
  color: white;
  overflow: hidden;

  @include breakpoint(small down) {
    padding-bottom: 0 !important;
    height: auto !important;
    min-height: 250px;
  }
}

.hover-blocks__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 300px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .has-header &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(88, 87, 84, 0.25);
    transition: 0.25s;
  }
}

.hover-blocks__header {
  z-index: 1;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  transition: 0.25s;

  .hover-blocks__item:hover & {
    opacity: 0;
    visibility: hidden;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 2px;
    content: '';
    background-color: currentColor;
    width: 100px;
  }

  i {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75px;
    margin-bottom: 10px;

    @include breakpoint(small down) {
      width: 50px;
    }
  }

  h3 {
    @include whitney;
    position: relative;
    z-index: 1;
    display: inline-block;
    font-size: 30px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    font-weight: 600;
  }
}

.hover-blocks__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  transition: 0.25s;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  background-color: rgba($red, 0.5);

  @include breakpoint(small down) {
    position: relative;
    height: auto;
  }

  .hover-blocks__item:hover & {
    opacity: 1;
    visibility: visible;
  }

  .hover-blocks__item--center & {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  h3,
  p {
    @include utopia;
    margin-bottom: 15px;
    width: 100%;
  }

  h3 {
    font-size: 30px;
  }

  p {
    max-width: 500px;
    font-weight: 16px;
  }

  a {
    border: 2px solid;
  }
}
