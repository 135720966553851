.flow-chart {
  position: relative;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.flow-chart__header {
  padding: 20px 20px 0;

  h2 {
    font-weight: normal;
    text-transform: uppercase;
  }

  @include breakpoint(medium only) {
    padding: 10px 10px 0;
  }
}

.flow-chart__inner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(12, 8.333%);
  padding: 10px;

  @include breakpoint(medium only) {
    padding: 5px;
  }

  @include breakpoint(medium up) {
    @supports (display: grid) {
      display: grid;
    }
  }
}

.flow-chart__item {
  @include utopia;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
  grid-column: span 12;

  @include breakpoint(medium only) {
    padding: 5px;
  }

  @include breakpoint(medium up) {
    width: 33.33%;
    grid-column: span 4;

    &:nth-child(1) {
      order: 1;
      grid-column-start: 0;
      grid-column-end: 5;
      grid-row-start: 0;
      grid-row-end: 1;
    }

    &:nth-child(2) {
      order: 4;
      grid-column-start: 0;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &:nth-child(3) {
      order: 5;
      grid-column-start: 5;
      grid-column-end: 9;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &:nth-child(4) {
      order: 2;
      grid-column-start: 5;
      grid-column-end: 9;
      grid-row-start: 0;
      grid-row-end: 1;
    }

    &:nth-child(5) {
      order: 3;
      grid-row: span 2;
    }

    @supports (display: grid) {
      width: auto;
    }
  }
}

.flow-chart__item__inner {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 26px;
  border: 1px solid #d9d9d9;
  background-color: $white;

  &:hover {
    border-color: $red;
  }

  i {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    color: red;
    width: 70px;
    z-index: 1;
  }

  .flow-chart__item:nth-child(5) & i {
    display: none;
  }

  @include breakpoint(medium up) {
    i {
      bottom: auto;
      left: auto;
    }

    .flow-chart__item:nth-child(1) & i {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 60%);
    }

    .flow-chart__item:nth-child(2) & i {
      right: 0;
      top: 50%;
      transform: translate(60%, -50%) rotate(-90deg);
    }

    .flow-chart__item:nth-child(3) & i {
      top: 0;
      left: 50%;
      transform: translate(-50%, -60%) rotate(180deg);
    }

    .flow-chart__item:nth-child(4) & i {
      right: 0;
      top: 50%;
      transform: translate(60%, -50%) rotate(-90deg);
    }

    .flow-chart__item:nth-child(5) & {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 30%;
      width: calc(33.33% - 30px);
      height: 90%;
      height: calc(100% - 40px);

      @supports (display: grid) {
        position: static;
        height: auto;
        width: 100%;
      }
    }
  }

  @include breakpoint(medium only) {
    padding: 13px;

    i {
      width: 45px;
    }

    .flow-chart__item:nth-child(5) & {
      top: 10px;
      right: 10px;
      width: calc(33.33% - 15px);
      height: calc(100% - 20px);

      @supports (display: grid) {
        position: static;
        height: auto;
        width: 100%;
      }
    }
  }

  > div {
    width: 100%;
  }

  * + *:not(i) {
    margin-top: 20px;
  }

  h3 {
    font-size: 22px;
    font-weight: 18px;
    line-height: 1.5;
    margin-bottom: 10px
  }

  h4 {
    font-weight: normal;
    font-size: 18px;
    font-style: italic;
    margin-bottom: 30px;
  }

  h3 + h4  {
    margin-top: 0;
  }
}
