// sass-lint:disable-all

//  Setup
//------------------------------------------------------------------------------
$base-font-size: 17px; // Used to set intial rem scale in _utilities.scss
$lte-ie-version: 999 !default;
@import "utilities";
@import "reset";
@import "fonts";
@import "grid";
@import "icons";
@import "variables";

@import "modules/tiles";
@import "modules/half-map";
@import "modules/breadcrumbs";
@import "modules/typed";
@import "modules/flow-chart";
@import "modules/mosaicGallery";
@import "modules/info-columns";
@import "modules/accredations";
@import "modules/freeform";
@import "modules/hover-lists";
@import "modules/icon-group";
@import "modules/icon-blocks";
@import "modules/overlay";
@import "modules/captcha";
@import "modules/divider";
@import "modules/divider-header";
@import "modules/dropdown";
@import "modules/layout";
@import "modules/main-nav";
@import "modules/mosaic";
@import "modules/previews";
@import "modules/services";
@import "modules/top-nav";
@import "modules/tabs";
@import "modules/header-list";
@import "modules/tick";
@import "modules/service-image-grid";
@import "modules/service-columned-content";
@import "modules/full-banner";
@import "modules/banner";
@import "modules/case-studies";
@import "modules/hover-blocks";
@import "modules/table-suppliers";

pre {
  font-size: 12px;
  border: 2px solid grey;
  width: auto;
  border-left: 12px solid green;
  border-radius: 5px;
  padding: 14px;

  /* Fixed line height */
  line-height: 24px;

  /* Use repeating-linear-gradient for background image */
  background-image: repeating-linear-gradient(180deg, #eee 0px, #eee 24px, #fff 24px, #fff 48px);

  /* Offset the background along the y-axis by top padding */
  background-position: 0 14px;
}

.hollow-component {
  border-top: 3px solid red;
}

.hollow-component__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px 0;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  background-color: lighten(red, 48);

  h2 {
    display: inline-block;
  }

  a {
    display: inline-block;
    color: black;
    border-bottom: 2px solid red;
    margin-bottom: 20px;
  }
}

.hollow-list {
  ul {
    column-count: 3;
    column-gap: 50px;
  }
}

.black-link {
  &,
  h3 {
    color: black !important;
  }
}

//  Base
//------------------------------------------------------------------------------
html {
  height: 100%;
  // background-color: ;
  // -webkit-tap-highlight-color: ;

  &.is-scroll-locked {
    overflow-x: initial;
    overflow-y: initial;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  > * {
    width: 100%;
  }

  html.is-scroll-locked & {
    overflow: hidden;
  }
}

main {
  flex-grow: 1;
}

//  Scale
//------------------------------------------------------------------------------
html {
  font-size: $base-font-size;

  // Use ems for media queries
  // See http://blog.cloudfour.com/the-ems-have-it-proportional-media-queries-ftw/
  // (This MQ is just an example - can be removed)
  @media only screen and (min-width: em(1280px)) {
    font-size: $base-font-size;
  }
}

//  Type
//------------------------------------------------------------------------------
body {
  @include whitney;
  line-height: 1.5;
}

body,
button,
select,
input,
textarea {
  @include font-smoothing(on);
  text-rendering: optimizeLegibility;
  // line-height: ;
  // color: ;
}

//  Media
//------------------------------------------------------------------------------
img {
  display: block;
  max-width: 100%;
  height: auto;
}

// For testing: share buttons
//------------------------------------------------------------------------------
.list-share{
  position: relative;
  &, a {
	  color: $black;
  }
}

.share-buttons{
  margin-bottom: 9px;
  height: 31px;
  padding-left: 5px;
  width: 192px;
  background-color: #F2F2F2;
  border-radius: 5px;
  display: none;
  position: absolute;
  top: -38px;

  li{
    display: inline-block;
    vertical-align: middle;
  }
}

// General
//------------------------------------------------------------------------------
a {
	color: $red;
}

.icon-ui-download {
	.btn & {
		@include rem(margin-left, 10px);
		@include rem(height, 20px);
	}

	@include mq($iphone, false, max) {
		.btn & {
			@include rem(height, 15px);
			@include rem(margin-left, 5px);
		}
	}
}

.icon-ui-magnifying-glass {
  @include rem(height, 16px);
}

.icon-ui-play {
	.alt-heading & {
		@include rem(height, 37px);

		//mq
		@include mq($imac, false, max) {
			@include rem(height, 35px);
		}
		@include mq($macbook, false, max) {
			@include rem(height, 30px);
		}
		@include mq($ipad, false, max) {
			@include rem(height, 25px);
		}
		@include mq($portrait, false, max) {
			@include rem(height, 20px);
		}
		@include mq($iphone, false, max) {
			@include rem(height, 18px);
		}
	}
}

.icon-ui-field360 {
	color: rgba($grey-f, .2);
	@include rem(height, 192px);

	//mq
	@include mq($imac, false, max) {
		@include rem(height, 170px);
	}
	@include mq($macbook, false, max) {
		@include rem(height, 150px);
	}
	@include mq($ipad, false, max) {
		@include rem(height, 135px);
	}
	@include mq($portrait, false, max) {
		@include rem(height, 115px);
	}
	@include mq($iphone, false, max) {
		@include rem(margin, 10px auto 0);
		display: block;
		@include rem(height, 80px);
	}
}

.multi-title {
  display: flex;
  justify-content: center;
  align-items: center;

  .columns {
    display: inline-block;
    padding: 15px 10px;
    margin-bottom: 0;
    margin-top: 0;
    width: auto;
    float: none;
  }

  .title-middle {
    padding-bottom: 0;
    padding-top: 0;

    .icon {
      width: 73px;
      text-align: center;
      color: white;
    }
  }
}

// Helpers
//------------------------------------------------------------------------------
a[href^=tel]{
  color:inherit;
  text-decoration:none;
}

.overflow-container {
  overflow: hidden;
}

.freeze {
	overflow: hidden!important;
}

.link-to-map {
	color: $black;
	border-bottom: 1px solid $black;
	@include rem(margin-top, 25px);
	display: inline-block;
	@include mq($iphone, false, max) {
		@include rem(font-size, 14px);
		@include rem(margin-top, 10px);
	}
}

.p-left, .p-right {
  &[class*="hairline"] {
    @include mq($iphone, false, max) {
      border: none;
    }
  }
}

.p-left {
  @include rem(padding-left, 30px);

  //mq
  @include mq($imac, false, max) {
    @include rem(padding-left, 25px);
  }
  @include mq($macbook, false, max) {
    @include rem(padding-left, 20px);
  }
  @include mq($ipad, false, max) {
    @include rem(padding-left, 15px);
  }
  @include mq($portrait, false, max) {
    @include rem(padding-left, 10px);
  }
  @include mq($iphone, false, max) {
    padding-left: 0;
  }
}

.p-right {
  @include rem(padding-right, 30px);

  //mq
  @include mq($imac, false, max) {
    @include rem(padding-right, 25px);
  }
  @include mq($macbook, false, max) {
    @include rem(padding-right, 20px);
  }
  @include mq($ipad, false, max) {
    @include rem(padding-right, 15px);
  }
  @include mq($portrait, false, max) {
    @include rem(padding-right, 10px);
  }
  @include mq($iphone, false, max) {
    padding-right: 0;
  }
}

.m-left {
  @include rem(margin-left, 30px);

  //mq
  @include mq($imac, false, max) {
    @include rem(margin-left, 25px);
  }
  @include mq($macbook, false, max) {
    @include rem(margin-left, 20px);
  }
  @include mq($ipad, false, max) {
    @include rem(margin-left, 15px);
  }
  @include mq($portrait, false, max) {
    @include rem(margin-left, 10px);
  }
  @include mq($iphone, false, max) {
    margin-left: 0;
  }
}

.m-right {
  @include rem(margin-right, 30px);

  //mq
  @include mq($imac, false, max) {
    @include rem(margin-right, 25px);
  }
  @include mq($macbook, false, max) {
    @include rem(margin-right, 20px);
  }
  @include mq($ipad, false, max) {
    @include rem(margin-right, 15px);
  }
  @include mq($portrait, false, max) {
    @include rem(margin-right, 10px);
  }
  @include mq($iphone, false, max) {
    margin-right: 0;
  }
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.hide-mobile {
	@include mq($iphone, false, max) {
		display: none!important;
	}
}
.show-mobile-only {
  @include mq($iphone) {
    display: none!important;
  }
}

.show-portrait {
  @include mq($portrait) {
		display: none;
	}
}

.hide-text {
  @extend %opacity-0;
}

.v-flex {
	display: flex;
	flex-flow: column nowrap;
}

.flex {
  @include mq($portrait) {
    display: flex;
  }

  &.ipad-fix {
    @include mq($ipad) {
      display: flex;
    }
  }
}

.clear {
  clear: both;
}

.stick-bottom {
	margin-top: auto;
	margin-bottom: 0;
}

.no-mb {
	margin-bottom: 0!important;
}

.no-pb {
  padding-bottom: 0!important;
}

.text-center {
	text-align: center;
}

.text-left {
  text-align: left;
}


.text-left-mobile {
	@include mq($iphone, false, max) {
		text-align: left;
	}
}

.link-img {
	.alt-sub-heading {
		@include rem(margin-bottom, 25px);
	}
	.img {
		display: flex;
		flex-flow: column nowrap;
		@extend %background-cover;
		background-color: $grey-f;
		@include rem(padding, 40px 20px);
		@include rem(margin-bottom, 25px);
		overflow: hidden;
		position: relative;
		height: 25vw;
		transition: outline-color .2s ease-in-out;
		@include rem(outline-width, 10px);
		outline-style: solid;
		outline-color: transparent;
    background-image: url("/media/images/assets/placeholder.svg");

    .lte-ie10 & {
	  	display: block;
    }
		&:hover {
			outline-color: $grey-f;
		}
		&:before {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			top: 20%;
			right: 0;
			left: 0;
			/* IE9 SVG, needs conditional override of 'filter' to 'none' */
			background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC41Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.5))); /* Chrome,Safari4+ */
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%); /* W3C */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ); /* IE6-8 */
			.ie9 & {
				filter: none;
			}
		}
	}
	.title, .details {
		color: white;
		position: relative;
		z-index: 1;
		text-transform: uppercase;
		text-shadow: 0px 2px 0px rgba(0,0,0,.3);
	}
	.title {
		margin-top: auto;
		@include whitney(600, normal);
		@include rem(font-size, 15px);
	}
	.details {
		@include rem(font-size, 12px);
		@include whitney(300, normal);
	}

	//mq
	@include mq($imac, false, max) {
		.alt-sub-heading {
			@include rem(margin-bottom, 22px);
		}
		.img {
			@include rem(padding-top, 35px);
			@include rem(padding-bottom, 35px);
		}
	}
	@include mq($macbook, false, max) {
		.alt-sub-heading {
			@include rem(margin-bottom, 20px);
		}
		.img {
			@include rem(padding-top, 30px);
			@include rem(padding-bottom, 30px);
		}
		.title {
			@include rem(font-size, 14px);
		}
	}
	@include mq($ipad, false, max) {
		.alt-sub-heading {
			@include rem(margin-bottom, 18px);
		}
		.img {
			@include rem(padding-top, 25px);
			@include rem(padding-bottom, 25px);
			@include rem(outline-width, 7.5px);
		}
		.title {
			@include rem(font-size, 13px);
		}
	}
	@include mq($portrait, false, max) {
		@include rem(margin-bottom, 30px);
		.alt-sub-heading {
			@include rem(margin-bottom, 15px);
		}
		.img {
			@include rem(padding-top, 20px);
			@include rem(padding-bottom, 20px);
			height: 50vw;
		}
		.title {
			@include rem(font-size, 12px);
		}
		.details {
			@include rem(font-size, 11px);
		}
	}
	@include mq($iphone, false, max) {
		@include rem(margin-bottom, 25px);
		.alt-sub-heading {
			@include rem(margin-bottom, 10px);
		}
		.img {
			@include rem(padding, 15px);
			@include rem(outline-width, 5px);
		}
		.title {
			@include rem(font-size, 11px);
		}
		.details {
			@include rem(font-size, 10px);
		}
	}
}

.line-to-bottom {
  border-bottom: 1px solid $grey-f;
  .contrast & {
    border-color: white;
  }
}

.a-right {
  text-align: right;
}


// Highlight / Contrast
//------------------------------------------------------------------------------
.highlight {
  background-color: $black;
  color: #fff;
  a {
    color: #fff;
    transition: color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
    &:hover {
	    color: $red;
    }
  }
}

.highlight-font {
  color: #fff;
}

.highlight--cream {
  background-color: #C7B67D;
}

.contrast {
	background-color: $grey-f;
}

// Hairline
//------------------------------------------------------------------------------
%hairline {
  border-style: solid;
  border-color: $black-15;
  .highlight &, &.highlight {
    border-color: $black-80;
  }

  &.no-pb {
    border-color: white;
  }

  &.hairline-hide-mobile {
    &.ipad-fix {
      @include mq($portrait, false, max) {
        border-width: 0;
      }
    }
    @include mq($iphone, false, max) {
      border-width: 0;
    }
  }
}

.hairline-double {
  @extend %hairline;
  @include rem(border-width, 1px 0);
}

.hairline-double-side {
  @extend %hairline;
  @include rem(border-width, 0 1px);
}

.hairline-top {
  @extend %hairline;
  @include rem(border-width, 1px 0 0 0);
}

.hairline-bottom {
  @extend %hairline;
  @include rem(border-width, 0 0 1px 0);
}

.hairline-left {
  @extend %hairline;
  @include rem(border-width, 0 0 0 1px);
}

.hairline-right {
  @extend %hairline;
  @include rem(border-width, 0 1px 0 0);
}

%hairline-fix {
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    left: 0;
    // add right property with letter-spacing value;
    background-color: black;
    .highlight & {
      background-color: white;
    }
  }
}

.hairline-bottom-fix {
  @extend %hairline-fix;

  &:after {
    bottom: 1px;
  }
}

// Accent
//------------------------------------------------------------------------------
.accent-top {
	&:before {
		content: '';
		display: block;
		@include rem(width, 160px);
		@include rem(height, 1px);
		background-color: $black-15;
		@include rem(margin-bottom, 40px);

		//mq
	  @include mq($imac, false, max) {
			@include rem(margin-bottom, 35px);
	  }
	  @include mq($macbook, false, max) {
	    @include rem(margin-bottom, 30px);
	  }
	  @include mq($ipad, false, max) {
	    @include rem(margin-bottom, 25px);
	  }
	  @include mq($portrait, false, max) {
	    @include rem(margin-bottom, 20px);
	  }
	  @include mq($iphone, false, max) {
	    @include rem(margin-bottom, 15px);
	  }
	}
}

// Nav
//------------------------------------------------------------------------------
.logo {
  display: block;
  outline: none;
  box-shadow: none;
  font-size: 0;
  margin-bottom: 20px;

  &, &:hover {
	  color: $black;
  }

  .highlight & {
		&, &:hover {
			color: white;
		}
  }

  .lte-ie10 & {
		display: inline-block;

		@include mq($iphone, false, max) {
			display: block;
		}
  }

  //flex layout
  width: 256px; //FF fix
  //flex: 0 256px;
  .au & {
    width: 295px;
  }

  //mq
  @include mq($imac, false, max) {
    width: 200px;
    margin-bottom: 20px;
    //flex: 0 200px;

    .au & {
      width: 230px;
    }
  }

   @include mq($macbook, false, max) {
	   width: 150px;
    //flex: 0 150px;
    .au & {
      width: 173px;
    }
  }

  @include mq($ipad, false, max) {
    margin-top: 10px;
    margin-left: 10px;
    width: 125px;
    //flex: 0 125px;
    .au & {
      width: 144px;
    }
  }

  @include mq($portrait, false, max) {
    width: 100px;
    //flex: 0 100px;
    .au & {
      width: 115px;
    }
  }

  @include mq($iphone, false, max) {
    @include rem(margin, 5px auto 5px);

    .icon {
      @include rem(width, 100px);
    }
  }
}

.nav {
  text-transform: uppercase;
  @include whitney(bold);
  text-align: center;
  position: relative;

  ul > li {
    letter-spacing: .17em;
    @include rem(font-size, 14px);
    display: inline-block;
    @include rem(margin, 0px 35px);
    font-weight: normal;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    & > a {
      color: $black;
      .highlight & {
        color: white;
      }
      transition: color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
    }

    &.dropdown__item > a {
      @include mq($ipad, false, min) {
        .highlight & {
          color: $black;
        }
      }
    }

    //mq
    @include mq($imac, false, max) {
      @include rem(font-size, 13px);
      @include rem(margin, 0px 20px);
      letter-spacing: .24em;
			text-indent: .12em; //letter-spacing divided by 2
    }

    @include mq($macbook, false, max) {
      @include rem(font-size, 12px);
      @include rem(margin, 0px 18px);
      letter-spacing: .20em;
			text-indent: .1em; //letter-spacing divided by 2
    }

    @include mq($ipad, false, max) {
      @include rem(font-size, 11px);
      @include rem(margin, 0px 12px);
      letter-spacing: .18em;
			text-indent: .09em; //letter-spacing divided by 2
    }

    @include mq($portrait, false, max) {
      @include rem(font-size, 10px);
      @include rem(margin, 0px 8px);
	    letter-spacing: .14em;
			text-indent: .07em; //letter-spacing divided by 2
    }

    @include mq($iphone, false, max) {
      display: block;
      text-align: left;
      margin: 0;
      @include rem(font-size, 12px);
      letter-spacing: .1em;
			text-indent: .05em; //letter-spacing divided by 2
    }

    .fba & {
      letter-spacing: .14em;
      text-indent: .07em;
      padding-left: rem(8px);
      padding-right: rem(8px);

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }

      @include mq($imac, false, max) {
        padding-left: rem(7px);
        padding-right: rem(7px);
      }

      @include mq($macbook, false, max) {
        padding-left: rem(5px);
        padding-right: rem(5px);
      }

      @include mq($ipad, false, max) {
        letter-spacing: 0;
        text-indent: 0;
      }
      @include mq($ipad, false, max) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.l-header {
  border-bottom: 1px solid #979797;
}

.nav-primary {
  @include rem(margin, 28px 0 0);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > ul {
	  @include rem(margin, auto 0 0 0);
    border-top: 1px solid #979797;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    width: 100%;

    & > li {
      margin: 0 10px;

      .dropdown__link {
        @include rem(padding, 6px 0px 6px 0px);
        display: block;

        @include mq($ipad) {
          @include rem(padding, 3px 0 12px 0);
        }
      }
    }
  }

  .highlight & > ul {
    border-top: 1px solid #585754;
    border-bottom: 1px solid #585754;
  }

  .lte-ie10 & {
	  text-align: left;
		& > ul {
			display: inline-table;
			text-align: right;
			margin-bottom: 0;
			& > li {
				margin-right: rem(40px);
				&:last-child {
					margin-right: 0;
				}

				@include mq($imac, false, max) {
					margin-right: rem(30px);
				}
				@include mq($macbook, false, max) {
					margin-right: rem(20px);
				}
				@include mq($ipad, false, max) {
					margin-right: rem(10px);
				}
				@include mq($portrait, false, max) {
					margin-right: rem(8px);
				}
			}
			@include mq($iphone, false, max) {
				display: none; //for the accordion
			}
		}
  }

  //mq
  @include mq($imac, false, max) {
    @include rem(margin, 25px 0 0);
  }

  @include mq($macbook, false, max) {
    @include rem(margin, 20px 0 0);

    & > ul {
      @include rem(margin-bottom, 3px);
    }
  }

  @include mq($portrait, false, max) {
    @include rem(margin, 15px 0 0);

    & > ul {
      margin-left: 0;
    }
  }

  @include mq($ipad, false, max) {
	  @include rem(margin, 0px);
    display: block;
    padding-right: 0;

    & > ul {
      @include rem(padding, 0px);
      @include rem(margin, 0px);
      display: none;
      @include rem(border-top-width, 1px);
      border-top-style: solid;
      border-top-color: $black-15;

      .highlight & {
	      border-top-color: $black-80;
      }

      & > li {
        display: block;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.nav-primary > ul {
  margin-bottom: 0;
}

.nav-secondary {
  @include rem(padding, 0 20px);

  ul > li {
    @include rem(margin-top, 10px);
    @include rem(margin-bottom, 9px);

    a {
      border-bottom: 1px solid transparent
    }

    a.active {
      border-bottom-color: currentColor;
    }
    a {
      &:hover {
        color: $red;
      }
    }
  }

  @include mq($iphone, false, max) {
    @include rem(padding-top, 5px);
    @include rem(padding-bottom, 5px);
    & > ul > li {
        border-bottom: 1px solid $black-80;
        margin-bottom: 0;
      &:last-child {
        border-bottom: none;
      }
      a {
        @include rem(padding, 3px 0px 9px);
        display: block;
      }
    }
  }
}

[rel*="js-mobile-carousel"] {
	@include mq($iphone, false, max) {
		position: relative;
		padding-left: 0;
		padding-right: 0;
		ul {
			text-align: left;
			overflow: scroll;
			-webkit-overflow-scrolling: touch;
			white-space: nowrap;
			&::-webkit-scrollbar {
				display: none;
			}
		}
		ul > li {
			display: inline-block;
			margin-right: rem(10px);
			border-bottom: none;
			&:first-child {
				margin-left: rem(20px);
			}
			&:last-child {
				margin-right: rem(40px);
			}
		}

		&:before, &:after {
			position: absolute;
			top: 0;
			bottom: 0;
			display: block;
			content: '';
			.ie9 & {
				filter: none;
			}
		}
		&:before {
			left: 0;
			width: rem(20px);
			background: linear-gradient(to right,  rgba(white,1) 0%,rgba(white,0) 100%); /* W3C */
		}
		&:after {
			right: 0;
			width: rem(50px);
			background: linear-gradient(to right,  rgba(white,0) 0%,rgba(white,1) 100%); /* W3C */
		}
	}

  .highlight &:after {
    background: linear-gradient(to right,  rgba(1,1,1,0) 0%,rgba(1,1,1,1) 100%); /* W3C */
  }

  .highlight &:before {
    background: linear-gradient(to right,  rgba(1,1,1,1) 0%,rgba(1,1,1,0) 100%); /* W3C */
  }

  .contrast &:after {
    background: linear-gradient(to right,  rgba(1,1,1,0) 0%,#f2f2f2 100%); /* W3C */
  }

  .contrast &:before {
    background: linear-gradient(to right,  #f2f2f2 0%,rgba(1,1,1,0) 100%); /* W3C */
  }
}

.nav-contact {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  background-color: rgba($black, .9);
  color: white;

  .au & {
	   background-image: url('/media/images/assets/flag-au.png');
		 background-repeat: no-repeat;
		 background-position: 0 0;
		 background-size: 500px 65px;

		@include mq($portrait, false, max) {
			background-image: none!important;
		}
  }

  @include mq($iphone, false, max) {
    display: flex;
  }
}

[data-nav-target] {
  @include mq($ipad) {
    display: none;
  }
  display: block;
  position: relative;
  text-align: left;
  @include rem(font-size, 13px);
  @include rem(padding, 10px);
  letter-spacing: .04em;
  @include rem(border-top-width, 1px);
  border-top-style: solid;
  border-top-color: $black-15;
  .highlight & {
	  border-top-color: $black-80;
  }
  &:hover {
    cursor: pointer;
  }
  &:before, &:after {
    position: absolute;
    top: 50%;
    content: "";
    right: 10px;
    @include rem(width, 20px);
    @include rem(height, 2px);
    transition: all .3s linear;
  }
  &:before {
    @extend %opacity-0;
  }
  &:after {
    @include rem(margin-top, -8px);
    background-color: $black-15;
    box-shadow: 0 6px 0 0 $black-15, 0 12px 0 0 $black-15;
  }
  &.opened {
    &:before, &:after {
      background-color: $red;
    }
    &:before {
      @extend %opacity-1;
      transform: translateY(-50%) rotate(-45deg);
    }
    &:after {
      box-shadow: none;
      margin-top: 0;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

.side-nav-container {
	position: relative;
}

.side-nav {
	background-color: white;
	position: absolute;
	z-index: 2;
	width: 16.666%;
	left: 2.5rem;
	top: 0;
	@include rem(padding, 40px 0px 0px);
	padding-right: 2.5rem;
	@include rem(min-height, 600px);

	&:before {
		content: '';
		display: block;
		background-color: white;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 100%;
		width: 500px;
		width: 25vw;
	}

	.title {
		@include rem(font-size, 15px);
		color: $black-60;
		@include rem(margin-bottom, 30px);
		@include whitney;
		text-transform: uppercase;
	}

	@include mq($design) {
		left: calc(50% - (6 * #{$design}px / 12) + 2.5rem);
		width: calc((2 * #{$design}px / 12));
	}
	@include mq($macbook, false, max) {
		padding-right: 1.5rem;
		.title {
			@include rem(font-size, 14px);
			@include rem(margin-bottom, 20px);
		}
	}
	@include mq($ipad, false, max) {
		min-height: 0;
		position: static;
		width: 100%;
		@include rem(padding, 15px 20px 20px);
		.title {
			@include rem(font-size, 13px);
			@include rem(margin-bottom, 10px);
		}
	}
	@include mq($iphone, false, max) {
		.title {
			@include rem(font-size, 12px);
			@include rem(margin-bottom, 5px);
		}
	}
}

.side-nav__item {
  letter-spacing: 0.1em;
  @include rem(padding, 5px 0px);
  position: relative;
  text-transform: uppercase;
  @include rem(font-size, 15px);
  @include whitney(normal, normal);

	//mq
	@include mq($imac, false, max) {
		@include rem(font-size, 14px);
	}
	@include mq($macbook, false, max) {
		@include rem(font-size, 13px);
	}
  @include mq($iphone, false, max) {
		@include rem(font-size, 12px);
		@include rem(padding-top, 10px);
		@include rem(padding-bottom, 10px);
	}
}

.side-nav__item {
	a {
		color: $black;
		&:hover {
	    color: $red;
	  }
	}
}
.side-nav__item .icon {
  position: absolute;
  right: 0;
  top: 1em;
  transition: all .2s ease-in-out;
  @include mq($iphone, false, max) {
		top: 1.3em;
	}
}

.side-nav__sub-list {
  max-height: 0;
  overflow: hidden;
  padding-left: 0.5em;
  transition: 0.5s;

  .side-nav__input:checked ~ & {
    max-height: 30em;
    transition: 1s;
  }
}

.side-nav__sub-item {
  text-transform: none;
  width: intrinsic;
  a {
		font-weight: normal;

	  display: block;
	  @include rem(padding, 2px 0px);

	  @include mq($iphone, false, max) {
			@include rem(padding-top, 8px);
			@include rem(padding-bottom, 8px);
		}
	}
}

.side-nav__item input {
  display: none;
}

label.side-nav__label {
	@include hellip(100%);
	@include rem(padding-right, 15px);
}
.side-nav__label {
  cursor: pointer;
  display: block;
  .side-nav__input:checked ~ & {
    color: $red;
  }
}

.side-nav__input:checked ~ .icon {
	transform: rotateX(180deg) rotateY(-180deg);
}

.nav-dropdown  {
	display: none;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	@include rem(width, 60px);
	background-color: $black-80;
	transition: background-color .2s ease-in-out;
	cursor: pointer;
	&:hover {
		background-color: $black-60;
	}
	&.open {
		background-color: $red;
	}
	span {
		position: absolute;
		@include center;
		line-height: 0;
		letter-spacing: .04em;
		@include rem(font-size, 10px);
	}

	ul {
		cursor: default;
		display: none;
		z-index: 2;
		position: absolute;
		top: 100%;
		right: 0;
		background-color: $black-60;
		@include rem(width, 300px);
		text-align: left;
		box-shadow: 0px 5px 0px rgba(0,0,0,.05);
		& > li {
			@include rem(padding, 5px 10px);
			margin-left: 0;
			margin-right: 0;
			a:hover {
				color: $black;
			}
		}
		& > li, & > li a {
			display: block;
			width: intrinsic;
		}
	}

	//mq
	@include mq($iphone, false, max) {
		display: none!important;
	}
}

// list
//------------------------------------------------------------------------------
.list-social, .list-details, .list-members, .user-menu {
  @include rem(padding-left, 30px);
  @include rem(padding-right, 30px);
  //mq
  @include mq($imac, false, max) {
    @include rem(padding-left, 25px);
    @include rem(padding-right, 25px);
  }
   @include mq($macbook, false, max) {
    @include rem(padding-left, 20px);
    @include rem(padding-right, 20px);
  }
  @include mq($ipad, false, max) {
    @include rem(padding-left, 15px);
    @include rem(padding-right, 15px);
  }
  @include mq($portrait, false, max) {
    @include rem(padding-left, 20px);
    @include rem(padding-right, 20px);
    .fba & {
      @include rem(padding-left, 15px);
      @include rem(padding-right, 15px);
    }
  }
  @include mq($iphone, false, max) {
    display: none;
  }
}

.list-social {
  @include rem(padding-top, 6px);
  @include rem(padding-bottom, 2px);

  & > li {
    @include rem(margin, 0 10px);
    display: inline-block;
    vertical-align: middle;

    & > a {
	    color: $black;
	    transition: color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);

	    .highlight & {
		    color: $white;
	    }

	    &:hover {
			  color: $red;
		  }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    //mq
    @include mq($ipad, false, max) {
      @include rem(margin, 0 5px);
    }

    @include mq($portrait, false, max) {
      @include rem(margin, 0 5px);
    }
  }
}

.list-details,
.list-members {
  @include rem(padding-top, 7px);
  @include rem(padding-bottom, 5px);
  & > li {
    @include rem(font-size, 14px);
    @include opensans(600);
    letter-spacing: -0.012em;
    @include whitney;
  }

  //mq
  @include mq($ipad, false, max) {
    @include rem(padding-top, 9px);
    @include rem(padding-bottom, 6px);
    & > li {
      @include rem(font-size, 12px);
      letter-spacing: .04em;
    }
  }
}

.list-members {
  li {
    float:left;
    &:first-child {
      margin-right: 15px;
    }
  }
}

// Page header
//------------------------------------------------------------------------------
.page-header {
	text-align: center;
	@include rem(padding, 65px 0px 100px);

	@include mq($imac, false, max) {
		@include rem(padding, 55px 0px 85px);
	}
	@include mq($macbook, false, max) {
		@include rem(padding, 45px 0px 70px);
	}
	@include mq($ipad, false, max) {
		@include rem(padding, 45px 0px 70px);
	}
	@include mq($portrait, false, max) {
		@include rem(padding, 35px 0px 50px);
	}
	@include mq($iphone, false, max) {
		@include rem(padding, 25px 0px 40px);
	}
}

// Billboard
//------------------------------------------------------------------------------
.billboard {
  @include rem(height, 465px);
  @include rem(padding, 25px 0px 25px);
  @extend %background-cover;
  position: relative;
  overflow: hidden;
  text-align: center;
  background-color: $grey-f;
  display: table;
  width: 100%;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.15);
    content: '';
  }

  > * {
    position: relative;
    z-index: 1;
  }

  & > .row {
	  display: table-cell;
	  vertical-align: middle;
  }

  p {
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.15em;
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 1;
  }

  //mq
  @include mq($design) {
	  & > .row > .columns {
			width: calc((8 * #{$design}px / 12));
		}
  }

  @include mq($imac, false, max) {
    @include rem(height, 380px);
  }

  @include mq($macbook, false, max) {
    @include rem(height, 320px);
  }

  @include mq($ipad, false, max) {
	  @include rem(height, 265px);
  }

  @include mq($portrait, false, max) {
	  @include rem(height, 240px);

	  .heading {
		  margin: 0;
	  }
  }

  @include mq($iphone, false, max) {
	  @include rem(height, 150px);
  }
}

.billboard__header,
.billboard__text {
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    height: 1px;
    background-color: currentColor;
    max-width: 350px;
    width: 100%;
  }
}

// Hero
//------------------------------------------------------------------------------
.hero-news {
  @include rem(margin-bottom, 35px);
	position: relative;
  display: flex;
  flex-wrap: wrap;

  .img {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    background-color: $grey-f;
    width: 100%;
  }

	.img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    max-width: 150%;
    max-height: 150%;
	}

  .img::before {
    display: block;
    content: '';
    padding-bottom: 57.5%;
  }

	footer {
    width: 100%;
		background-color: $grey-f;
		display: flex;
    flex-wrap: wrap;

    ul {
      width: 100%;
    }

		dl {
			@include rem(padding, 30px);
			@include rem(font-size, 13px);
			@include whitney;
      width: 100%;

			dt {
				font-weight: bold;
			}
		}

		.list-share {
      @include rem(padding, 10px 30px 6px);
			margin-top: auto;
			margin-bottom: 0;
		}
	}

  @include mq($macbook, false, min) {
    @include rem(margin-bottom, 25px);

    .img {
      width: auto;
    }

    footer {
      @include rem(width, 250px);

      dl {
        @include rem(padding, 20px);
      }

      .icon {
        @include rem(height, 18px);
      }

      .list-share {
        @include rem(padding-left, 20px);
        @include rem(padding-right, 20px);
      }
    }
  }
}

// Slider News
//------------------------------------------------------------------------------
.slider-container {
	position: relative;
	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 1024px;
		width: 25vw;
		background-color: $grey-f;
	}
	&:before {
		right: 100%;
	}
	&:after {
		left: 100%;
	}

  &.slider-container--bare::after,
  &.slider-container--bare::before {
    content: normal;
  }

	&, .slider {
		@include rem(height, 500px);
	}

  &.slider-container--bare,
  &.slider-container--bare slider {
    @include rem(height, 400px);
  }

	//mq
	@include mq($ipad, false, max) {
		&, .slider {
			@include rem(height, 450px);
		}
	}
	@include mq($macbook, false, max) {
		&, .slider {
			@include rem(height, 400px);
		}
	}
	@include mq($ipad, false, max) {
		&, .slider {
			@include rem(height, 350px);
		}
	}
	@include mq(960, false, max) {
		&, .slider {
			height: auto;
		}
	}
	@include mq($iphone, false, max) {
		@include rem(margin-bottom, 15px);
	}
}

.slider {
	.img {
		position: absolute;
		@extend %background-cover;
		top: 0;
		bottom: 0;
		right: 0;
		left: 33.333%;
	}
	.card-news {
		width: 33.3333333333%;
		position: absolute;
		bottom: 0;
		top: 0;
		display: flex;
		flex-flow: column nowrap;
		&:after {
			content: '';
			display: block;
			position: absolute;
			height: 0;
			width: 0;
			@include rem(border-width, 15px 0px 15px 15px);
			border-style: solid;
			border-color: transparent transparent transparent white;
			@include center(false, true);
			left: 100%;
		}
		header {
			&:after {
				content: none;
			}
		}
		section {
			background-color: white;
			@include rem(padding-top, 40px);
			@include rem(padding-bottom, 60px);
			flex: 1;
			overflow: hidden;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			position: relative;
		}
		footer {
			margin-top: auto;
			margin-bottom: 0;
			@include rem(min-height, 35px);
		}
		.list-share {
			.icon-ui-share {
				@include rem(height, 20px);
			}
			.icon-ui-email {
				@include rem(height, 24px);
			}
		}
	}


	//mq
	@include mq($imac, false, max) {
		.card-news {
			section {
				@include rem(padding-top, 30px);
				@include rem(padding-bottom, 30px);
			}
		}
	}
	@include mq($macbook, false, max) {
		.card-news {
			section {
				@include rem(padding-top, 25px);
				@include rem(padding-bottom, 25px);
			}
		}
	}
	@include mq($ipad, false, max) {
		.alt-sub-heading {
			@include rem(font-size, $base-font-size);
		}
		.card-news {
			section {
				@include rem(padding-top, 20px);
				@include rem(padding-bottom, 20px);
				p {
					@include rem(font-size, 15px);
				}
			}
		}
	}
	@include mq(960, false, max) {
		.img {
			position: static;
			height: 0;
			padding-bottom: 49%;
		}
		.card-news {
			position: static;
			width: 100%;
			&:after {
				content: none;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
			section {
				@include rem(padding-top, 15px);
				@include rem(padding-bottom, 15px);
				p {
					@include rem(font-size, 14px);
				}
			}
		}
	}
}

.slider .controls {
	.next, .previous {
		background-color: $black;
		position: absolute;
		@include rem(width, 30px);
		@include rem(height, 30px);
		@include rem(left, -31px);
		.icon {
			position: absolute;
			@include center;
			@include rem(height, 25px);
		}
		&:hover {
			cursor: pointer;
			background-color: lighten($black, 5);
		}
	}
	.next {
		top: 0;
	}
	.previous {
		@include rem(top, 31px);
	}
	@include mq($portrait, false, max) {
		.next, .previous {
			@include rem(top, 140px);
		}
		.next {
			left: inherit;
			right: 0;
		}
		.previous {
			left: 0;
		}
	}
	@include mq($iphone, false, max) {
		.next, .previous {
			@include rem(top, 65px);
		}
	}
}

// heading
//------------------------------------------------------------------------------
.heading {
  text-transform: uppercase;
  @include rem(font-size, 50px);
  @include whitney(medium);
  font-weight: 500;
  letter-spacing: .6em;
  display: inline-block;
  position: relative;

  &.hairline-bottom-fix:after {
    right: .6em;
  }

	.au .block-home & {
		@include rem(font-size, 45px);
	}
  .page-header & {
	  @include rem(font-size, 25px);
  }

  .fba & {
    @include rem(font-size, 35px);
    letter-spacing: .48em;
  }

  //mq
  @include mq($imac, false, max) {
    @include rem(font-size, 40px);
    letter-spacing: .5em;
    &.hairline-bottom-fix:after {
    	right: .5em;
  	}
  	.page-header & {
		  @include rem(font-size, 22px);
	  }
	  .fba & {
  	   @include rem(font-size, 30px);
	  }
  }
  @include mq($macbook, false, max) {
    letter-spacing: .4em;
    &, .au .block-home & {
			@include rem(font-size, 35px);
		}
    &.hairline-bottom-fix:after {
    	right: .4em;
  	}
  	.page-header & {
		  @include rem(font-size, 20px);
	  }
	  .fba & {
  	   @include rem(font-size, 28px);
	  }
  }
  @include mq($ipad, false, max) {
    letter-spacing: .3em;
    &, .au .block-home & {
			@include rem(font-size, 30px);
		}
    &.hairline-bottom-fix:after {
    	right: .3em;
  	}
  	.page-header & {
		  @include rem(font-size, 18px);
	  }
	  .fba & {
  	   @include rem(font-size, 25px);
	  }
  }
  @include mq($portrait, false, max) {
    letter-spacing: .25em;
    &, .au .block-home &  {
			@include rem(font-size, 25px);
		}
    &.hairline-bottom-fix:after {
    	right: .25em;
  	}
  }
  @include mq($iphone, false, max) {
    letter-spacing: .2em;
    &, .au .block-home &, .fba & {
			@include rem(font-size, 22px);
		}
    &.hairline-bottom-fix:after {
    	right: .2em;
  	}
  	.page-header & {
		  @include rem(font-size, $base-font-size);
	  }
	  .fba & {
  	  letter-spacing: .2em;
	  }
  }
}

.sub-title {
  @include rem(font-size, $base-font-size);
  @include utopia(normal, italic);

	.highlight & {
		color: white;
	}

  //mq
  @include mq($imac, false, max) {
    @include rem(font-size, 15px);
  }
  @include mq($macbook, false, max) {
    @include rem(font-size, 14px);
  }
  @include mq($ipad, false, max) {
    @include rem(font-size, 13px);
  }
  @include mq($portrait, false, max) {
    @include rem(font-size, 12px);
  }
}

.sub-heading {
  letter-spacing: .04em;
  @include utopia(normal);
  line-height: 1;
  @include rem(font-size, 54px);
  @include rem(margin-bottom, 25px);

  &[class*="hairline-bottom"] {
   @include rem(padding-bottom, 20px);
   @include rem(padding-top, 20px);
  }

  &.smaller {
	  @include rem(font-size, 45px);
	  line-height: 1.2;
  }

  //mq
  @include mq($ipad, false, max) {
    @include rem(font-size, 40px);
    @include rem(margin-bottom, 20px);

    &[class*="hairline-bottom"] {
     @include rem(padding-bottom, 10px);
    }

    &.smaller {
		  @include rem(font-size, 30px);
	  }
  }

  @include mq($portrait, false, max) {
    @include rem(font-size, 30px);

    &.smaller {
		  @include rem(font-size, 25px);
	  }
  }

  @include mq($iphone, false, max) {
    @include rem(font-size, 25px);
    @include rem(margin-bottom, 15px);

    &[class*="hairline-bottom"] {
     @include rem(padding-bottom, 7px);
    }

    &.smaller {
		  @include rem(font-size, 20px);
	  }
  }
}

.alt-sub-heading {
  @extend %alt-sub-heading-styling;

  a {
    color: $red;
    text-decoration: underline;
    transition: color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
    &:hover {
      color: $black-80;
    }
  }

  &.flash-message {
    a {
      color: $red;
    }
  }

  &.smaller {
    font-size: 22px;
  }
}

.alt-sub-heading--margined {
  margin-bottom: 40px;
}

.alt-sub-heading-2 {
  @include utopia;

  @include mq($imac, false, max) {
    font-size: 22px;
  }

  @include mq($iphone, false, max) {
    font-size: 16px;
  }
}

.title-4 {
  @include whitney(bold);
  text-transform: uppercase;
  font-size: rem(16px);
  text-transform: uppercase;
  letter-spacing: .12em;

  @include mq($iphone, false, max) {
    font-size: rem(14px);
  }
}

.title-20 {
  @include whitney(600);
  font-size: rem(20px);
  text-transform: uppercase;
  letter-spacing: .28em;
  @include mq($macbook, false, max) {
      font-size: rem(18px);
  }
  @include mq($iphone, false, max) {
    font-size: rem(16px);
  }
}

%alt-sub-heading-styling {
	@include rem(font-size, 36px);
  @include utopia;

  //mq
  @include mq($imac, false, max) {
    @include rem(font-size, 36px);
  }

  @include mq($macbook, false, max) {
    @include rem(font-size, 28px);
  }

  @include mq($ipad, false, max) {
    @include rem(font-size, 18px);
  }

  @include mq($portrait, false, max) {
    @include rem(font-size, $base-font-size);
  }
}

.alt-heading {
	display: block;
	@include whitney(500, normal);
	text-transform: uppercase;
	@include rem(font-size, 24px);
	letter-spacing: .28em;

	.icon {
		vertical-align: middle;
	}

	//mq
  @include mq($imac, false, max) {
		@include rem(font-size, 22px);
	}
	@include mq($macbook, false, max) {
		@include rem(font-size, 20px);
	}
	@include mq($ipad, false, max) {
		@include rem(font-size, 18px);
	}
	@include mq($portrait, false, max) {
		@include rem(font-size, $base-font-size);
	}
	@include mq($iphone, false, max) {
		@include rem(font-size, 14px);
		letter-spacing: .14em;
	}
}


// intro
//------------------------------------------------------------------------------
.page-intro {
  overflow: hidden;
  @include rem(margin-bottom, 59px);
  @include rem(padding-bottom, 11px);

  &.page-intro--no-margin {
    margin-bottom: 0;
  }

  .content {
    background-color: $red;
    position: relative;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: $red;
    }

    // &:before {
    //   right: 100%;
    //   width: 10000px;
    //   width: 100vw;
    // }

    &:after { //this to make it go to the egde
      left: 100%;
      width: 10000px;
      width: 100vw;
    }

    &.empty {
	    @include rem(height, 92px);
	    background-color: transparent;

	    &:before, &:after {
	    	content: none!important;
	    }
    }
  }

  .icon-container {
	  color: white;
    background-color: $black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    @include rem(width, 96px);
    box-shadow: 0px 11px 0px 0px $black-15;

    &.icon-container--alt {
      box-shadow: none;
    }

    .icon {
      position: absolute;
      @include center;
      @include rem(height, 64px);
    }
  }

  .icon-container--large .icon {
    @include rem(height, 94px);
  }

  .alt-sub-heading {
    color: white;
    @include rem(padding, 30px 0px 30px 128px);
  }

  //mq
  @include mq($imac, false, max) {
    @include rem(margin-bottom, 54px);

    &.page-intro--no-margin {
      margin-bottom: 0;
    }

    .alt-sub-heading {
      @include rem(padding-top, 25px);
      @include rem(padding-bottom, 25px);
      @include rem(padding-left, 120px);
    }
    .icon-container {
      @include rem(width, 90px);
      .icon {
        @include rem(height, 62px);
      }
    }
		.content.empty {
	  	@include rem(height, 81px);
    }
  }

  @include mq($macbook, false, max) {
    @include rem(margin-bottom, 44px);

    &.page-intro--no-margin {
      margin-bottom: 0;
    }

    .alt-sub-heading {
      @include rem(padding-top, 22px);
      @include rem(padding-bottom, 22px);
      @include rem(padding-left, 110px);
    }
    .icon-container {
      @include rem(width, 80px);
      .icon {
        @include rem(height, 55px);
      }
    }
    .content.empty {
	  	@include rem(height, 74px);
    }
  }
  @include mq($ipad, false, max) {
    @include rem(margin-bottom, 40px);
    @include rem(padding-bottom, 5px);

    &.page-intro--no-margin {
      margin-bottom: 0;
    }

    .alt-sub-heading {
      @include rem(padding-top, 20px);
      @include rem(padding-bottom, 20px);
      @include rem(padding-left, 90px);
    }
    .icon-container {
      @include rem(width, 70px);
      box-shadow: 0px 5px 0px 0px $black-15;
      .icon {
        @include rem(height, 50px);
        max-width: 90%;
      }
    }
    .content.empty {
	  	@include rem(height, 67px);
    }
  }
  @include mq($portrait, false, max) {
    @include rem(margin-bottom, 30px);

    &.page-intro--no-margin {
      margin-bottom: 0;
    }

    .alt-sub-heading {
      @include rem(padding-top, 18px);
      @include rem(padding-bottom, 18px);
      @include rem(padding-left, 80px);
    }
    .icon-container {
      @include rem(width, 60px);
      .icon {
	      max-width: 80%;
        @include rem(height, 45px);
      }
    }
    .content.empty {
	  	@include rem(height, 60px);
    }
  }
  @include mq($iphone, false, max) {
    @include rem(margin-bottom, 25px);
    padding-bottom: 0;

    &.page-intro--no-margin {
      margin-bottom: 0;
    }

    .content {
      &:before {
        content: '';
      }
      &:after {
        @include rem(width, 25px);
      }
    }
    .alt-sub-heading {
	    @include rem(font-size, 14px);
      @include rem(padding-top, 16px);
      @include rem(padding-bottom, 16px);
      @include rem(padding-left, 65px);
    }
    .icon-container {
      background-color: transparent;
      @include rem(width, 45px);
      box-shadow: none;
      .icon {
        @include rem(height, 40px);
      }
    }
    .content.empty {
	  	@include rem(height, 53px);
	  	.icon-container {
		  	background-color: $black;
		  	@include rem(width, 50px);
	  	}
    }
  }
}

.text-intro {
  @include rem(margin-bottom, 40px);
  &[class*="hairline-top"],  &[class*="hairline-double"]{
    @include rem(padding-top, 25px);
  }
  &[class*="hairline-bottom"], &[class*="hairline-double"] {
    @include rem(padding-bottom, 25px);
  }

  //mq
  @include mq($imac, false, max) {
    @include rem(margin-bottom, 35px);
    &[class*="hairline-top"],  &[class*="hairline-double"]{
      @include rem(padding-top, 24px);
    }
    &[class*="hairline-bottom"], &[class*="hairline-double"] {
      @include rem(padding-bottom, 24px);
    }
  }
  @include mq($macbook, false, max) {
    @include rem(margin-bottom, 28px);
    &[class*="hairline-top"],  &[class*="hairline-double"]{
      @include rem(padding-top, 22px);
    }
    &[class*="hairline-bottom"], &[class*="hairline-double"] {
      @include rem(padding-bottom, 22px);
    }
  }
  @include mq($ipad, false, max) {
    @include rem(margin-bottom, 25px);
    &[class*="hairline-top"],  &[class*="hairline-double"]{
      @include rem(padding-top, 20px);
    }
    &[class*="hairline-bottom"], &[class*="hairline-double"] {
      @include rem(padding-bottom, 20px);
    }
  }
  @include mq($portrait, false, max) {
    @include rem(margin-bottom, 20px);
    &[class*="hairline-top"],  &[class*="hairline-double"]{
      @include rem(padding-top, 18px);
    }
    &[class*="hairline-bottom"], &[class*="hairline-double"] {
      @include rem(padding-bottom, 18px);
    }
  }
  @include mq($iphone, false, max) {
    @include rem(font-size, 14px);
    @include rem(margin-bottom, 15px);
    &[class*="hairline-top"],  &[class*="hairline-double"]{
      @include rem(padding-top, 15px);
    }
    &[class*="hairline-bottom"], &[class*="hairline-double"] {
      @include rem(padding-bottom, 15px);
    }
  }

  a {
    text-decoration: underline;
  }
}

// Text
//------------------------------------------------------------------------------

.text {
	@include rem(padding-top, 10px);
	@include rem(padding-bottom, 10px);
	@include whitney;
  @include rem(font-size, $base-font-size);
	color: $black-80;

	p, ul, img {
		@include rem(margin-bottom, 25px);
		&:last-of-type:last-child:not(&:first-of-type) {
			margin-bottom: 0;
		}
	}

  figure[style*='float: left;'] {
    margin-right: 15px;
    margin-bottom: 10px;
  }

  figure[style*='float: right;'] {
    margin-left: 15px;
    margin-bottom: 10px;
  }

	.highlighted, .black {
		color: black;
	}

	.highlighted { //maybe better to use strong here
		@include utopia(400, italic);
	}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include utopia(500);
    font-weight: 500;
  }

  a {
    text-decoration: underline;
  }

  a:hover {
    color: black;
  }

  h1 {
    @include rem(font-size, 54px);
    @include rem(margin-bottom, 20px);
    color: black;
  }

  h2 {
    @include rem(font-size, 36px);
    @include rem(margin-bottom, 20px);
    color: black;
  }

  h3 {
    @include rem(font-size, 22px);
    @include rem(margin-bottom, 25px);
    color: black;
  }

  h4 {
    @include rem(font-size, 18px);
    @include rem(margin-bottom, 25px);
    color: black;
  }

  li {
    margin-bottom: 5px;
  }

	ul {
		& > li {
			@include rem(padding-left, 15px);
			a:hover {
				text-decoration: underline;
			}
			&:before {
				content: '›';
				color: $red;
				display: inline-block;
				@include rem(margin-right, 10px);
				@include rem(margin-left, -15px);
			}
		}
	}

	.fba .form & {
    padding-top: 0;
	}

	@include mq($macbook, false, max) {
		h2, h3 {
			@include rem(margin-bottom, 20px);
		}
	}
	@include mq($ipad, false, max) {
		@include rem(font-size, 15px);

    h2, h3 {
      @include rem(margin-bottom, 15px);
    }

		p, ul, img {
			@include rem(margin-bottom, 20px);
		}
	}

	@include mq($portrait, false, max) {
		p, ul, img {
			@include rem(margin-bottom, 15px);
		}
		h2, h3 {
			@include rem(margin-bottom, 10px);
		}
	}

	@include mq($iphone, false, max) {
		@include rem(font-size, 14px);

		p, ul, img {
			@include rem(margin-bottom, 10px);
		}
	}
}

@each $color, $hexa in $colors {
	.text-#{$color} {
		color: $hexa;
	}
}

.col {
	column-count: 2;
	column-gap: 6rem;
	column-rule: 1px solid $black-15;
	//mq
	@include mq($imac, false, max) {
		column-gap: 5rem;
	}
	@include mq($macbook, false, max) {
		column-gap: 4rem;
	}
	@include mq($ipad, false, max) {
		column-gap: 3rem;
	}
	@include mq($portrait, false, max) {
		column-count: 1;
	}
	@include mq($iphone, false, max) {

	}
}

.w-aside {
	display: flex;
	flex-flow: row nowrap;
	.content {
		flex: 1;
		@include rem(padding-right, 30px);
	}
	.aside {
		flex: 0 255px;
		@include rem(padding-left, 30px);
	}

	//mq
	@include mq($imac, false, max) {
		.aside {
			flex: 0 250px;
		}
	}
	@include mq($macbook, false, max) {
		.content {
			@include rem(padding-right, 25px);
		}
		.aside {
			flex: 0 225px;
			@include rem(padding-left, 25px);
		}
	}
	@include mq($ipad, false, max) {

	}
	@include mq($portrait, false, max) {
		.content {
			@include rem(padding-right, 20px);
		}
		.aside {
			flex: 0 190px;
			@include rem(padding-left, 20px);
		}
	}
	@include mq($iphone, false, max) {
		display: block;
		.content {
			padding-right: 0;
		}
		.aside {
			padding-left: 0;
			&[class*="hairline"] {
				border: none;
			}
		}
	}

  .aside {
    a.btn {
      font-size: 13px;
      line-height: 29px;

      i.icon {
        position: relative;
        top: -2px;
      }
    }

    .aside-sidebar {
      @include rem(margin-top, 40px);

      a.btn {
        width: 100%;
        text-align: center;
        @include rem(margin-bottom, 30px);
      }
    }
  }
}

blockquote {
	@extend %alt-sub-heading-styling;
	color: $black-30;
	font-style: italic;
	text-align: center;
	@include rem(margin, 20px 0px 30px);
	@include rem(padding, 0 80px);
	&:before, &:after {
		display: inline-block;
	}
	&:before {
		content: '\201C';
	}
	&:after {
		content: '\201D';
	}

	//mq
  @include mq($imac, false, max) {
		@include rem(padding, 0 60px);
  }
  @include mq($macbook, false, max) {
		@include rem(padding, 0 40px);
		@include rem(margin-bottom, 25px);
  }
  @include mq($ipad, false, max) {
		@include rem(padding, 0 30px);
		@include rem(margin-bottom, 20px);
  }
  @include mq($portrait, false, max) {
  	@include rem(padding, 0 20px);
  	@include rem(margin, 15px 0px);
  }
  @include mq($iphone, false, max) {
  	@include rem(padding, 0 15px);
  }
}

// Block
//------------------------------------------------------------------------------
.block-square {
	@include rem(padding, 80px);

	//mq
  @include mq($imac, false, max) {
	  @include rem(padding, 75px);
  }
  @include mq($macbook, false, max) {
	 	@include rem(padding, 65px);
  }
  @include mq($ipad, false, max) {
		@include rem(padding, 55px);
  }
  @include mq($portrait, false, max) {
		@include rem(padding, 45px 25px);
  }
  @include mq($iphone, false, max) {
		@include rem(padding, 30px 20px);
  }
}

.block-container {
	@include rem(padding-top, 60px);
	@include rem(padding-bottom, 60px);
	//mq
  @include mq($imac, false, max) {
	  @include rem(padding-top, 55px);
  	@include rem(padding-bottom, 55px);
  }
  @include mq($macbook, false, max) {
	 	@include rem(padding-top, 50px);
  	@include rem(padding-bottom, 50px);
  }
  @include mq($ipad, false, max) {
		@include rem(padding-top, 40px);
  	@include rem(padding-bottom, 40px);
  }
  @include mq($portrait, false, max) {
		@include rem(padding-top, 30px);
  	@include rem(padding-bottom, 30px);
  }
  @include mq($iphone, false, max) {
		@include rem(padding-top, 20px);
  	@include rem(padding-bottom, 20px);
  }
}

.block-container--top {
  @include rem(padding-top, 60px);
  //mq
  @include mq($imac, false, max) {
    @include rem(padding-top, 55px);
  }
  @include mq($macbook, false, max) {
    @include rem(padding-top, 50px);
  }
  @include mq($ipad, false, max) {
    @include rem(padding-top, 40px);
  }
  @include mq($portrait, false, max) {
    @include rem(padding-top, 30px);
  }
  @include mq($iphone, false, max) {
    @include rem(padding-top, 20px);
  }
}

.block-container--bottom {
  @include rem(padding-bottom, 60px);
  //mq
  @include mq($imac, false, max) {
    @include rem(padding-bottom, 55px);
  }
  @include mq($macbook, false, max) {
    @include rem(padding-bottom, 50px);
  }
  @include mq($ipad, false, max) {
    @include rem(padding-bottom, 40px);
  }
  @include mq($portrait, false, max) {
    @include rem(padding-bottom, 30px);
  }
  @include mq($iphone, false, max) {
    @include rem(padding-bottom, 20px);
  }
}

.block-container-small {
  @include rem(padding-top, 30px);
  @include rem(padding-bottom, 30px);

  //mq
  @include mq($imac, false, max) {
    @include rem(padding-top, 25px);
    @include rem(padding-bottom, 25px);
  }
  @include mq($macbook, false, max) {
    @include rem(padding-top, 20px);
    @include rem(padding-bottom, 20px);
  }
  @include mq($ipad, false, max) {
    @include rem(padding-bottom, 15px);
    @include rem(padding-bottom, 15px);
  }
  @include mq($portrait, false, max) {
    @include rem(padding-top, 10px);
    @include rem(padding-bottom, 10px);
  }
}

.block-container-small--top {
  @include rem(padding-top, 30px);

  //mq
  @include mq($imac, false, max) {
    @include rem(padding-top, 25px);
  }
  @include mq($macbook, false, max) {
    @include rem(padding-top, 20px);
  }
  @include mq($ipad, false, max) {
    @include rem(padding-top, 15px);
  }
  @include mq($portrait, false, max) {
    @include rem(padding-top, 10px);
  }
}

.block-container-small--bottom {
  @include rem(padding-bottom, 30px);

  //mq
  @include mq($imac, false, max) {
    @include rem(padding-bottom, 25px);
  }
  @include mq($macbook, false, max) {
    @include rem(padding-bottom, 20px);
  }
  @include mq($ipad, false, max) {
    @include rem(padding-bottom, 15px);
  }
  @include mq($portrait, false, max) {
    @include rem(padding-bottom, 10px);
  }
}


.block-container-long {
	@include rem(padding-top, 80px);
	@include rem(padding-bottom, 80px);

	//mq
  @include mq($imac, false, max) {
	  @include rem(padding-top, 75px);
  	@include rem(padding-bottom, 75px);
  }
  @include mq($macbook, false, max) {
	 	@include rem(padding-top, 65px);
  	@include rem(padding-bottom, 65px);
  }
  @include mq($ipad, false, max) {
		@include rem(padding-top, 55px);
  	@include rem(padding-bottom, 55px);
  }
  @include mq($portrait, false, max) {
		@include rem(padding-top, 45px);
  	@include rem(padding-bottom, 45px);
  }
  @include mq($iphone, false, max) {
		@include rem(padding-top, 30px);
  	@include rem(padding-bottom, 30px);
  }
}

.block-container-mega {
	@include rem(padding-top, 160px);
	@include rem(padding-bottom, 160px);

	//mq
  @include mq($imac, false, max) {
	  @include rem(padding-top, 100px);
  	@include rem(padding-bottom, 100px);
  }
  @include mq($macbook, false, max) {
	  @include rem(padding-top, 80px);
	 	@include rem(padding-bottom, 80px);
  }
  @include mq($ipad, false, max) {
	  @include rem(padding-top, 65px);
		@include rem(padding-bottom, 65px);
  }
  @include mq($portrait, false, max) {
	  @include rem(padding-top, 45px);
		@include rem(padding-bottom, 45px);
  }
  @include mq($iphone, false, max) {
	  @include rem(padding-top, 30px);
		@include rem(padding-bottom, 30px);
  }
}

.block {
	@include rem(padding-bottom, 60px);

	//mq
  @include mq($imac, false, max) {
  	@include rem(padding-bottom, 55px);
  }
  @include mq($macbook, false, max) {
	 	@include rem(padding-bottom, 50px);
  }
  @include mq($ipad, false, max) {
		@include rem(padding-bottom, 40px);
  }
  @include mq($portrait, false, max) {
		@include rem(padding-bottom, 30px);
  }
  @include mq($iphone, false, max) {
		@include rem(padding-bottom, 20px);
  }

  &-ipad-fix {
    @include mq($ipad, false, max) {
  		@include rem(padding-bottom, 40px);
    }
    @include mq($portrait, false, max) {
  		@include rem(padding-bottom, 30px);
    }
    @include mq($iphone, false, max) {
  		@include rem(padding-bottom, 20px);
    }
  }
}

.block-small {
	@include rem(padding-bottom, 40px);

	//mq
  @include mq($imac, false, max) {
  	@include rem(padding-bottom, 35px);
  }
  @include mq($macbook, false, max) {
	 	@include rem(padding-bottom, 30px);
  }
  @include mq($ipad, false, max) {
		@include rem(padding-bottom, 25px);
  }
  @include mq($portrait, false, max) {
		@include rem(padding-bottom, 20px);
  }
}

.block-mini {
  @include rem(padding-bottom, 25px);

	//mq
  @include mq($imac, false, max) {
  	@include rem(padding-bottom, 20px);
  }
  @include mq($macbook, false, max) {
	 	@include rem(padding-bottom, 15px);
  }
  @include mq($portrait, false, max) {
		@include rem(padding-bottom, 10px);
  }
}

.block-m {
	@include rem(margin-bottom, 60px);

	//mq
  @include mq($imac, false, max) {
  	@include rem(margin-bottom, 55px);
  }
  @include mq($macbook, false, max) {
	 	@include rem(margin-bottom, 50px);
  }
  @include mq($ipad, false, max) {
		@include rem(margin-bottom, 40px);
  }
  @include mq($portrait, false, max) {
		@include rem(margin-bottom, 30px);
  }
  @include mq($iphone, false, max) {
		@include rem(margin-bottom, 20px);
  }

  &-mini {
    @include rem(margin-bottom, 25px);

  	//mq
    @include mq($imac, false, max) {
    	@include rem(margin-bottom, 20px);
    }
    @include mq($macbook, false, max) {
  	 	@include rem(margin-bottom, 15px);
    }
    @include mq($portrait, false, max) {
  		@include rem(margin-bottom, 10px);
    }
  }
}

.block-long {
	@include rem(padding-bottom, 80px);

	//mq
  @include mq($imac, false, max) {
  	@include rem(padding-bottom, 75px);
  }
  @include mq($macbook, false, max) {
	 	@include rem(padding-bottom, 65px);
  }
  @include mq($ipad, false, max) {
		@include rem(padding-bottom, 55px);
  }
  @include mq($portrait, false, max) {
		@include rem(padding-bottom, 45px);
  }
  @include mq($iphone, false, max) {
		@include rem(padding-bottom, 30px);
  }
}

.block-mega {
	@include rem(padding-bottom, 160px);

	//mq
  @include mq($imac, false, max) {
  	@include rem(padding-bottom, 100px);
  }
  @include mq($macbook, false, max) {
	 	@include rem(padding-bottom, 80px);
  }
  @include mq($ipad, false, max) {
		@include rem(padding-bottom, 65px);
  }
  @include mq($portrait, false, max) {
		@include rem(padding-bottom, 45px);
  }
  @include mq($iphone, false, max) {
		@include rem(padding-bottom, 30px);
  }
}

.block-home {
	background-image: url('/media/images/assets/map.jpg');
	background-repeat: no-repeat;
	background-position: center -50px;
	background-size: 1581px 869px;

	.au & {
		background-position: center -300px;
	}

	@media 	(min--moz-device-pixel-ratio: 1.3),
	         	(-o-min-device-pixel-ratio: 2.6/2),
			 	(-webkit-min-device-pixel-ratio: 1.3),
			 	(min-device-pixel-ratio: 1.3),
			 	(min-resolution: 1.3dppx) { //detect retina
	    background-image: url('/media/images/assets/map@2x.jpg');
	    background-size: 1581px 869px;
	  }

	@include mq($iphone, false, max) {
		background-size: cover;
		background-position: center -50px!important;
	}
}

.block-error {
	text-align: center;
	.icon-ui-error {
		@include rem(margin-top, 30px);
		@include rem(margin-bottom, 30px);
	}

	//mq
  @include mq($imac, false, max) {
	  .icon-ui-error {
			@include rem(margin-top, 25px);
			@include rem(margin-bottom, 25px);
			@include rem(height, 250px);
		}
  }
  @include mq($macbook, false, max) {
	  .icon-ui-error {
			@include rem(margin-top, 20px);
			@include rem(margin-bottom, 20px);
			@include rem(height, 200px);
		}
  }
  @include mq($ipad, false, max) {
	  .icon-ui-error {
			@include rem(margin-top, 15px);
			@include rem(margin-bottom, 15px);
			@include rem(height, 150px);
		}
  }
  @include mq($portrait, false, max) {
	  .icon-ui-error {
			@include rem(margin-top, 10px);
			@include rem(margin-bottom, 10px);
			@include rem(height, 125px);
		}
  }
  @include mq($iphone, false, max) {
	  .icon-ui-error {
			margin-top: 0px;
			@include rem(margin-bottom, 5px);
			@include rem(height, 100px);
		}
		.alt-sub-heading {
	  	@include rem(font-size, 14px);
  	}
  }
}

.block-archive {
	display: table;
	@include rem(padding, 12px 0px);
	&.no-img {
		.img {
			display: none;
		}
		.content {
			padding-left: 0!important;
		}
	}
	.img, .content {
		display: table-cell;
	}
	.img {
		@include rem(height, 216px);
		width: 50%;
		@extend %background-cover;
		position: relative;
		&:after {
			content: '';
			display: block;
			height: 0;
			width: 0;

			position: absolute;
			right: 0;
			@include center(false, true);
			@include rem(border-width, 15px 15px 15px 0px);
			border-style: solid;
			border-color: transparent white transparent transparent;
			.highlight & {
				border-color: transparent $grey-f transparent transparent;
			}
		}
	}
	.content {
		@include rem(padding, 20px 0px 20px 30px);
		vertical-align: middle;
	}
	.alt-sub-heading {
		@include rem(margin-bottom, 30px);
	}
	p {
		color: $black-80;
		@include rem(font-size, $base-font-size);
		a {
			color: black;
			@include rem(font-size, 13px);
			border-bottom: 1px solid black;
		}
	}
	.list-links-inline {
		@include rem(margin-bottom, 5px);
	}

	//mq
	@include mq($imac, false, max) {
		.alt-sub-heading  {
			@include rem(margin-bottom, 25px);
		}
		.content {
			@include rem(padding-left, 25px);
		}
	}
	@include mq($macbook, false, max) {
		.alt-sub-heading  {
			@include rem(margin-bottom, 20px);
		}
		.content {
			@include rem(padding-left, 20px);
		}
		.img:after {
			@include rem(border-width, 12px 12px 12px 0px);
		}
	}
	@include mq($ipad, false, max) {
		.alt-sub-heading {
			@include rem(margin-bottom, 15px);
		}
		.content {
			@include rem(padding-left, 15px);
		}
		.img {
			@include rem(height, 200px);
			&:after {
				@include rem(border-width, 10px 10px 10px 0px);
			}
		}
		p {
			@include rem(font-size, 15px);
		}
	}
	@include mq($portrait, false, max) {
		.alt-sub-heading  {
			@include rem(margin-bottom, 10px);
		}
		p {
			@include rem(font-size, 14px);
		}
	}
	@include mq($iphone, false, max) {
		&, .img, .content {
			display: block;
		}
		.alt-sub-heading  {
			@include rem(margin-bottom, 5px);
		}
		.img {
			width: 100%;
			border: none;
			@include rem(margin-bottom, 15px);
			@include rem(height, 160px);
			&:after {
				@include rem(border-width, 0px 10px 10px 10px);
				border-color: transparent transparent white transparent;
				@include center(true, false);
				bottom: 0;
				top: inherit;
				.highlight & {
					border-color: transparent transparent $grey-f transparent;
				}
			}
		}
		.content {
			padding: 0;
		}
	}
}

.block-other-news {
	.title-container {
		@include rem(padding, 15px 125px 15px 0px);
		position: relative;
		display: inline-block;
		color: white;
		background-color: $black;
		&:before {
			right: 100%;
			width: 768px;
			width: 25vw;
			background-color: $black;
		}
		&:after {
			left: 100%;
			width: 2000px;
			width: 100vw;
			background-color: $grey-f;
		}
		&:after, &:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
		}
	}
	.controls {
		background-color: $black;
		cursor: pointer;
		transition: background-color .2s ease-in-out;
		position: absolute;
		right: 0;
		@include rem(width, 30px);
		.icon {
			@include rem(height, 25px);
			@include center;
			position: absolute;
		}
		&.next {
			top: 0;
			bottom: 50%;
			bottom: calc(50% + 0.5px);
		}
		&.previous {
			top: 50%;
			top: calc(50% + 0.5px);
			bottom: 0;
			border-top: 0.5px solid transparent;
		}
		&:hover {
			background-color: lighten($black, 15);
		}
	}
	ul {
		@include rem(padding, 20px 0px);
	}

	//mq
	@include mq($macbook, false, max) {
		.controls {
			.icon {
				@include rem(height, 22px);
			}
		}
	}
	@include mq($ipad, false, max) {
		.controls {
			@include rem(width, 25px);
			.icon {
				@include rem(height, 20px);
			}
		}
	}
	@include mq($portrait, false, max) {
		.title-container {
			display: block;
			text-align: center;
			padding-right: 0;
			&:after {
				background-color: $black;
			}
		}
		.title {
			@include rem(padding-left, 40px);
			@include rem(padding-right, 40px);
		}
		.controls {
			@include rem(width, 35px);
			&.previous {
				left: 0;
				right: inherit;
				top: 0;
			}
			&.next {
				bottom: 0;
			}
		}
	}
	@include mq($iphone, false, max) {
		.title {
			@include rem(padding-left, 25px);
			@include rem(padding-right, 25px);
		}
		.controls {
			@include rem(width, 40px);
			&.previous {
				@include rem(left, -20px);
			}
			&.next {
				@include rem(right, -20px);
			}
		}
	}
}

.block-cta {
  position: relative;
  text-align: center;
  @include rem(padding, 100px 0px);

  p {
    @include rem(margin-bottom, 25px);
    &:last-child {
      margin-bottom: 0;
    }
  }

  //mq
  @include mq($imac, false, max) {
    @include rem(padding-top, 80px);
    @include rem(padding-bottom, 80px);
    p {
      @include rem(margin-bottom, 23px);
    }
  }
  @include mq($macbook, false, max) {
    @include rem(padding-top, 60px);
    @include rem(padding-bottom, 60px);
    p {
      @include rem(margin-bottom, 20px);
    }
  }
  @include mq($ipad, false, max) {
    @include rem(padding-bottom, 40px);
    p {
      @include rem(margin-bottom, 15px);
    }
  }
  @include mq($portrait, false, max) {
    @include rem(padding-top, 30px);
    @include rem(padding-bottom, 30px);
  }
  @include mq($iphone, false, max) {
    @include rem(padding-top, 25px);
    @include rem(padding-bottom, 25px);
    text-align: left;
  }
}

.block-cta__image {
  position: absolute;
  bottom: 50px;
  right: 5%;
  width: 79px;

  @include mq($imac, false, max) {
    bottom: 50px;
    right: 5%;
    width: 79px;
  }
  @include mq($iphone, false, max) {
    bottom: 30px;
    right: 5%;
    width: 59px;
  }
}

.block-service {
  @include rem(padding-bottom, 30px);
}

.block-discover {
	.icon {
		vertical-align: middle;
		color: $red;
		@include rem(margin-right, 50px);
	}
	.alt-heading {
		display: inline-block;
	}

	.row {
		display: flex;
		flex-flow: row nowrap;
	}
	.columns:nth-child(2) {
		display: flex;
	}

	.btn {
		margin: auto 0 auto auto;
	}

	//mq
  @include mq($imac, false, max) {
	  .icon {
		  @include rem(margin-right, 45px);
	  }
	}
	@include mq($macbook, false, max) {
	  .icon {
		  @include rem(margin-right, 40px);
	  }
	}
	@include mq($ipad, false, max) {
	  .icon {
		  @include rem(margin-right, 30px);
	  }
	}
	@include mq($portrait, false, max) {
	  .icon {
		  @include rem(margin-right, 25px);
	  }
	}
	@include mq($iphone, false, max) {
		text-align: center;

		.row, .columns:nth-child(2) {
			display: block;
		}
	  .icon {
		  margin-right: 0;
		  @include rem(height, 50px);
	  }
	  .alt-heading {
		  display: block;
		  @include rem(margin-bottom, 25px);
	  }
	}
}

@each $color, $hexa in $colors {
	.block-#{$color} {
		background-color: $hexa;

		.background {
			background-color: $hexa;
			background-blend-mode: overlay;  //luminosity or multiply also
		}
	}
}

.block-news {
	@include clearfix;
	.card-8 {
		float: left;
		width: 66.6666666667%;
	}
	.news-more {
		width: 33.3333333333%;
		margin-left: 66.6666666667%;
		border-left: 4rem solid transparent;
	}

	//mq
	@include mq($imac, false, max) {
		.news-more {
			border-left-width: 3rem;
		}
	}
	@include mq($macbook, false, max) {
		.news-more {
			border-left-width: 2.5rem;
		}
	}
	@include mq($ipad, false, max) {
		.news-more {
			border-left-width: 1.25rem;
		}
	}
	@include mq($portrait, false, max) {
		.card-8 {
			float: none;
			width: auto;
		}

		.news-more {
			width: auto;
			margin-left: 0;
			border: none;
		}
	}
}

.block-help--title {
  @include whitney(700);
  font-size: rem(24px);
  letter-spacing: .28em;

  .icon {
    width: rem(55px);
    margin-right: rem(35px);
    color: $red;
    vertical-align: middle;
  }

  @include mq($macbook, false, max) {
    font-size: rem(22px);

    .icon {
      width: rem(40px);
      margin-right: rem(25px);
    }
  }

  @include mq($ipad, false, max) {
    font-size: rem(20px);

    .icon {
      width: rem(35px);
      margin-right: rem(15px);
    }
  }

  @include mq($portrait, false, max) {
    font-size: rem(18px);
    letter-spacing: .14em;

    .icon {
      width: rem(30px);
      margin-right: rem(10px);
    }
  }

  @include mq($iphone, false, max) {
    font-size: rem(16px);
    letter-spacing: 0;

    .icon {
      margin-right: rem(5px);
    }
  }
}


// button
//------------------------------------------------------------------------------
.btn {
  @include rem(font-size, 14px);
  text-transform: uppercase;
  @include rem(padding, 15px 30px);
  @include whitney(bold);
  @include rem(border-radius, 3px);
  display: inline-block;

  //reset
  &:active, &:focus {
	  outline: none;
		box-shadow: none;
  }

  //mq
  @include mq($imac, false, max) {
    @include rem(padding, 13px 28px);
  }
  @include mq($macbook, false, max) {
    @include rem(padding, 12px 25px);
  }
  @include mq($ipad, false, max) {
    @include rem(padding, 10px 20px);
  }
  @include mq($portrait, false, max) {
    @include rem(font-size, 13px);
    @include rem(padding, 8px 15px);
  }
  @include mq($iphone, false, max) {
    @include rem(font-size, 12px);
    @include rem(padding, 7px 12px);
  }

  &[disabled] {
    cursor: not-allowed;

    &, &:hover, &:focus {
      color: #c6c6c6;
      border-color: #c6c6c6;
      background-color: transparent;
    }
  }
}

.btn-cancel {
  padding-left: rem(10px);
  padding-right: rem(10px);
  color: rgba(black, .5);
  transition: color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
  .btn ~ & {
    margin-left: rem(25px);

    @include mq($iphone, false, max) {
      margin-left: rem(15px);
    }
  }
  &:hover {
    color: rgba(black, .75);
    background-color: transparent;
  }
}

.btn-block {
	width: 100%;
}

.btn-plain {
  color: white!important;
  border-radius: 0;
  transition: background-color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);

  &:hover {
    text-decoration: underline;
  }
}

.btn-sidebar {
	font-weight: normal;
	text-transform: none;
	@include rem(padding-top, 8px);
	@include rem(padding-bottom, 8px);
}

.btn-inverted {
  color: $black;
  border-width: 2px;
  border-style: solid;
  background-color: $white;
  transition-property: border-color, color, background-color!important;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0.070, 0.635, 0.415, 0.990);

  .highlight &:hover,
  &:hover {
    color: $black;
    background-color: #D9D9D9;
    text-decoration: underline;
  }

  .highlight & {
    color: $black;
  }

  //mq
  @include mq($portrait, false, max) {
    border-width: 1px;
  }
}

.btn-border {
	@include rem(padding-left, 60px);
	@include rem(padding-right, 60px);
	position: relative;
	overflow: hidden;

	&:hover, &:focus {
    text-decoration: underline;
  }

	//mq
	@include mq($imac, false, max) {
    @include rem(padding-left, 55px);
    @include rem(padding-right, 55px);
  }

  @include mq($macbook, false, max) {
    @include rem(padding-left, 40px);
    @include rem(padding-right, 40px);
  }

  @include mq($ipad, false, max) {
    @include rem(padding-left, 35px);
    @include rem(padding-right, 35px);
  }

  @include mq($portrait, false, max) {
    @include rem(padding-left, 30px);
    @include rem(padding-right, 30px);
  }

  @include mq($iphone, false, max) {
    @include rem(padding-left, 20px);
    @include rem(padding-right, 20px);
  }
}

.btn-simple-border {
  border: 2px solid currentColor;
}

.btn-icon {
	position: relative;
	@include hellip(100%);
	@include rem(padding-right, 50px);

	.icon {
		position: absolute;
		@include center(false, true);
		@include rem(right, 30px);
		@include rem(height, 16px);
	}
}

.btn-inline {
	background-color: transparent;
	transition: all .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
	&:hover, &:focus {
		background-color: $grey-f!important;
	}
}

.btn-spacer {
  padding-left: rem(15px);
  padding-right: rem(15px);

  @include mq($iphone, false, max) {
    padding-left: rem(10px);
    padding-right: rem(10px);
  }
}

.btn-margin {
  margin-left: rem(15px);
  margin-right: rem(15px);

  @include mq($iphone, false, max) {
    margin-left: rem(10px);
    margin-right: rem(10px);
  }
}

@each $color, $hexa in $colors {
	.btn-#{$color} {
		color: $hexa;

		&:hover, &:focus {
      color: $hexa;
			text-decoration: underline;
		}

		&.btn-inline {
			&:hover, &:focus {
				color: $hexa;
			}
		}

		&.btn-colorful {
			color: white!important;

			&:hover, &:focus {
				border-color: $hexa!important;
				background-color: $hexa!important;
			}
		}

    &.btn-rad {
      background-color: $hexa;
      color: white;
      border: 1px solid white;

      &:hover,
      &:focus {
        background-color: lighten($hexa, 2);
      }
    }
	}

	.btn-plain.btn-#{$color} {
		background-color: $hexa;

		&:hover, &:focus {
			background-color: lighten($hexa, 2);
		}
	}
}

.btn-small {
  font-size: 0.9em;
  padding: 0.7em 2.5em;
  border: 2px solid;

  .grid-img & {
    color: white;
  }

  &:hover, &:focus {
    &:before, &:after {
      width: 2em;
    }
  }
}

.btn-fat {
	@include rem(font-size, 14px);
  padding: 0.7em 1.5em;
  border: 2px solid black;
  &:hover {
    color: white;
  }
  &:focus {
     color: $black;
  }
  &:hover, &:focus {
     &:before, &:after {
      width: 1em;
    }
  }
}

.btn-compact {
	@include rem(font-size, 12px);
	@include rem(padding, 10px 15px);
	border-style: solid;
	@include rem(border-width, 1px);
	.icon {
		@include rem(height, 15px);
		@include rem(margin-left, 5px);
	}
}

.btn-play {
	transition: color .2s cubic-bezier(0.205, 0.700, 0.325, 0.980);
	&:hover.text-blue-dark {
		color: lighten($blue-dark,10);
	}
	@include mq($iphone, false, max) {
		position: relative;
		@include rem(padding, 10px 10px 10px 50px);
		@include rem(border-radius, 3px);
		text-align: left;
		.icon {
			position: absolute;
			@include rem(left, 8px);
			@include center(false, true);

		}
		&.text-blue-dark {
			background-color: $blue-dark;
			color: white;
		}
	}
}

.btn-description {
  margin-top: rem(15px);
  color: rgba($black, .8);
  @include whitney;
  font-size: rem(16px);

  @include mq($macbook, false, max) {
    font-size: rem(14px);
  }
  @include mq($ipad, false, max) {
    font-size: rem(12px);
  }
  @include mq($portrait, false, max) {
    font-size: rem(11px);
  }
}

.btn-inactive {
  cursor: default;
  opacity: .2;
}

.btn-stamp {
  display: flex;
  flex-flow: row nowrap;
  max-width: rem(545px);
  padding: rem(15px) rem(50px);
  margin-left: auto;
  margin-right: auto;
  border-radius: rem(5px);
  font-size: rem(20px);
  letter-spacing: .08em;
  line-height: 1.7;
  text-align: left;
  background-color: $red;

  &:hover {
    background-color: darken($red, 5);
  }

  & > * {
    align-self: center;
  }

  img {
    width: rem(100px);
    height: rem(100px);
    margin-right: rem(40px);
  }

  @include mq($imac, false, max) {
    max-width: rem(525px);
    padding-right: rem(40px);
    padding-left: rem(40px);

    img {
      width: rem(90px);
      height: rem(90px);
      margin-right: rem(30px);
    }
  }

  @include mq($macbook, false, max) {
    padding-right: rem(30px);
    padding-left: rem(30px);
    max-width: rem(475px);

    img {
      margin-right: rem(25px);
    }
  }

  @include mq($ipad, false, max) {
    max-width: rem(425px);
    padding: rem(10px) rem(25px);
    font-size: rem(18px);

    img {
      width: rem(75px);
      height: rem(75px);
      margin-right: rem(20px);
    }
  }

  @include mq($portrait, false, max) {
    max-width: rem(350px);
    padding-right: rem(20px);
    padding-left: rem(20px);
    font-size: rem(16px);

    img {
      margin-right: rem(15px);
    }
  }

  @include mq($iphone, false, max) {
    position: relative;
    display: block;
    min-height: 0;
    padding-right: rem(15px);
    padding-left: rem(15px);
    text-align: center;
    overflow: hidden;

    img {
      position: absolute;
      top: rem(-20px);
      left: rem(-10px);
      width: rem(100px);
      height: rem(100px);
      opacity: .3;
    }
  }
}

// tagline
//------------------------------------------------------------------------------
%strong {
	color: $red;
	@include rem(padding-bottom, 4px);
	@include rem(border-bottom-width, 3px);
  border-bottom-style: solid;
  //don't specify the border-color so it will be the same as the font color!!

  @include mq($portrait, false, max) {
		@include rem(padding-bottom, 2px);
    @include rem(border-bottom-width, 2px);
	}
}

.strongify strong {
	@extend %strong;
}

.block-centered {
  @include rem(padding, 55px 0 47px);
  text-align: center;
  p {
    letter-spacing: .04em;
    strong {
      display: inline-block;
      @extend %strong;
      font-weight: 500;
    }
  }

  //mq
  @include mq($imac, false, max) {
    @include rem(padding, 50px 0 42px);
  }
  @include mq($macbook, false, max) {
    @include rem(padding, 40px 0 32px);
  }
  @include mq($ipad, false, max) {
    @include rem(padding, 35px 0 27px);
  }
  @include mq($portrait, false, max) {
    @include rem(padding, 30px 0 24px);
  }
  @include mq($iphone, false, max) {
    @include rem(padding-top, 25px);
    @include rem(padding-bottom, 19px);
  }
}

// table
//------------------------------------------------------------------------------
%table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

%table-text {
  @extend %table;

  thead {
    text-align: left;
    th {
      white-space: nowrap;
      @include rem(padding-top, 7px);
      @include rem(padding-bottom, 7px);
    }
  }

  tbody {
    tr {
      @extend .hairline-bottom;
    }
    td {
      @include rem(padding-top, 15px);
      @include rem(padding-bottom, 15px);
    }
  }

  //mq
  @include mq($imac, false, max) {
    @include rem(font-size, 15px);
  }
  @include mq($macbook, false, max) {
    @include rem(font-size, 14px);
  }

  @include mq($portrait, false, max) {
    thead {
      display: none;
    }
    tbody {
      tr:nth-child(odd) {
        background-color: $grey-f;
      }
    }
  }
  @include mq($portrait, false, max) {
    tbody {
      td {
        display: block;
        @include rem(padding-left, 20px!important);
        @include rem(padding-right, 20px!important);
        @include rem(padding-top, 7px);
        @include rem(padding-bottom, 7px);
        &:first-child {
          @include rem(padding-top, 15px);
        }
        &:last-child {
          @include rem(padding-bottom, 15px);
        }
      }
    }
  }
  @include mq($iphone, false, max) {
    @include rem(margin-top, 20px);
    @include rem(margin-left, -20px);
    @include rem(margin-right, -20px);
  }
}

.table-documents {
  @extend %table-text;
  width: auto;

  a {
    text-decoration: underline;
  }

	thead th, tbody td {
		&:first-child {
			width: 65%;
			@include rem(padding-right, 50px);
		}
		&:nth-child(n+2):nth-last-child(2) {
			@include rem(padding-right, 25px);
		}
		@include mq($ipad, false, max) {
  		&:first-child {
  			width: 55%;
  		}
  		&:last-child {
  			width: 25%;
  		}
  	}
	}

	.title {
		font-weight: bold;
		color: $blue-dark;
		transition: color .2s ease-in-out;
		&:hover {
			color: lighten($blue-dark, 10);
		}
	}
	.closed {
		font-style: italic;
	}
	.note {
		@include rem(font-size, 13px);
	}

	//mq
	@include mq($portrait, false, max) {
		tbody {
			td {
				&:first-child, &:last-child  {
					width: 100%;
				}
				&:nth-child(2) {
					font-weight: bold;
					@include rem(font-size, 12px);
				}
			}
		}
	}
}

.table-events {
  @extend %table-text;
  table-layout: auto;
  font-size: rem(13px);
  thead, tbody {
    td {
      padding-left: rem(5px);
      padding-right: rem(5px);
      vertical-align: baseline;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
        width: 1px;
      }
    }
  }

  tbody td {
    padding-top: rem(10px);
    padding-bottom: rem(10px);
  }

  .date, .location {

  }
  .location {
    display: block;
    color: $blue-dark;
    font-size: rem(10px);
    font-weight: bold;
    text-transform: uppercase;
  }
  .date, .link {
    font-size: rem(12px);
  }

  @include mq($macbook, false, max) {
    tbody td {
      padding-top: rem(8px);
      padding-bottom: rem(8px);
    }
  }
  @include mq($portrait, false, max) {
    tbody td {
      &:before {
        display: block;
        color: #545454;
        font-size: rem(10px);
        text-transform: uppercase;
        content: attr(data-title);
      }
    }
  }
  @include mq($iphone, false, max) {
    width: 100vw;
  }
}

.table-form {
  @extend %table;
  @extend %table-text;

  thead {
    border-bottom: 1px solid $black-15;
    th {
      font-size: rem(14px);
    }
  }
  tbody {
    td {
      color: $black-80;
      font-size: rem(15px);

      @include mq($portrait, false, max) {
        &:before {
          display: block;
          margin-bottom: rem(5px);
          color: black;
          font-size: rem(12px);
          font-weight: bold;
          letter-spacing: .12em;
          text-transform: uppercase;
          content: attr(data-title)":";
        }
      }
    }
  }

  td, th {
    padding-left: rem(8px);
    padding-right: rem(8px);
    &:first-child { //date
      width: rem(100px);
    }
    &:nth-child(2) { //title

    }
    &:nth-child(3) { //state
      width: rem(125px);
    }
    &:nth-child(4) { //actions
      width: rem(160px);
    }

    @include mq($portrait, false, max) {
      padding-left: rem(5px);
      padding-right: rem(5px);
    }
  }

  a {
    display: inline-block;
    color: $red;
    transition: color .2s ease-in-out;

    @include mq($portrait, false, max) {
      border: 1px solid;
      border-radius: rem(3px);
      padding: rem(7px) rem(12px);
      transition-property: color, background-color;
    }
  }

  .list-actions {
    overflow: hidden;
    & > li {
      float: left;
      margin-right: rem(6px);
      font-size: rem(12px);
      font-weight: bold;
      text-transform: uppercase;
      &:after {
        margin-left: rem(6px);
        content: "•";
        color: $grey-f;
      }
      &:last-child {
        margin-right: 0;
        &:after {
          content: none;
        }
      }

      @include mq($portrait, false, max) {
        margin-right: 0;
        margin-bottom: rem(10px);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .start {
      color: $black-80;
      &:hover {
        color: darken($black-80, 10);
      }

      @include mq($portrait, false, max) {
        color: white;
        border-color: $black-80;
        background-color: $black-80;
        &:hover {
          color: white;
          background-color: darken($black-80, 10);
        }
      }
    }
    .continue {
      color: $blue;
      &:hover {
        color: darken($blue, 10);
      }
      @include mq($portrait, false, max) {
        color: white;
        background-color: $blue;
        border-color: $blue;
        &:hover {
          color: white;
          background-color: darken($blue, 10);
        }
      }
    }
  }

  @include mq($portrait, false, max) {
    &, tbody, tr, td {
      display: block;
      width: auto;
    }
    td {
      width: 100%!important;
    }
  }
}

.table {
  @extend %table;
  @extend %table-text;

  width: 100%;
}

// Suppliers
//------------------------------------------------------------------------------
.block-suppliers {
  @include rem(padding, 65px 0px);
  position: relative;

  //gradient
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    @include rem(height, 10px);

    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjIlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjY1Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);

    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 2%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 2%,rgba(0,0,0,0.65) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-8 */


    opacity: .09;
    filter: alpha(opacity=0.09);
    .ie9 & {
      filter: none;
    }
  }

  //gradient
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    @include rem(height, 10px);

    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjIlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjY1Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);

    background: -webkit-linear-gradient(bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 2%,rgba(0,0,0,0.65) 100%); /* Chrome10+,Safari5.1+ */
    background: linear-gradient(to top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 2%,rgba(0,0,0,0.65) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-8 */


    opacity: .09;
    filter: alpha(opacity=0.09);

    .ie9 & {
      filter: none;
    }
  }

  //mq
  @include mq($imac, false, max) {
    @include rem(padding-top, 45px);
    @include rem(padding-bottom, 45px);
  }
  @include mq($macbook, false, max) {
    @include rem(padding-top, 35px);
    @include rem(padding-bottom, 35px);
  }
  @include mq($ipad, false, max) {
    @include rem(padding-top, 25px);
    @include rem(padding-bottom, 25px);
  }
  @include mq($portrait, false, max) {
    @include rem(padding-top, 15px);
    @include rem(padding-bottom, 15px);
  }
  @include mq($iphone, false, max) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

// List
//------------------------------------------------------------------------------
ol {
	list-style: decimal inside;
}

.list-legal {
  position: relative;
  display: flex;
  @include rem(padding, 15px 0px);
  & > li {
    @include rem(font-size, 11px);
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: .03em;
    @include rem(margin-right, 45px);
    &.copyright {
      margin-left: auto;
    }
    &.country-selector {
      margin-right: 0;
      margin-left: auto;
    }
    & > a {
      color: $black-60;
    }
  }

  //mq
  @include mq($portrait, false, max) {
    &, & > li {
      display: block;
    }
    & > li {
      margin-bottom: rem(12px);
      &, &.copyright, &.country-selector {
        margin-right: 0;
        margin-left: 0;
      }

      &.country-selector {
        display: inline-block;
        margin-top: rem(12px);
        margin-bottom: rem(24px);
      }
      &.copyright {
        margin-bottom: 0;
      }
    }
  }
  @include mq($iphone, false, max) {
    display: block;
  }
}

.list-offices {
	& > li {
		@include rem(margin-bottom, 50px);
		&:nth-last-child(-n+3) {
			margin-bottom: 0;
		}
	}

  a {
    color: black;
  }

	address {
		@include rem(padding-left, 30px);
		@include rem(padding-right, 30px);
	}
	.details {
		color: $black-80;
		.type {
			color: black;
		}
	}

	.office {
		@include rem(margin-bottom, 15px);
		.hq {
			@include rem(font-size, $base-font-size);
			font-style: italic;
		}
	}

	//flex layout
	display: flex;
	flex-flow: row wrap;
	& > li {
		flex: 0 33.333%;

		display: flex;
		flex: column nowrap;
		address {
			flex: 1 auto;
		}
	}

	//mq
	@include mq($imac, false, max) {
		& > li {
			@include rem(margin-bottom, 40px);
		}
	}
	@include mq($macbook, false, max) {
		.office {
			@include rem(margin-bottom, 12px);
			.hq {
				@include rem(font-size, 15px);
			}
		}
		address {
			@include rem(padding-left, 25px);
			@include rem(padding-right, 25px);
		}
		& > li {
			@include rem(margin-bottom, 35px);
		}
	}
	@include mq($ipad, false, max) {
		.office {
			@include rem(margin-bottom, 10px);
			.hq {
				@include rem(font-size, 14px);
			}
		}
		address {
			@include rem(padding-left, 20px);
			@include rem(padding-right, 20px);
			@include rem(font-size, 15px);
		}
		& > li {
			@include rem(margin-bottom, 30px);
		}
	}
  @include mq($portrait, false, max) {
		.office {
			@include rem(margin-bottom, 8px);
			.hq {
				@include rem(font-size, 12px);
			}
		}
		address {
			@include rem(padding-left, 15px);
			@include rem(padding-right, 15px);
		}
		& > li {
			flex: 0 50%;
			& , &:nth-last-child(-n+3) {
				@include rem(margin-bottom, 25px);
			}
			&:nth-last-child(-n+2) {
				margin-bottom: 0;
			}
		}
	}
	@include mq($iphone, false, max) {
		display: block;
		.office {
			@include rem(margin-bottom, 5px);
		}
		address {
			padding-right: 0;
			@include rem(font-size, 14px);
			@include rem(padding-left, 10px);
		}
		& > li {
			& , &:nth-last-child(-n+3), &:nth-last-child(-n+2) {
				@include rem(margin-bottom, 15px);
			}
		}
	}
}

.list-offices-alt {
	counter-reset: offices;
	list-style: none;
	& > li {
		@include rem(padding-bottom, 50px);
		&:after {
			content: '';
			display: block;
			@include rem(width, 32px);
			height: 2px;
			background-color: $black;
			@include rem(margin-top, 40px);
			@include rem(margin-left, 25px);
		}
		.alt-sub-heading {
			@include rem(margin-bottom, 10px);
			position: relative;
			@include rem(padding-left, 25px);
			&:before {
				display: inline-block;
				counter-increment: offices;
				content: counter(offices)'.';
				position: absolute;
				@include rem(left, -5px);
			}
		}
		p {
			@include whitney;
			@include rem(font-size, $base-font-size);
			@include rem(padding-left, 25px);
			color: $black-80;
			&:nth-last-of-type(2) {
				@include rem(margin-bottom, 25px);
			}
		}
	}

	//flex
	display: flex;
	flex-flow: row wrap;
	& > li {
		flex: 0 50%;
		max-width: 50%;
		display: flex;
		flex-flow: column nowrap;
		*:last-child {
			flex: 1 auto;
		}

		border-style: solid;
		border-color: transparent;
		&:nth-child(odd) {
			@include rem(border-right-width, 70px);
		}
		&:nth-child(even) {
			@include rem(border-left-width, 70px);
		}
	}

	//mq
  @include mq($imac, false, max) {
		& > li {
			&:nth-child(odd) {
				@include rem(border-right-width, 45px);
			}
			&:nth-child(even) {
				@include rem(border-left-width, 45px);
			}
			.alt-sub-heading {
				&:after {
					@include rem(left, -10px);
				}
			}
		}
  }
  @include mq($macbook, false, max) {
		& > li {
			&:nth-child(odd) {
				@include rem(border-right-width, 30px);
			}
			&:nth-child(even) {
				@include rem(border-left-width, 30px);
			}
			&:after {
				@include rem(margin-left, 20px);
			}
			.alt-sub-heading {
				@include rem(padding-left, 20px);
				&:before {
					position: static;
					@include rem(margin-right, 5px);
				}
			}
			p {
				@include rem(padding-left, 20px);
			}
		}
  }
  @include mq($ipad, false, max) {
		& > li {
			&:nth-child(odd) {
				@include rem(border-right-width, 30px);
			}
			&:nth-child(even) {
				@include rem(border-left-width, 30px);
			}
			&:after {
				@include rem(margin-left, 15px);
			}
			.alt-sub-heading {
				@include rem(padding-left, 15px);
			}
			p {
				@include rem(padding-left, 15px);
				&:nth-last-of-type(2) {
					@include rem(margin-bottom, 20px);
				}
			}
		}
  }
  @include mq($portrait, false, max) {
		& > li {
			@include rem(padding-bottom, 30px);
			&:after {
				@include rem(margin-top, 20px);
			}
			&:after {
				@include rem(margin-left, 10px);
			}
			.alt-sub-heading {
				@include rem(padding-left, 10px);
			}
			p {
				@include rem(padding-left, 10px);
				&:nth-last-of-type(2) {
					@include rem(margin-bottom, 18px);
				}
			}
			&:nth-child(even) {
				border-left: none;
			}
			&:nth-child(odd) {
				border-right: none;
			}
		}

		//flex
		display: block;
		& > li {
			display: block;
			max-width: 100%;
		}
  }
  @include mq($iphone, false, max) {
		& > li {
			.alt-sub-heading {
				@include rem(margin-bottom, 5px);
				padding-left: 0;
			}
			&:after {
				margin-left: 0;
			}
			p {
				padding-left: 0;
				@include rem(font-size, 14px);
				&:nth-last-of-type(2) {
					@include rem(margin-bottom, 15px);
				}
			}
		}

  }
}

.list-expand {
	&.with-counter {
		counter-reset: faq;
		list-style: none;
		.alt-sub-heading {
			position: relative;
			&:before {
				counter-increment: faq;
				content: counter(faq)'. ';
				@include rem(margin-right, 4px);
			}
		}
	}

	& > li {
		&[class*="hairline"] {
      border-color: $black-15;

			&:before {
				bottom: -1px;
			}
		}

		@include rem(padding, 0px 30px 0 0);
		transition: background-color .2s ease-in-out;
		position: relative;

		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			background-color: transparent;
			width: 75%;
			width: 100vw;
			top: 0;
			bottom: 0;
			transition: background-color .2s ease-in-out;
		}

		&:before {
			right: 100%;
		}

		&:after {
			left: 100%;
		}
	}

	.icon-ui-arrow-bottom {
		vertical-align: middle;
		position: absolute;
		@include center(false, true);
		right: 0;
		transition: transform .2s ease-in-out;
	}

	.alt-sub-heading {
		@include rem(padding, 10px 30px 10px 0px);
	}

	.expand-content {
		@include rem(font-size, $base-font-size);
		color: $black-80;
		padding-left: 30%;
		position: relative;
		@include rem(margin, 50px 0px);
		&:before {
			content: '';
			display: block;
			@include rem(width, 32px);
			height: 1px;
			background-color: $black;
			position: absolute;
			left: 0;
			@include rem(top, 12px);
		}
	}

	//slideToggle
	& > li {
		&.revealed {
			background-color: $grey-f;
			border-color: transparent!important;
			&:before, &:after {
				background-color: $grey-f;
			}
			.icon-ui-arrow-bottom {
				transform: translate(0, -50%) rotate(180deg);
			}
		}
	}

	[rel="js-toggle-trigger"] {
		cursor: pointer;
	}

	//mq
  @include mq($imac, false, max) {
		.expand-content {
			@include rem(margin, 35px 0px 45px);
		}
		& > li {
			@include rem(padding, 0px 25px 0px 0px);
		}
  }
  @include mq($macbook, false, max) {
		.expand-content {
			@include rem(margin, 25px 0px 35px);
		}
		& > li {
			@include rem(padding, 0px 20px 0px 0px);
		}
  }
  @include mq($ipad, false, max) {
		.expand-content {
			@include rem(margin, 15px 0px 25px);
			padding-left: 25%;
		}

		& > li {
			@include rem(padding, 0px 15px 0px 0px);

			&:before, &:after {
				width: 50%;
				width: 50vw;
			}
		}
  }

  @include mq($portrait, false, max) {
		.expand-content {
			@include rem(margin, 10px 0px 20px);
			padding-left: 20%;
		}

		& > li {
			@include rem(padding, 0px 10px 0px 0px);
			&:before, &:after {
				width: 25%;
				width: 25vw;
			}
		}
  }

  @include mq($iphone, false, max) {
	  & > li {
			padding: 0;

			&:before, &:after {
				@include rem(width, 20px);
			}
		}

		.expand-content {
			@include rem(font-size, 14px);
			padding-left: 0;
			@include rem(margin, 0px 0px 10px);
			&:before {
				content: none;
			}
		}
  }
}

.list-people {
	@include clearfix;
	@include rem(font-size, $base-font-size);
	& > li {
		float: left;
		@include rem(padding-left, 30px);
		@include rem(margin-left, 30px);
		@include rem(margin-right, 30px);
		@include rem(margin-bottom, 30px);
	}

	.position {
		@include utopia;
	}
	.position {
		@include utopia(normal, italic);
		@include rem(margin-bottom, 35px);
	}
	.details {
		@include whitney;
	}

	//mq
  @include mq($imac, false, max) {
	  & > li {
		  @include rem(margin-left, 20px);
		  @include rem(margin-bottom, 27px);
	  }
		.position {
			@include rem(margin-bottom, 30px);
		}
  }
  @include mq($macbook, false, max) {
	  & > li {
		  @include rem(margin-left, 15px);
		  @include rem(padding-left, 25px);
		  @include rem(margin-bottom, 25px);
	  }
		.position {
			@include rem(margin-bottom, 20px);
		}
  }
  @include mq($ipad, false, max) {
	  & > li {
		  @include rem(margin-left, 10px);
		  @include rem(padding-left, 20px);
		  @include rem(margin-bottom, 20px);
	  }
		.position {
			@include rem(margin-bottom, 20px);
		}
  }
  @include mq($portrait, false, max) {
		& > li {
			float: none;
			margin-left: 0;
			@include rem(margin-bottom, 15px);
			&:last-child {
				margin-bottom: 0;
			}
	  }
		.position {
			@include rem(margin-bottom, 15px);
		}
  }
  @include mq($iphone, false, max) {
	  & > li {
		 	@include rem(padding-left, 10px);
	  }
	  .position {
			@include rem(margin-bottom, 10px);
		}
		.details, .position {
			@include rem(font-size, 14px);
		}
  }
}

.list-position {
	& > li {
		@include rem(margin-bottom, 65px);
		&[class*="hairline-left"]{
			@include rem(padding-left, 30px);
		}
		&:nth-last-child(-n+2) {
			margin-bottom: 0;
		}
		&:nth-last-child(2):nth-child(even) {
			@include rem(margin-bottom, 65px);
		}
	}

	.location {
		@include utopia(normal, italic);
		@include rem(margin-bottom, 25px);
	}
	.description {
		@include rem(margin-bottom, 25px);
		color: $black-80;
	}
	.dc, .description {
		@include whitney;
		@include rem(font-size, $base-font-size);
	}
	.dc-details {
		@include rem(font-size, $base-font-size);
		dt {
			@include utopia;
		}
		dd {
			@include whitney;
		}
	}

	//flex
	display: flex;
	flex-flow: row wrap;
	& > li {
		flex: 1 50%;
		max-width: 50%; //IE Fix;
		@include rem(border-right-width, 50px);
		border-right-style: solid;
		border-right-color: transparent;
	}

	//mq
  @include mq($imac, false, max) {
		& > li {
			@include rem(border-right-width, 40px);
			&[class*="hairline-left"]{
				@include rem(padding-left, 25px);
			}
			&, &:nth-last-child(2):nth-child(even) {
				@include rem(margin-bottom, 50px);
			}
		}
		.location, .description {
		  @include rem(margin-bottom, 22px);
	  }
  }
  @include mq($macbook, false, max) {
		& > li {
			@include rem(border-right-width, 30px);
			&[class*="hairline-left"]{
				@include rem(padding-left, 20px);
			}
			&, &:nth-last-child(2):nth-child(even) {
				@include rem(margin-bottom, 40px);
			}
		}
		.location, .description {
		  @include rem(margin-bottom, 20px);
	  }
  }
  @include mq($ipad, false, max) {
		display: block;
    & > li {
      max-width: 100%; //IE fix
			border-right: none;
			&[class*="hairline-left"]{
				@include rem(padding-left, 15px);
			}
			&, &:nth-last-child(2):nth-child(even), &:nth-last-child(-n+2) {
				@include rem(margin-bottom, 35px);
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		.location, .description {
		  @include rem(margin-bottom, 15px);
	  }
  }
  @include mq($portrait, false, max) {
	  & > li {
		  &[class*="hairline-left"]{
				@include rem(padding-left, 10px);
			}
		  &, &:nth-last-child(2):nth-child(even), &:nth-last-child(-n+2) {
				@include rem(margin-bottom, 25px);
			}
	  }
	  .location, .description {
		  @include rem(margin-bottom, 12px);
	  }
		.location {
			@include rem(font-size, 14px);
		}
		.dc-details, .dc, .description {
			@include rem(font-size, 15px);
		}
  }
  @include mq($iphone, false, max) {
	  .location, .description {
		  @include rem(margin-bottom, 10px);
	  }
	  .location {
			@include rem(font-size, 12px);
		}
		.dc-details, .dc, .description {
			@include rem(font-size, 14px);
		}
  }
}

.list-links {
	color: $red;
	line-height: 2;
	@include rem(margin-bottom, 25px);

	//mq
  @include mq($imac, false, max) {
		@include rem(margin-bottom, 22px);
  }
  @include mq($macbook, false, max) {
		@include rem(margin-bottom, 20px);
  }
  @include mq($ipad, false, max) {
		@include rem(margin-bottom, 15px);
  }
  @include mq($portrait, false, max) {
	  line-height: 3;
		@include rem(margin-bottom, 15px);
  }
  @include mq($iphone, false, max) {
	  @include rem(font-size, 14px);
  }
}

.list-links-inline {
	@include whitney;
	@include rem(font-size, 13px);
	display: block;
	a {
		@extend %link-href;
	}
	@include mq($iphone, false, max) {
		@include rem(font-size, 12px);
	}
}

.link-inline {
	@extend %link-href;
}

%link-href {
	@include whitney;
	@include rem(font-size, 13px);
	color: black;
	text-transform: uppercase;
	border-bottom: 1px solid black;
	display: inline-block;
	padding-bottom: 1px;
	transition-property: color, border-color;
	transition-duration: .2s;
	transition-timing-function: cubic-bezier(0.070, 0.635, 0.415, 0.990);
	&:hover {
		color: $red;
		border-color: $red;
	}

	//mq
	@include mq($iphone, false, max) {
		@include rem(font-size, 12px);
	}
}

.list-share {
	& > li {
		display: inline-block;
		vertical-align: middle;
		@include rem(margin-right, 20px);
		&:last-child {
			margin-right: 0;
		}
	}
	.icon {
		@include rem(height, 20px);
	}
}

.list-categories {
	& > li {
		display: inline-block;
		&:after {
			content: "|";
		}
		&:last-child {
			&:after {
				content: none;
			}
		}
	}
}

.list-news-aside {
	.list-links-inline {
		@include rem(margin-bottom, 5px);
	}
	.alt-sub-heading {
		@include rem(font-size, $base-font-size);
		@include rem(margin-bottom, 15px!important);
	}
	.img {
		display: block;
		background-color: $grey-f;
		width: 66%;
		height: 0;
		padding-bottom: 37.09%;
		@extend %background-cover;
	}
	.img, .content {
		float: left;
	}
	.content  {
		width: 34%;
		@include rem(padding-left, 30px);
		margin-bottom: 0!important;
	}
	p {
		@include whitney;
		color: $black-80;
		@include rem(font-size, 13px);
	}

	//mq
	@include mq($imac, false, max) {
		.content {
			@include rem(padding-left, 20px);
		}
	}
	@include mq($macbook, false, max) {
		.alt-sub-heading {
			@include rem(margin-bottom, 10px!important);
		}
		.content {
			@include rem(padding-left, 15px);
		}
	}
	@include mq($ipad, false, max) {
		@include rem(font-size, 15px);
		.alt-sub-heading {
			@include rem(font-size, 14px);
			@include rem(margin-bottom, 5px!important);
		}
		.img, .content {
			float: none;
			width: 100%;
		}
		.content {
			padding-left: 0;
		}
		.img {
			padding-bottom: 58.2%;
			@include rem(margin-bottom, 10px);
		}
	}
	@include mq($portrait, false, max) {
		.alt-sub-heading {
			@include rem(font-size, $base-font-size);
		}
	}
	@include mq($iphone, false, max) {
		.alt-sub-heading {
			@include rem(font-size, 14px);
		}
		.list-links-inline {
			&, a {
				@include rem(font-size, 11px);
			}
		}
		.container {
			@include rem(margin-bottom, 15px);
		}
		.img {
			padding-bottom: 47.5%;
		}
	}
}

.list-news-vertical {
	[class*="hairline"] {
		@include rem(padding-top, 20px);
		&:first-child {
			padding-top: 0;
		}
	}
	.container {
		overflow: hidden;
		@include rem(margin-bottom, 25px);
	}
}

.list-news-horizontal {
	display: flex;
	flex-flow: row nowrap;
	& > li {
		flex: 1;
		@include rem(margin, 0 35px);

		display: flex;
		flex-flow: column nowrap;

		.alt-sub-heading {
			margin-top: auto;
		}

		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	@include mq($portrait, false, max) {
		display: block;
		margin-left: 2.5rem;
		margin-right: 2.5rem;
		& > li {
			&, &:first-child, &:last-child {
				@include rem(margin-left, 20px);
				@include rem(margin-right, 20px);
			}
			@include rem(border-bottom-width, 1px);
			border-bottom-style: solid;
			border-bottom-color: $black-15;
			@include rem(margin-bottom, 20px);
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	@include mq($iphone, false, max) {
		display: block;
		margin: 0;
		& > li {
			&, &:first-child, &:last-child {
				margin-left: 0;
				margin-right: 0;
			}
			@include rem(margin-bottom, 15px);
		}
	}
}

.list-stats {
	//styling
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
	text-align: center;

	li > strong {
		position: relative;
		@include utopia(normal, normal);
		@include rem(font-size, 74px);
		line-height: 1;
		.icon {
			display: block;
			@include rem(max-height, 45px);
			@include rem(margin, 0 auto 15px);
		}
	}
	.label {
		display: block;
		@include rem(font-size, $base-font-size);
	}

	//layout
	overflow: hidden;
	& > li {
		float: left;
		width: 25%;
    float: none;
    display: inline-block;
    padding-top: 40px;
    padding-bottom: 40px;

		&:not(:last-child) {
			@include rem(border-right-width, 20px);
			border-right-style: solid;
			border-right-color: transparent;
		}
	}

  &--long li {
    width: 33.33%;
  }

  strong span {
    display: inline-block;
    border-top: 1px solid $grey-f;
    border-bottom: 1px solid $grey-f;
    margin-bottom: 20px;
  }

  .text {
    display: block;
    width: 100%;
  }

	//mq
	@include mq($imac, false, max) {
		li > strong {
			@include rem(font-size, 60px);
			.icon {
				@include rem(margin-bottom, 12px);
			}
		}
		& > li {
			&:not(:last-child) {
				@include rem(border-right-width, 15px);
			}
		}
	}
	@include mq($macbook, false, max) {
		li > strong {
			@include rem(font-size, 50px);
			.icon {
				@include rem(margin-bottom, 10px);
			}
		}
		.label {
			@include rem(font-size, 15px);
		}
		& > li {
			&:not(:last-child) {
				@include rem(border-right-width, 10px);
			}
		}
	}
	@include mq($ipad, false, max) {
		li > strong {
			@include rem(font-size, 40px);
			.icon {
				@include rem(max-height, 50px);
        @include rem(width, 50px);
				@include rem(margin-bottom, 10px);
			}
		}
		.label {
			@include rem(font-size, 14px);
		}
	}
	@include mq($portrait, false, max) {
		li > strong {
			@include rem(font-size, 30px);
			.icon {
				@include rem(margin-bottom, 8px);
			}
		}
		.label {
			@include rem(font-size, 13px);
		}
		& > li {
			width: 50%;
			@include rem(margin-bottom, 20px);
			&:nth-last-child(-n+2) {
				margin-bottom: 0;
			}
			&:nth-child(even) {
				border-right: none;
			}
		}
	}
	@include mq($iphone, false, max) {
    strong span {
      margin-bottom: 0;
    }

		li > strong {
			@include rem(font-size, 20px);
			.icon {
        @include rem(max-height, 50px);
				@include rem(width, 50px);
				@include rem(margin-bottom, 5px);
			}
		}
		& > li {
			width: 100%;
			border-right: none!important;
      padding-top: 20px;
      padding-bottom: 5px;
			@include rem(margin-bottom, 15px);
			&, &:nth-last-child(-n+2) {
				@include rem(margin-bottom, 15px);
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.list-block-links {
	//styling
	& > li {
		text-align: center;
		@include rem(padding, 60px 60px 115px);
		position: relative;
	}

  .icon-ui-directional-arrow {
    width: 73px;
  }

	.alt-heading, .btn {
		position: relative;
		z-index: 1;
	}
	.background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		@extend %background-cover;
		@extend %opacity-0;
		pointer-events: none;
		transition-property: opacity;
		transition-duration: .4s;
		transition-timing-function: cubic-bezier(0.070, 0.635, 0.415, 0.990);
	}
	.btn {
		width: intrinsic;
		margin-left: auto;
		margin-right: auto;
		&:hover + div {
			@extend %opacity-1;
		}
	}

	.stick-bottom {
		@include rem(margin-bottom, 25px);
	}

  .multi-title .stick-bottom {
    margin-bottom: 0;
  }

	//layout
	display: flex;
	flex-flow: row nowrap;
	& > li {
		flex: 1;
		display: flex;
		flex-flow: column wrap;
	}


	.lte-ie10 & {
		overflow: hidden;
		& > li {
			float: left;
			width: 33.333%;
			min-height: rem(332px);
			@include mq($macbook, false, max) {
				min-height: rem(297px);
			}
			@include mq($portrait, false, max) {
				float: none;
				width: 100%;
				min-height: auto;
			}
		}
	}

	//mq
  @include mq($imac, false, max) {
	  & > li {
		  @include rem(padding, 50px 50px 50px);
	  }
	}

	@include mq($macbook, false, max) {
	  & > li {
		  @include rem(padding, 40px 40px 70px);
	  }
	}

	@include mq($ipad, false, max) {
	  & > li {
		  @include rem(padding, 30px 30px 45px);
	  }
	}

	@include mq($portrait, false, max) {
	  & > li {
		  @include rem(padding, 25px 20px 30px);
	  }
	}
	@include mq($iphone, false, max) {
	  display: block;
	  & > li {
		  display: block;
		  @include rem(padding, 25px 20px);
	  }
	}
}

.list-block-links__text {
  @include utopia;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 22px;

  @include mq($iphone, false, max) {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
  }
}

.list-block-links__heading {
  min-height: 69px;

  @include mq($iphone, false, max) {
    min-height: auto;
  }
}

.list-services {
	@extend %list-block;

	//styling
	& > li {
		& > a {
			display: flex;
			flex-flow: column nowrap;
			@include rem(border-width, 10px);
			border-style: solid;
			border-color: $grey-f;
			text-align: center;
			transition-property: color, background-color;
			transition-duration: .2s;
			transition-timing-function: cubic-bezier(0.070, 0.635, 0.415, 0.990);
			color: $black-80;

			@include rem(height, 250px);
			&:hover {
				background-color: $grey-f;
				color: $red;
			}

			.lte-ie10 & {
				display: block;
			}
		}
	}

	.title {
		@include whitney(600, normal);
		letter-spacing: .28em;
		@include rem(font-size, 20px);
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.icon {
		@include rem(margin, auto auto 40px auto);
		@include rem(height, 70px);
	}

  //mq
  @include mq($imac, false, max) {
	  & > li > a {
		  @include rem(height, 225px);
	  }
	  .title {
		  @include rem(font-size, 18px);
	  }
	  .icon {
		  @include rem(height, 60px);
		  @include rem(margin-bottom, 35px);
	  }
	}
	@include mq($macbook, false, max) {
		& > li > a {
		  @include rem(height, 200px);
		  @include rem(padding-left, 7px);
		  @include rem(padding-right, 7px);
		  @include rem(border-width, 7px);
	  }
	  .title {
		  @include rem(font-size, $base-font-size);
	  }
	  .icon {
		  @include rem(height, 50px);
		  @include rem(margin-bottom, 30px);
	  }
	}
	@include mq($ipad, false, max) {
		& > li > a {
		  @include rem(height, 175px);
	  }
	  .title {
		  @include rem(font-size, 14px);
	  }
	  .icon {
		  @include rem(height, 40px);
		  @include rem(margin-bottom, 25px);
	  }
	}
	@include mq($portrait, false, max) {
		& > li > a {
		  @include rem(height, 150px);
		  @include rem(padding-left, 5px);
		  @include rem(padding-right, 5px);
		  @include rem(border-width, 5px);
	  }
	  .title {
		  @include rem(font-size, 12px);
	  }
	  .icon {
		  @include rem(height, 30px);
		  @include rem(margin-bottom, 20px);
	  }
	}
  @include mq($iphone, false, max) {
	  & > li > a {
		  @include rem(height, 125px);
	  }
	  .title {
		  @include rem(font-size, 11px);
	  }
	  .icon {
		  @include rem(margin-bottom, 15px);
	  }
	}
}

.list-services--fba {
  max-width: rem(1024px);
  @extend %list-block-3;

  .title {
    color: #3d3d3a;

    @include mq($iphone) {
      min-height: 43.5%;
    }
  }

  .icon {
    color: $red;

    @include mq($iphone) {
      margin-top: rem(25px);
      margin-bottom: auto;
    }
    @include mq($ipad, false, max) {
      margin-top: rem(10px);
    }
  }

  & > li > a {
    @include rem(padding, 10px 15px 20px);

    @include mq($ipad, false, max) {
      padding-left: rem(10px);
      padding-bottom: rem(15px);
      padding-right: rem(10px);
    }
  }

  @include mq($ipad) {
    margin-left: auto!important;
    margin-right: auto!important;
  }
}

.list-services--big {
	@extend %list-block-3;
	//styling {
	& > li > a {
		@include rem(padding, 20px 10px);
	}
}

.list-services--small {
	@extend %list-block-5;
	//styling
	& > li > a {
		@include rem(padding, 0px 10px 20px);
	}
}

%list-block {
  display: flex;
  flex-flow: row wrap;
	@include rem(margin-left, -15px);
	@include rem(margin-right, -15px);
	& > li {
		@include rem(border-right-width, 15px);
	  @include rem(border-left-width, 15px);
	  border-right-style: solid;
	  border-right-color: transparent;
	  border-left-style: solid;
	  border-left-color: transparent;

	  @include rem(margin-bottom, 15px * 2);
	}

	.lte-ie10 & {
		overflow: hidden;
		& > li {
			float: left;
			width: 100%;
		}
	}

	//mq
  @include mq($imac, false, max) {
	  @include rem(margin-left, -12.5px);
		@include rem(margin-right, -12.5px);
    & > li {
      @include rem(border-right-width, 12.5px);
      @include rem(border-left-width, 12.5px);

      @include rem(margin-bottom, 12.5px * 2);
    }
  }
  @include mq($macbook, false, max) {
	  @include rem(margin-left, -10px);
		@include rem(margin-right, -10px);
    & > li {
      @include rem(border-right-width, 10px);
      @include rem(border-left-width, 10px);

      @include rem(margin-bottom, 10px * 2);
    }
  }
  @include mq($ipad, false, max) {
	  @include rem(margin-left, -5px);
		@include rem(margin-right, -5px);
    & > li {
      @include rem(border-right-width, 5px);
      @include rem(border-left-width, 5px);

      @include rem(margin-bottom, 5px * 2);
    }
  }
}

@for $i from 3 through 5 {
  %list-block-#{$i} > li {
    flex: 0 100%/$i;
    max-width: 100%/$i; //IE fix

    &:nth-last-child(-n+#{$i}) {
		  margin-bottom: 0;
	  }

    @include mq($iphone, false, max) {
      flex: 0 50%;
  		max-width: 50%;
  		&:nth-last-child(-n+#{$i}) {
        @include rem(margin-bottom, 5px * 2);
      }
      &:last-child {
  		  margin-bottom: 0;
  		}
    }
  }
}

.list-buttons {
	display: flex;
	flex-flow: wrap row;
	& > li {
		@include rem(margin-bottom, 25px);
		@include rem(margin-right, 25px);
		&:last-child {
			margin-right: 0;
		}
    &:before {
      content: '';
    }
	}

	//mq
	@include mq($imac, false, max) {
		& > li {
			@include rem(margin-right, 22px);
			@include rem(margin-bottom, 22px);
		}
	}
	@include mq($macbook, false, max) {
		& > li {
			@include rem(margin-right, 20px);
			@include rem(margin-bottom, 20px);
		}
	}
	@include mq($ipad, false, max) {
		& > li {
			@include rem(margin-right, 17px);
			@include rem(margin-bottom, 17px);
		}
	}
	@include mq($portrait, false, max) {
		& > li {
			@include rem(margin-right, 15px);
			@include rem(margin-bottom, 15px);
		}
	}
	@include mq($iphone, false, max) {
		& > li {
			float: none;
			width: 100%;
			margin-right: 0;
			@include rem(margin-bottom, 20px);
			.btn {
				display: block;
				text-align: center;
			}
		}
	}

  .fba & {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
    & > li {
      flex: 1 1;
    }

    @include mq($ipad, false, max) {
      max-width: 80%;
    }
    @include mq($portrait, false, max) {
      max-width: none;
    }
    @include mq($iphone, false, max) {
      display: block;
      & > li:last-child {
        margin-bottom: 0;
      }

      .btn {
        width: 100%;
        display: block;
        text-align: center;
      }
    }
  }
}

.text ul.list-buttons {
  & > li {
    margin-right: 0;
    &:before {
      content: '';
    }
  }
}

ul.list-buttons li.item-center {
  margin-left: auto;
  margin-right: auto;

}

.list-key { //map
	& > li {
		position: relative;
		display: inline-block;
		margin-right: rem(20px);
		padding-left: rem(15px);
		&:before {
			position: absolute;
			top: 50%;
			left: 0;
			@include center(false, true);
			display: inline-block;
			width: rem(8px);
			height: rem(8px);
			border-radius: 50%;
			content: "";
		}
		&:last-child {
			margin-right: 0;
		}
		@each $color, $hexa in $colors {
			&.key-#{$color}:before {
				background-color: $hexa;
			}
		}

		@include mq($ipad, false, max) {
			padding-left: rem(10px);
			margin-right: rem(15px);
			&:before {
				width: rem(5px);
				height: rem(5px);
			}
		}
	}
}

.list-section {
	overflow: hidden;
	.list-section--section {
		float: left;
		@include mq($portrait, false, max) {
			float: none;
			width: 100%!important;
			.alt-sub-heading {
				max-width: none!important;
			}
		}
	}
}
.list-section--2 {
	.list-section--section {
		width: 50%;
		.alt-sub-heading {
			@include rem(max-width, 545px);
			margin-left: auto;
			margin-right: auto;
		}
	}
}
.list-section--3 {
	.list-section--section {
		width: 33%;
		.alt-sub-heading {
			@include rem(max-width, 545px);
			margin-left: auto;
			margin-right: auto;
		}
	}
}
.list-section--border {
	.list-section--section {
		@extend %hairline;
		@include rem(border-width, 0 1px 0 0);

		&:last-child {
			border: none;
		}

		@include mq($portrait, false, max) {
			@include rem(border-width, 0 0 1px 0);
		}
	}
}

.list-check {
  .fm {
    @include whitney(600);
    font-size: rem(14px);
    text-transform: uppercase;
    letter-spacing: .04em;

    @include mq($iphone, false, max) {
       font-size: rem(12px);
    }
  }

  &-title { //fba icon
    width: 100%;
    max-width: rem(260px);
    margin-bottom: rem(20px);

    @include mq($ipad, false, max) {
      margin-bottom: rem(10px);
    }
    @include mq($portrait, false, max) {
      max-width: rem(200px);
      margin-bottom: rem(5px);
    }
    @include mq($iphone, false, max) {
      max-width: rem(150px);
    }
  }

  & > li {
    position: relative;
    padding-left: rem(25px);
    margin-bottom: rem(18px);

    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      position: absolute;
      top: rem(5px);
      left: 0;
      width: rem(11px);
      height: rem(6px);
      border-width: 0px 0px 2px 2px;
      border-style: none none solid solid;
      border-color: $red;
      transform: rotate(-45deg);
      content: "";
    }
  }

  @include mq($iphone, false, max) {
    padding-bottom: rem(5px);
    margin-bottom: rem(10px);
    & > li {
      margin-bottom: rem(10px);
    }
  }
}

.list-options-block {
  counter-reset: option;

  & > li {
    margin-bottom: rem(25px);
    list-style: none;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      position: relative;
      display: block;
      min-height: rem(160px);
      padding: rem(50px) rem(25px) rem(50px) rem(180px);
      border: rem(10px) solid $grey-f;
      color: #3d3d3a;
      transition: background-color, color;
      transition-duration: .2s;
      transition-timing-function: ease-in-out;

      &:hover {
        color: $red;
        background-color: $grey-f;
      }

      &:before {
        position: absolute;
        top: 50%;
        left: rem(-5px);
        display: block;
        width: rem(95px);
        height: rem(95px);
        border-radius: 50%;
        background-color: white;
        color: black;
        @include utopia;
        font-size: rem(74px);
        transform: translate(-50%, -50%);
        text-align: center;
        letter-spacing: 0;
        line-height: rem(95px);
        counter-increment: option;
        content: counter(option);

        @include mq($imac, false, max) {
          width: rem(80px);
          height: rem(80px);
          font-size: rem(65px);
          line-height: rem(80px);
        }
        @include mq($macbook, false, max) {
          width: rem(70px);
          height: rem(70px);
          font-size: rem(45px);
          line-height: rem(70px);
        }
        @include mq($portrait, false, max) {
          width: rem(60px);
          height: rem(60px);
          font-size: rem(30px);
          line-height: rem(60px);
        }
        @include mq($iphone, false, max) {
          top: rem(-5px);
          left: 50%;
          width: rem(50px);
          height: rem(50px);
          font-size: rem(25px);
          line-height: rem(50px);
        }
      }

      @include mq($imac, false, max) {
        padding: rem(40px) rem(20px) rem(40px) rem(180px);
      }
      @include mq($macbook, false, max) {
        padding: rem(35px) rem(20px) rem(35px) rem(160px);
      }
      @include mq($ipad, false, max) {
        padding-left: rem(140px);
      }
      @include mq($iphone, false, max) {
        padding: rem(30px) rem(15px);
        border-width: rem(5px);
        text-align: center;
      }
    }
  }

  .icon {
    position: absolute;
    @include center(false, true);
    left: rem(75px);
    width: rem(64px);
    height: rem(54px);
    color: $red;

    @include mq($imac, false, max) {
      left: rem(70px);
    }
    @include mq($macbook, false, max) {
      left: rem(65px);
    }
    @include mq($ipad, false, max) {
      left: rem(50px);
    }
    @include mq($iphone, false, max) {
      position: static;
      left: auto;
      display: block;
      margin: rem(5px) auto rem(30px);
      transform: translate(0);
    }
  }

  p {
    margin-top: rem(5px);
    color: rgba(black, .8);
    font-weight: normal;
    font-size: rem(16px);
    text-transform: none;
    letter-spacing: 0;

    @include mq($portrait, false, max) {
      font-size: rem(14px);
    }
  }

  &-title {
    margin-bottom: rem(33px);
    color: black;
    @include utopia;
    font-size: rem(22px);

    @include mq($macbook, false, max) {
      margin-bottom: rem(25px);
      font-size: rem(20px);
    }

    @include mq($ipad, false, max) {
      margin-bottom: rem(20px);
      font-size: rem(18px);
    }

    @include mq($iphone, false, max) {
      margin-top: rem(15px);
      margin-bottom: rem(25px);
      font-size: rem(16px);
      text-align: center;
    }
  }
}

.list-options-small {
  .fake-tab--trigger:hover ~ span {
    background-color: $grey-f;
    color: $red;
  }

  & > li {
    position: relative;
    margin-bottom: rem(45px);

    &:last-child {
      margin-bottom: 0;
    }
    span {
      padding: rem(40px) rem(45px);
      display: block;
      border: 5px solid $grey-f;
      color: black;
      @include utopia;
      @include rem(font-size, 22px);
      transition-property: color, background-color;
      transition-timing-function: ease-in-out;
      transition-duration: .2s;

      &:hover {
        background-color: $grey-f;
        color: $red;
      }
    }

    @include mq($imac, false, max) {
      margin-bottom: rem(40px);
      span {
        padding: rem(30px) rem(35px);
      }
    }
    @include mq($macbook, false, max) {
      margin-bottom: rem(30px);
      span {
        padding: rem(20px) rem(30px);
        @include rem(font-size, 20px);
      }
    }
    @include mq($ipad, false, max) {
      margin-bottom: rem(25px);
      span {
        padding: rem(15px) rem(20px);
        @include rem(font-size, 18px);
      }
    }
    @include mq($portrait, false, max) {
      margin-bottom: rem(20px);
      span {
        padding: rem(10px) rem(15px);
      }
    }
    @include mq($iphone, false, max) {
      margin-bottom: rem(15px);
      span {
        @include rem(font-size, $base-font-size);
        padding-bottom: rem(15px);
      }
    }
  }
}

//for .list-stripped, see "card" section


// Address
//------------------------------------------------------------------------------
address {
	font-style: inherit;
}

.address-footer {
  ul > li {
    @include rem(margin, 2px 0px 1px);
    &.gap {
      @include rem(margin-bottom, 20px);
    }
    &.street-address {
      line-height: 1.85;
    }
  }
}

.address-hq {
	@include rem(margin-bottom, 30px);
	&[class*="hairline"] {
		@include rem(padding, 25px 0px 25px 25px);
	}
	.hq {
		@include rem(margin-bottom, 20px);
		span {
			font-style: italic;
			@include rem(font-size, $base-font-size);
		}
	}
	ul > li {
		color: $black-80;
		@include rem(font-size, $base-font-size);
	}

	.email {
		color: $red;
	}

	//mq
	@include mq($macbook, false, max) {
		&[class*="hairline"] {
			@include rem(padding-left, 20px);
		}
		.hq {
			span {
				@include rem(font-size, 14px);
			}
		}
	}
	@include mq($ipad, false, max) {
		&[class*="hairline"] {
			@include rem(padding-top, 20px);
			@include rem(padding-bottom, 20px);
			@include rem(padding-left, 15px);
		}
		.hq {
			@include rem(margin-bottom, 5px);
			span {
				@include rem(font-size, 12px);
			}
		}
	}
	@include mq($portrait, false, max) {
		&[class*="hairline"] {
			@include rem(padding-top, 15px);
			@include rem(padding-bottom, 15px);
			@include rem(padding-left, 10px);
		}
		.hq {
			span {
				@include rem(font-size, 13px);
			}
		}
	}
	@include mq($iphone, false, max) {
		&[class*="hairline"] {
			@include rem(padding-top, 10px);
			@include rem(padding-bottom, 10px);
			padding-left: 0;
		}
		.hq {
			@include rem(margin-bottom, 5px);
			span {
				@include rem(font-size, 12px);
			}
		}
		ul > li {
			@include rem(font-size, 14px);
		}
	}
}

// Footer
//------------------------------------------------------------------------------
.l-footer {
  @include rem(padding-top, 30px);
  border-top: 1px solid #d6d6d6;
  //mq
  @include mq($portrait, false, max) {
    @include rem(padding-top, 30px);
  }
  @include mq($iphone, false, max) {
    @include rem(padding-top, 25px);
  }
}

.company-logo--small {
  display: block;
  @include rem(height, 36px);
  @include rem(margin, 0 auto 24px);
  @include rem(width, 130px);

  //mq
  @include mq($macbook, false, max) {
    @include rem(margin-bottom, 40px);
  }
  @include mq($ipad, false, max) {
    @include rem(margin-bottom, 35px);
  }
  @include mq($portrait, false, max) {
    @include rem(margin-bottom, 30px);
  }
  @include mq($iphone, false, max) {
    @include rem(margin-bottom, 25px);
  }
}

.footer-nav-container {
  float: left;
  //padding: 0 3.125%;
  width: 100%;

  &.has-branches {
    @include mq($iphone, false, max) {
      float: none;
      display: table-cell;
      .text {
        padding-top: 0;
      }
    }
  }
}

.footer-nav {
  float: left;
  @include rem(padding, 0 0 48px);
  width: 100%;
  h4 {
    @include rem(font-size, 15px);
    @include whitney;
    text-transform: uppercase;
    letter-spacing: .3em;
    @include rem(margin-bottom, 20px);
    &[class*="hairline-bottom"] {
      @include rem(padding-bottom, 10px);
    }
  }
  ul > li {
    @include rem(font-size, 13px);
    @include whitney;
    & > a {
      color: black;
      display: inline-block;
      @include rem(padding, 2px 0px 1px);
      @include rem(border-bottom-width, 2px);
      border-bottom-style: solid;
      border-bottom-color: transparent;
      transition: border .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
      &:hover {
        border-bottom-color: $red;
      }
    }

    & > ul li {
      list-style:disc;
      margin-left: 20px;
    }
  }

  address {
    @include rem(font-size, 13px);
    @include whitney;
    line-height: 1.9;
  }

  address a {
    display: inline-block;
    border-bottom: 2px solid transparent;
    transition: border .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
  }

  address a:hover {
    border-bottom-color: $red;
  }

  //mq
  @include mq($imac, false, max) {
    @include rem(padding-bottom, 40px);
    h4 {
      @include rem(font-size, 14px);
    }
  }
  @include mq($macbook, false, max) {
    @include rem(padding-bottom, 35px);
    h4 {
      @include rem(font-size, 13px);
    }
  }
  @include mq($ipad, false, max) {
    @include rem(padding-bottom, 30px);
  }
  @include mq($portrait, false, max) {
    @include rem(padding-bottom, 25px);
  }
  @include mq($iphone, false, max) {
    @include rem(padding-bottom, 20px);
    h4 {
      @include rem(font-size, 12px);
    }
    ul > li {
      @include rem(font-size, 12px);
      & > a {
        @include rem(padding-top, 4px);
        @include rem(padding-bottom, 3px);
      }
    }

    &.has-stamp {
      width: rem(70px + 10px);
    }
  }
}

@include mq(580) {
  .footer-nav {
    float: left;
    width: 48%;
    margin-left: 4%;
    &:nth-child(odd) {
      clear: left;
      margin-left: 0;
    }
  }
}

@include mq(880) {
  .footer-nav {
    float: left;
    width: 31%;
    margin-left: 3.5%;
    &:nth-child(odd) {
      clear: none;
      margin-left: 3.5%;
    }
    &:nth-child(3n+1) {
      margin-left: 0;
    }
    &:nth-child(4n) {
      clear: left;
    }
  }
}

@include mq(1200) {
  .company-logo--small {
    float: left;
    margin: 0 6% 0 3.125%;
    width: 10.875%;
  }
  .footer-nav-container {
    float: left;
    @include rem(margin-bottom, 40px);
    padding: 0;
    width: 80%;
  }
  .footer-nav {
    margin-left: 0;
    margin-right: 2.0833333%;
    padding: 0;
    width: 17.7094%;
    img {
      width: 100%;
      margin-left: auto;
    }
    &:nth-child(odd) {
      clear: none;
      margin-left: 0;
    }
    &:nth-child(4n) {
      clear: none;
    }
  }
}

// Card
//------------------------------------------------------------------------------
.card {
	z-index: 1;

  //basic styling
  header {
	  @include rem(min-height, 120px);
	  @include rem(padding, 20px 30px 10px);
    //@include rem(padding, 20px 30px 40px);
    position: relative;
    transition-property: background-color, color;
    transition-duration: .4s;
    transition-timing-function: ease-in-out;
  }
  section {
    @include rem(padding, 25px 30px 30px);
    color: $black-80;
    z-index: 1;
    .icon {
	    display: block;
			@include rem(margin, 0 auto 25px);
    }
  }
  .description {
    @include whitney;
    @include rem(margin-bottom, 20px);
    &:last-child {
      margin-bottom: 0;
    }
  }
  .description, .details li {
    @include rem(font-size, $base-font-size);
  }
  .details {
    .type {
      @include rem(font-size, 13px);
    }
  }

  &:not(.card--gray) footer {
    background-color: $black !important;
  }

  footer {
	  @include rem(font-size, 14px);
    @include rem(padding, 10px 30px);
    position: relative;
    z-index: 1;
    ul  {
			text-align: center;
	    & > li {
	      display: inline-block;
	      @include rem(margin-left, 8px);
	      @include rem(margin-right, 8px);
	      &:first-child {
	        margin-left: 0;
	      }
	      &:last-child {
	        margin-right: 0;
	      }
			}
    }
    &, a {
	    color: white;
    }
  }
  .img-boundaries {
    position: relative;
  }

	&.card-cover .img {
		background-size: cover;
	}
	&.card-contain .img {
	  background-size: contain;
	}
  .img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    transition: opacity .4s ease-in-out;

    background-image: url("/media/images/assets/placeholder.svg");
    background-position: center;
  }

  .btn {
		display: block;
		@include rem(max-width, 210px);
		margin: auto;
		text-align: center;

		.lte-ie10 & {
			margin-bottom: rem(10px);
		}
	}

	section, footer {
		transition: opacity .4s ease-in-out;
	}
  section {
    @extend %opacity-0;
  }

  &:hover {
    header {
      background-color: $black;
      &, a {
	      color: white;
      }
    }
    .img {
      @extend %opacity-0;
      transition-delay: 0;
    }
    section, footer {
      @extend %opacity-1;
    }
  }

  //touch device
	.mobile &, .lte-ie10 & {
		section, footer, &:hover .img {
    	@extend %opacity-1;
  	}
		.img {
			position: static;
			@include rem(height, 220px);
		}
	}

  //mq
  @include mq($imac, false, max) {
    header {
	    @include rem(min-height, 115px);
			@include rem(padding-top, 18px);
      //@include rem(padding-bottom, 30px);
    }
    header, section, footer {
      @include rem(padding-left, 25px);
      @include rem(padding-right, 25px);
    }
    section {
      @include rem(padding-top, 20px);
      @include rem(padding-bottom, 25px);
    }
    .description, .details li {
      @include rem(font-size, 15px);
    }
  }
  @include mq($macbook, false, max) {
    header {
      @include rem(min-height, 110px);
			@include rem(padding-top, 15px);
      //@include rem(padding-bottom, 25px);
    }
    header, section, footer {
      @include rem(padding-left, 20px);
      @include rem(padding-right, 20px);
    }
    section {
      @include rem(padding-top, 20px);
      @include rem(padding-bottom, 20px);
    }
    .description, .details li {
      @include rem(font-size, 14px);
    }
  }
  @include mq($ipad, false, max) {
    header {
	    @include rem(min-height, 90px);
			@include rem(padding-top, 12px);
      //@include rem(padding-top, 15px);
      //@include rem(padding-bottom, 20px);
    }
    .description {
      @include rem(margin-bottom, 15px);
    }
  }
  @include mq($portrait, false, max) {
		header {
			@include rem(min-height, 80px);
			@include rem(padding-top, 10px);
		}
	}
  @include mq($iphone, false, max) {
    .img {
      position: static;
      @include rem(height,220px);
      @include rem(border-bottom-width, 1px);
      border-bottom-style: solid;
    }
    section, footer, &:hover .img {
      opacity: 1;
      filter: alpha(opacity=1);
    }
    footer {
      background-color: $black!important;
      ul > li {
        @include rem(margin-left, 12.5px);
        @include rem(margin-right, 12.5px);
      }
    }
  }
}

.card__link {
  display: block;
}

.card-news {
	@include rem(margin-bottom, 10px);
	position: relative;
	&:last-of-type {
		margin-bottom: 0;
	}
	&[class*="hairline-bottom"] {
		@include rem(padding-bottom, 10px);
	}
	header {
		background-color: $black;
		@include rem(padding-bottom, 15px);
		.list-links-inline {
			@include rem(margin-bottom, 20px);
			&, a {
				color: white;
				border-bottom-color: white;
			}
			a {
				&:hover {
					color: $red;
					border-bottom-color: $red;
				}
			}
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			height: 0;
			width: 0;
			@include rem(border-width, 15px 15px 0px 15px);
			border-style: solid;
			border-color: $black transparent transparent transparent;
			@include center(true, false);
			top: 100%;
		}
	}

	.alt-sub-heading {
		&, a {
			color: white;
		}
	}
	.read-more {
		color: black;
		border-bottom: 1px solid black;
	}
	section {
		@extend %opacity-1;
		background-color: $grey-f;
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
		p {
			@include rem(padding-left, 30px);
			@include rem(padding-right, 30px);
		}
		& > a.read-more {
			display: inline-block;
			@include rem(margin-left, 30px);
			@include rem(margin-right, 30px);
			@include rem(margin-top, 50px);
			@include rem(font-size, 13px);
		}
	}
	.img {
		position: static;
		@include rem(height, 100px);
		background-position: center;
		background-size: cover;
		display: block;
		@include rem(margin-bottom, 20px);
		height: 0;
		padding-bottom: 34.51%;
	}
	&:hover {
		.img {
			@extend %opacity-1;
		}
	}
	footer {
		@include rem(padding, 5px 30px 2px);
	}

	//mq
	@include mq($imac, false, max) {
		header {
			.list-links-inline {
				@include rem(margin-bottom, 18px);
			}
			&:after {
				@include rem(border-width, 12px 12px 0px 12px);
			}
		}
		section {
			p {
				@include rem(padding-left, 25px);
				@include rem(padding-right, 25px);
			}
			& > a.read-more {
				@include rem(margin-left, 25px);
				@include rem(margin-right, 25px);
				@include rem(margin-top, 40px);
			}
		}
		footer {
			@include rem(padding-left, 25px);
			@include rem(padding-right, 25px);
		}
	}
	@include mq($macbook, false, max) {
		header {
			.list-links-inline {
				@include rem(margin-bottom, 15px);
			}
			&:after {
				@include rem(border-width, 10px 10px 0px 10px);
			}
		}
		section {
			p {
				@include rem(padding-left, 20px);
				@include rem(padding-right, 20px);
			}
			& > a.read-more {
				@include rem(margin-left, 20px);
				@include rem(margin-right, 20px);
				@include rem(margin-top, 30px);
			}
		}
		footer {
			@include rem(padding-left, 20px);
			@include rem(padding-right, 20px);
		}
	}
	@include mq($ipad, false, max) {
		header {
			.list-links-inline {
				@include rem(margin-bottom, 10px);
			}
		}
		section {
			& > a.read-more {
				@include rem(margin-top, 20px);
			}
		}
	}
	@include mq($portrait, false, max) {
		@include rem(margin-bottom, 8px);
		&[class*="hairline-bottom"] {
			@include rem(padding-bottom, 8px);
		}
		&:last-of-type {
			@include rem(margin-bottom, 35px);
		}
		header {
			.list-links-inline {
				@include rem(margin-bottom, 8px);
			}
		}
		.img {
			@include rem(margin-bottom, 15px);
			padding-bottom: 40%;
		}
		section {
			p {
				@include rem(font-size, 15px);
			}
			& > a.read-more {
				@include rem(margin-top, 10px);
			}
		}
	}
	@include mq($iphone, false, max) {
		@include rem(margin-bottom, 5px);
		&[class*="hairline-bottom"] {
			@include rem(padding-bottom, 5px);
		}
		&:last-of-type {
			@include rem(margin-bottom, 25px);
		}
		header {
			.list-links-inline {
				@include rem(margin-bottom, 5px);
				&, a {
					@include rem(font-size, 12px);
				}
			}
		}
		.img {
			border: none;
			padding-bottom: 45%;
		}
		section {
			@include rem(padding-bottom, 15px);
			p {
				@include rem(font-size, 14px);
			}
			& > a.read-more {
				@include rem(margin-top, 5px);
			}
		}
	}
}

.block-training {
  header.section-header {
    margin-bottom:50px;
    .h2 {
      margin-bottom: 20px;
    }
  }
}
.card-training {
    @include rem(margin-bottom, 10px);
    display: inline-block;
    margin-bottom: 30px;
    &:last-of-type {
        margin-bottom: 0;
    }
    &[class*="hairline-bottom"] {
        @include rem(padding-bottom, 10px);
    }
    header {
        background-color: $black;
        @include rem(padding-bottom, 15px);
        .list-links-inline {
            @include rem(margin-bottom, 20px);
            &, a {
                color: white;
                border-bottom-color: white;
            }
            a {
                &:hover {
                    color: $red;
                    border-bottom-color: $red;
                }
            }
        }
        &:after {
            content: '';
            display: block;
            position: absolute;
            height: 0;
            width: 0;
            @include rem(border-width, 15px 15px 0px 15px);
            border-style: solid;
            border-color: $black transparent transparent transparent;
            @include center(true, false);
            top: 100%;
        }
    }
    .alt-sub-heading,
    .alt-sub-sub-heading {
        &, a {
            color: white;
        }
    }
    .alt-sub-sub-heading {
      font-size: 0.75rem;
    }
    section {
        width: auto;
        @extend %opacity-1;
    }
    button.download {
      display: inline-block;
      margin: 20px 0;
      text-decoration: none;
      padding: 15px 25px;
      border: 1px solid red;
      border-radius: 3px;
      font-size: 14px;

      &:hover {
        background-color: red;
        color: white;
      }
    }

    @include mq($iphone, false, max) {
      button.download {
        width: 100%;
        max-width: 100%;
      }
    }

    img {
      width: 250px;
      background-position: center;
      background-size: cover;
      display: block;
      float: right;
      margin: 17px;
    }
    &:hover {
        img {
            @extend %opacity-1;
        }
    }
    @include mq($iphone, false, max) {
      img {
        width: 100%;
        margin: 0;
      }
    }
    footer {
        @include rem(padding, 5px 30px 2px);
    }
}
.card-networking {
  .btn {
    display: block;
    max-width: 100%;
    margin: auto;
    text-align: center;
    margin-right: 20px;
  }
  section {
    display: inline-block;
  }
  section.actions {
    padding-top: 0;
  }
}

form.form-training {
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #d9d9d9;
    margin: 1em 0;
    padding: 0;
  }
  select {
    width:100%;
  }
}

.card-team {
	.img {
    background-position: right bottom;
  }

  .lte-ie10 & footer {
	  min-height: rem(46px);
  }
}

.card-hide-footer {
	header {
		&:after {
      content: "";
      display: block;
      background-color: $red;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 0;
      transition: width .3s ease-in-out;
    }
	}

	footer {
    @extend %opacity-0;
  }

  &:hover {
	  header {
		  &:after {
        @include rem(width, 23px);
        transition-delay: .4s;
      }
	  }
  }

  section {
    position: relative;
    z-index: 1;
    padding-right: 0;
  }

  .description {
    @include rem(height, 280px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 20px;
    margin-bottom: 0;
  }

  .description--small {
    @include rem(height, 200px);
  }

  //mq
  @include mq($ipad, false, max) {
		.description {
	  	@include rem(height, 250px);
	  }

    .description--small {
      @include rem(height, 200px);
    }
	}

  @include mq($iphone, false, max) {
	  .description {
		  height: auto;
	  }
	}
}

.card-download {
	section {
		text-align: center;
    padding: 20px;
	}

  header {
    min-height: 0;
  }

  .btn {
    display: inline-block;
    max-width: 100%;
    width: auto;
    margin: 0;
    text-align: center;
  }

  footer {
    min-height: 44px;
  }
}

.card-download__hover {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: center;
}

.card-download__hover__links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .btn {
    flex-grow: 1;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: 1px;
    margin-right: 1px;
  }
}

.card--gray {
  border-bottom: 1px solid $grey-f;
  border-left: 1px solid $grey-f;
}

.card--gray header {
  background-color: $grey-f;

  *,
  > .alt-sub-heading,
  > .list-links-inline a {
    color: $black;
    border-bottom-color: $black;
  }
}

.card--gray:hover header,
.card--gray:hover header a {
  color: $black;
}

.card--gray:hover header {
  background-color: $grey-f;
}

.card--gray footer {
  background-color: $grey-f;

  * {
    color: $black;
  }
}

.list-card--white {
	header, section, .img, footer {
    background-color: white;
  }
  .img {
	  border-color: $grey-f;
  }
  header.hairline-bottom {
    border-color: $grey-f;
  }
}

.list-card--grey {
	header, section, .img, footer {
    background-color: $grey-f;
  }
  .img {
	  border-color: white;
  }
  header.hairline-bottom {
    border-color: white;
  }
}

.list-card {
	.contrast & {
		padding-top: rem(45px);

		@include mq($imac, false, max) {
			padding-top: rem(40px);
		}
		@include mq($macbook, false, max) {
			padding-top: rem(30px);
		}
		@include mq($ipad, false, max) {
			padding-top: rem(20px);
		}
		@include mq($portrait, false, max) {
			padding-top: rem(10px);
		}
		@include mq($iphone, false, max) {
			padding-top: rem(20px);
		}
	}

	display: flex;
  flex-flow: row wrap;

  .lte-ie10 & > li {
	  float: left;
  }

	@for $i from 2 through 4 {
		&.list-card--#{$i} > li {
			flex: 0 100%/$i;
			max-width: 100%/$i; //IE fix
			&:nth-child(#{$i}n+1) {
				border-left-width: 0;
			}
			&:nth-child(#{$i}n+#{$i}) {
				border-right-width: 0;
			}
			@include mq($portrait, false, max) {
				&:nth-child(#{$i}n+1) {
					border-left-width: 5px;
				}
				&:nth-child(#{$i}n+#{$i}) {
					border-right-width: 5px;
				}
			}
		}
	}

  & > li {
    //spacing between lis
    @include rem(border-right-width, 15px);
    @include rem(border-left-width, 15px);
    border-right-style: solid;
    border-right-color: transparent;
    border-left-style: solid;
    border-left-color: transparent;

    display: flex;
    flex-flow: column nowrap;
    .card {
      flex: 1 auto;
      display: flex;
      flex-flow: column nowrap;

      .img-boundaries {
        flex: 1 auto;
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        background-color: white;

        section {
          width: 100%;
          flex: 1 auto;
        }

        footer {
          width: 100%;
          align-self: flex-end;
          margin-bottom: 0;
        }
      }
    }

		//default style with stripes
		@include rem(margin-bottom, 45px);

    @include mq($imac, false, max) {
	 		@include rem(border-right-width, 12.5px);
      @include rem(border-left-width, 12.5px);
      @include rem(margin-bottom, 40px);
	  }
	  @include mq($macbook, false, max) {
		  @include rem(border-right-width, 10px);
      @include rem(border-left-width, 10px);
      @include rem(margin-bottom, 30px);
		}
		@include mq($ipad, false, max) {
			@include rem(border-right-width, 5px);
      @include rem(border-left-width, 5px);
      @include rem(margin-bottom, 20px);
		}
		@include mq($portrait, false, max) {
			@include rem(margin-bottom, 10px);
			flex: 0 50%!important;
      max-width: 50%!important; //IE fix
      &:nth-child(odd) {
        border-left-width: 0!important;
      }
      &:nth-child(even) {
        border-right-width: 0!important;
      }
		}
		@include mq($iphone, false, max) {
			border-left: none!important;
      border-right: none!important;
		}
  }
  @include mq($iphone, false, max) {
    &, & > li, & > li .card-team, & > li .card-team .img-boudaries {
      display: block;
      max-width: 100%!important; //IE Fix;
    }
  }
}

.list-card--striped {
  @include rem(margin-bottom, 40px);

  @for $i from 2 through 4 {
    &.list-card--#{$i} > li {
      &:nth-child(#{$i*2}n+1) {
        //border top + bottom for filling the gap of the before and after trick
        @include rem(border-top-width, 45px);
        @include rem(border-bottom-width, 45px);
        border-style: solid;
        border-color: $grey-f transparent;
        //remove the margin
        margin-top: 0;
        margin-bottom: 0;

        //the pseudo elements are used to do the grey stripes
        &:before, &:after {
          content: '';
          display: block;
          position: absolute;
          @include rem(top, -45px);
          @include rem(bottom, -45px);
          background-color: $grey-f;
          pointer-events: none;
        }
        &:before {
          right: 100%;
          width: 3000px; //fallback
          width: 100vw;
        }
        &:after {
          left: 100%;
          width: 3000px; //fallback
          width: 100vw;
        }

        @include mq($imac, false, max) {
          @include rem(border-top-width, 40px);
          @include rem(border-bottom-width, 40px);
          &:before, &:after {
            @include rem(top, -40px);
            @include rem(bottom, -40px);
          }
        }

        @include mq($macbook, false, max) {
          @include rem(border-top-width, 30px);
          @include rem(border-bottom-width, 30px);
          &:before, &:after {
            @include rem(top, -30px);
            @include rem(bottom, -30px);
          }
        }

        @include mq($ipad, false, max) {
          @include rem(border-top-width, 20px);
          @include rem(border-bottom-width, 20px);
          &:before, &:after {
            @include rem(top, -20px);
            @include rem(bottom, -20px);
          }
        }

        @include mq($portrait, false, max) {
          border-top: 0;
          border-bottom: 0;
          @include rem(margin-top, 10px);
          @include rem(margin-bottom, 10px);
          &:before, &:after {
            content: none;
          }
        }
      }

      @for $a from 1 through $i {
        //styling the different row
        &:nth-child(#{$i*2}n+#{$a}) .card {
          header, section, .img, footer  {
            background-color: white;
          }
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			   	 .img {
					  	background-color: white!important;
					  }
			    }
          header.hairline-bottom {
            border-color: $grey-f;
          }
        }
      }

      @for $a from $i+1 through 2*$i {
        &:nth-child(#{$i*2}n+#{$a}) .card {
          header, section, .img, footer {
            background-color: $grey-f;
          }
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			   	 .img {
					  	background-color: white!important;
					  }
			    }
          header.hairline-bottom {
            border-color: white;
          }
        }
      }

      @for $a from 1 through 2*$i {
        &:nth-child(#{$i*2}n+#{$a}) .card {
          &:hover {
            header, footer {
              background-color: $black;
            }
          }
        }
      }
    }
  }

  & > li {
    //grey background
    position: relative;

    //to compensate the border top+bottom added below
    @include rem(margin-top, 45px);
    @include rem(margin-bottom, 45px);

    @include mq($imac, false, max) {
      @include rem(margin-top, 40px);
      @include rem(margin-bottom, 40px);
    }

    @include mq($macbook, false, max) {
      @include rem(margin-top, 30px);
      @include rem(margin-bottom, 30px);
    }

    @include mq($ipad, false, max) {
      @include rem(margin-top, 20px);
      @include rem(margin-bottom, 20px);
    }

    @include mq($portrait, false, max) {
	    &:nth-child(4n+1) .card, &:nth-child(4n+2) .card {
	      header, section, .img  {
	        background-color: white!important;
	      }
	      header.hairline-bottom {
	        border-color: $grey-f!important;
	      }
	    }

	    &:nth-child(4n+3) .card, &:nth-child(4n+4) .card {
	      header, section, .img {
	        background-color: $grey-f!important;
	      }
	      header.hairline-bottom {
	        border-color: white!important;
	      }
	    }

	    @include rem(margin-top, 10px);
      @include rem(margin-bottom, 10px);
      .card {
	      &:hover {
		      header {
			      background-color: $black!important;
		      }
	      }
      }
      &:nth-child(4n+1) {
        margin-top: 0!important;
        margin-bottom: 0!important;
        @include rem(border-top-width, 10px!important);
        @include rem(border-bottom-width, 10px!important);
        border-style: solid!important;
        border-color: $grey-f!important;
        border-left: none!important;
        //the pseudo elements are used to do the grey stripes
        &:before, &:after {
          content: ''!important;
          display: block;
          position: absolute;
          @include rem(top, -10px!important);
          @include rem(bottom, -10px!important);
          background-color: $grey-f;
          pointer-events: none;
        }
        &:before {
          right: 100%;
          @include rem(width, 30px);
        }
        &:after {
          left: 100%;
          width: $portrait; //fallback
          width: 60vw;
        }
      }
      &:nth-child(4n+4) {
	    	border-right: none!important;
	    }
	  }

	  @include mq($iphone, false, max) {
		  &:nth-child(2n+1) .card {
	      header, section, .img  {
	        background-color: white!important;
	      }
	      header.hairline-bottom {
	        border-color: $grey-f!important;
	      }
	      .img {
	        border-bottom-color: $grey-f!important;
	      }
	    }

	    &:nth-child(2n+2) .card  {
	      header, section, .img {
	        background-color: $grey-f!important;
	      }
	      header.hairline-bottom {
	        border-color: white!important;
	      }
	      .img {
	        border-bottom-color: white!important;
	      }
	    }

	    //margin-bottom: 0;
	    .card {
		    &:hover {
			    header {
				    background-color: $black!important;
			    }
		    }
	    }
      &:nth-child(4n+1) {
        border-top: 0!important;
        border-bottom: 0!important;
        @include rem(margin-top, 10px!important);
        @include rem(margin-bottom, 10px!important);
        &:before, &:after {
          content: none;
        }
      }
      &:nth-child(2n+1) {
        margin-top: 0!important;
        margin-bottom: 0!important;
        @include rem(border-top-width, 10px!important);
        @include rem(border-bottom-width, 10px!important);
        border-style: solid!important;
        border-color: $grey-f!important;
				border-left: none!important;
				border-right: none!important;
        //the pseudo elements are used to do the grey stripes
        &:before, &:after {
          content: ''!important;
          display: block!important;
          position: absolute;
          @include rem(top, -10px!important);
          @include rem(bottom, -10px!important);
          background-color: $grey-f!important;
          pointer-events: none;
        }
        &:before {
          right: 100%;
          @include rem(width, 25px);
        }
        &:after {
          left: 100%;
          width: $portrait; //fallback
          width: 60vw;
        }
      }
	  }
  }
}

// Timeline
//------------------------------------------------------------------------------
.timeline {
	overflow: hidden;
	position: relative;

	&[class*="hairline"] {
		border-color: $black-15;
	}
}

.list-timeline > li {
	@include rem(height, 700px);

	//mq
	@include mq($imac, false, max) {
		@include rem(height, 650px);
	}
	@include mq($macbook, false, max) {
		@include rem(height, 500px);
	}
	@include mq($ipad, false, max) {
		@include rem(height, 400px);
	}
	@include mq($portrait, false, max) {
		@include rem(height, 320px);
	}
	@include mq($iphone, false, max) {
		height: auto;
	}
}

.list-timeline { //move this one with transform
	backface-visibility: hidden;
	overflow: hidden;
	background-color: $grey-f;
	transition: width .2s ease-in-out;

	& > li {
		float: left;
		@extend %background-cover;
		transition: width .2s ease-in-out;

		//colors
		@each $color, $hexa in $colors {
			&.#{$color} {
				.timeline-hero .sub-heading {
					background-color: $hexa;
				}
				.btn-container {
					background-color: lighten($hexa, 5);
				}
			}
		}

		.timeline-hero .sub-heading {
			//styling
			font-weight: normal;
			text-align: center;
			background-color: $black-15;
			margin-bottom: 0;
			line-height: 1;
			@include rem(padding, 30px 5px);
		}

		.btn-container {
			background-color: lighten($black-15, 5);
			@include rem(padding, 30px 5px);
			text-align: center;
			transform: rotateX(180deg);
			transform-origin: 50% 0;
			transition: transform .4s cubic-bezier(0.070, 0.635, 0.415, 0.990);
			backface-visibility: hidden;
		}
		&:hover {
			.btn-container {
				transform: rotateX(0deg);
			}
		}

		.btn {
			@include rem(font-size, $base-font-size);
			width: 100%;
			@include rem(max-width, 257px);
		}
	}

	//mq
	@include mq($imac, false, max) {
		 & > li {
			.btn-container {
				@include rem(padding-top, 25px);
				@include rem(padding-bottom, 25px);
			}
		 }
	}
	@include mq($macbook, false, max) {
	 & > li {
			.btn-container {
				@include rem(padding-top, 20px);
				@include rem(padding-bottom, 20px);
			}
			.btn {
				@include rem(font-size, 15px);
				@include rem(max-width, 220px);
			}
		 }
	}
	@include mq($ipad, false, max) {
		& > li {
			.timeline-hero .sub-heading {
				 @include rem(padding, 20px);
			}
			.btn-container {
				@include rem(padding, 15px 10px);
			}
			.btn {
				@include rem(font-size, 14px);
				@include rem(max-width, 200px);
			}
		 }
	}
	@include mq($portrait, false, max) {
		& > li {
			.btn-container {
				@include rem(padding-top, 10px);
				@include rem(padding-bottom, 10px);
			}
			.btn {
				@include rem(font-size, 12px);
				@include rem(max-width, 150px);
			}
		 }
	}
	@include mq($iphone, false, max) {
		display: none;
	}
}

.list-years {
	@include rem(padding-bottom, 50px);
	& > li {
		@include rem(margin-bottom, 20px);
		&:last-chidl {
			margin-bottom: 0;
		}
		&[class*=hairline-bottom] {
			@include rem(padding-bottom, 25px);
			border-color: rgba(white, .2);
		}
		& > * {
			@include rem(padding-left, 30px);
		}
	}

	//mq
	@include mq($imac, false, max) {
		& > li {
			@include rem(margin-bottom, 15px);
			&[class*=hairline-bottom] {
				@include rem(padding-bottom, 20px);
			}
			& > * {
				@include rem(padding-left, 25px);
			}
		}
	}
	@include mq($macbook, false, max) {
		& > li {
			@include rem(margin-bottom, 12px);
			&[class*=hairline-bottom] {
				@include rem(padding-bottom, 15px);
			}
			& > * {
				@include rem(padding-left, 20px);
			}
		}
	}
	@include mq($ipad, false, max) {
		& > li {
			@include rem(margin-bottom, 10px);
			&[class*=hairline-bottom] {
				@include rem(padding-bottom, 10px);
			}
			& > * {
				@include rem(padding-left, 15px);
			}
		}
	}
	@include mq($portrait, false, max) {
		& > li {
			& > * {
				@include rem(padding-left, 10px);
			}
		}
	}
	@include mq($iphone, false, max) {
		@include rem(padding-bottom, 15px);
		& > li {
			& > * {
				padding-left: 0;
			}
		}
	}
}

#fill {
	display: block;
	position: absolute;
	z-index: 1;
	transition-property: top, left, bottom, right;
	transition-duration: .6s;
	transition-timing-function: cubic-bezier(0.160, 0.785, 0.310, 1.000);

	//mq
	@include mq($iphone, false, max) {
		display: none!important;
	}
}

.timeline-details {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10;

	header {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		background-color: $grey-f;
		@include rem(width, 336px);
		text-align: center;
		@include rem(padding, 30px 10px);
	}

	.decade {
		font-weight: normal;
		line-height: 1;
		@include rem(margin-bottom, 60px);
	}

	section {
    @include rem(padding, 65px 45px);
    @include rem(left, 336px);
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;

		&:after {
			content: '';
			display: block;
			position: absolute;
			@include rem(bottom, 65px);
			left: 0;
			@include rem(right, 290px);
			@include rem(height, 95px);
			pointer-events: none;
		}

		//layout
		display: flex;
		flex-flow: row nowrap;

		.main {
			overflow: hidden;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;

			flex: 1 auto;
			@include rem(padding-right, 97px);
		}
	}

	.alt-sub-heading {
		color: black;
	}

	.year {
    @include rem(margin-bottom, 10px);
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			height: 0;
			width: 0;
			@include rem(border-width, 8px 0px 8px 10px);
			border-color: transparent transparent transparent black;
			border-style: solid;

			@include center(false, true);
			left: 0;
		}
	}

	aside {
		flex: 0 0 256px;
		@include rem(padding-left, 30px);

		&[class*="hairline"] {
			border-color: rgba(white, .2);
		}

		.alt-sub-heading {
			@include rem(margin-bottom, 10px);
		}

		p {
			@include rem(font-size, 13px);
			@include rem(margin-bottom, 15px);

			&:last-child {
				margin-bottom: 0;
			}
		}

		img {
      @include rem(margin-bottom, 15px);
			box-shadow: 0px 0px 0px 5px rgba(black, .1);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	//color
	@each $color, $hexa in $colors {
		&.#{$color} {
			header, .btn {
				color: lighten($hexa, 5);

        @include mq($iphone, false, max) {
          border-bottom: 1px solid;
        }
			}

  		section {
				background-color: lighten($hexa, 5);
				@include scrollbars(4px, $hexa);
				&:after {
					background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(lighten($hexa, 5),0)), color-stop(100%,rgba(lighten($hexa, 5),1))); /* Chrome,Safari4+ */
					background: linear-gradient(to bottom,	 rgba(lighten($hexa, 5),0) 0%,rgba(lighten($hexa, 5),1) 100%); /* W3C */
				}

				@if #{$color} == 'black' {
					.alt-sub-heading {
						color: white;
					}
					.year:before {
						border-left-color: white;
					}
				}

				@include mq($iphone, false, max) {
					background-color: rgba(lighten($hexa, 5), .95);
				}
			}
		}
	}

	//default color
	.btn, header {
		color: $black-80;
	}

	//mq
	@include mq($imac, false, max) {
		header {
			@include rem(width, 300px);
		}

		.decade {
			@include rem(margin-bottom, 50px);
		}

		section {
			@include rem(left, 300px);
			@include rem(padding, 50px 35px);
			.main {
				@include rem(padding-right, 65px);
				p {
					@include rem(font-size, $base-font-size);
				}
			}

			&:after {
				@include rem(bottom, 50px);
				@include rem(right, 254px);
				@include rem(height, 60px);
			}
		}

		aside {
			flex: 0 0 225px;
			@include rem(padding-left, 25px);
			.alt-sub-heading {
				@include rem(margin-bottom, 8px);
			}
		}

		.year {
			@include rem(margin-bottom, 8px);
			&:before {
				@include rem(border-width, 6px 0px 6px 8px);
			}
		}
	}

	@include mq($macbook, false, max) {
		header {
			@include rem(width, 250px);
		}
		.decade {
			@include rem(margin-bottom, 40px);
		}
		section {
			@include rem(left, 250px);
			@include rem(padding, 35px);
			.main {
				@include rem(padding-right, 40px);
				p {
					@include rem(font-size, 15px);
				}
			}
			&:after {
				@include rem(bottom, 35px);
				@include rem(right, 189px);
				@include rem(height, 40px);
			}
		}
		.btn {
			@include rem(font-size, 14px);
		}
		aside {
			flex: 0 0 150px;
			@include rem(padding-left, 20px);
			.alt-sub-heading {
				@include rem(margin-bottom, 5px);
			}
		}

		.year {
			@include rem(margin-bottom, 6px);
			&:before {
				@include rem(border-width, 5px 0px 5px 7px);
			}
		}
	}

	@include mq($ipad, false, max) {
		header {
			@include rem(width, 175px);
		}
		.decade {
			@include rem(margin-bottom, 30px);
		}
		section {
			@include rem(left, 175px);
			@include rem(padding, 20px);
			.main {
				@include rem(padding-right, 25px);
				p {
					@include rem(font-size, 14px);
				}
			}
			&:after {
				@include rem(bottom, 20px);
				@include rem(right, 159px);
				@include rem(height, 30px);
			}
		}
		.btn {
			@include rem(font-size, 12px);
		}
		aside {
			flex: 0 0 135px;
			@include rem(padding-left, 15px);
			.alt-sub-heading {
				@include rem(margin-bottom, 2px);
			}
		}

		.year {
			@include rem(margin-bottom, 5px);
			&:before {
				@include rem(border-width, 4px 0px 4px 6px);
			}
		}
	}

	@include mq($portrait, false, max) {
		header {
			@include rem(width, 125px);
			@include rem(padding-left, 5px);
			@include rem(padding-right, 5px);
		}
		.decade {
			@include rem(margin-bottom, 20px);
		}
		section {
			@include rem(left, 125px);
			.main {
				@include rem(padding-right, 20px);
			}
			&:after {
				@include rem(right, 144px);
				@include rem(height, 25px);
			}
		}
		.btn {
			@include rem(font-size, 11px);
			@include rem(padding-left, 5px);
			@include rem(padding-right, 5px);
		}
		aside {
			flex: 0 0 120px;
		}
	}

	@include mq($iphone, false, max) {
		&, header {
			display: block;
		}
		&, header, section {
			position: static;
		}
		header {
			background-color: white;
			width: 100%;
			@include rem(padding-top, 10px);
			@include rem(padding-bottom, 7px);
			.btn {
				display: none;
			}
		}
		.decade {
			margin-bottom: 0;
		}

		section {
			display: block;
      transition: 0.5s;
      max-height: 0;
      overflow: hidden;
      padding: 0;

			.main {
				overflow: visible;
				padding-right: 0;
        padding: 20px;
			}

			&:after {
				content: none;
			}
		}

    &.is-active section {
      max-height: 9999px;
      transition: 1.5s;
    }

		.year {
			@include rem(padding-left, 10px);
		}

		aside {
			padding-left: 0;

			&[class*="hairline"] {
				border: none;
			}
		}

		.btn {
			@include rem(font-size, 10px);
		}
	}
}

@-moz-document url-prefix() { //firefox hack
	.timeline-details {
		aside {
			flex: none!important;
			width: 256px;
			//mq
			@include mq($imac, false, max) {
				width: 225px;
			}
			@include mq($macbook, false, max) {
				width: 150px;
			}
			@include mq($ipad, false, max) {
				width: 135px;
			}
			@include mq($portrait, false, max) {
				width: 120px;
			}
		}
	}
}


// Antimation
//------------------------------------------------------------------------------
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    transform: translateY(0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    transform: translateX(0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes scalefade {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(1.4);
		opacity: 0;
	}
}


.tada {
  animation-name: tada;
}

@keyframes tada {
  from {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }

  10% {
    opacity: 1;
  }

  30%, 90% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.anim-scale-fade {
	animation: scalefade .2s;
	animation-fill-mode: forwards;
}

// grid
//------------------------------------------------------------------------------
.grid {
	background-color: $black;

	clear: both;
	@include clearfix;
	& > [class*="w-"] {
		position: relative;
		overflow: hidden;
		float: left;
		height: 0;

    padding-bottom: 27.711%;
		&[class*="x2"] {
  		padding-bottom: 55.385%;

  		@include mq($macbook, false, max) {
        padding-bottom: 55.35%;
      }
		}

		&.contains-grid { //reset for grid container (grid inside grid)
			height: auto!important;
			padding-bottom: 0!important;
			border-bottom: none!important;

			.grid > [class*="w-"] {
        padding-bottom: 27.711% * 2;
      }
		}
	}

	//sizing
	&.grid-1 {
		& > [class*="w-1x"] {
			width: 100%;
		}
	}
	&.grid-2 {
		& > [class*="w-1x"] {
			width: 50%;
		}
		& > [class*="w-2x"] {
			width: 100%;
		}
	}
	&.grid-3 {
		& > [class*="w-1x"] {
			width: 33.333%;
		}
		& > [class*="w-2x"] {
			width: 66.6666%;
		}
		& > [class*="w-3x"] {
			width: 100%;
		}
	}
	&.grid-4 {
		& > [class*="w-1x"] {
			width: 25%;
		}
		& > [class*="w-2x"] {
			width: 50%;
		}
		& > [class*="w-3x"] {
			width: 75%;
		}
		& > [class*="w-4x"] {
			width: 100%;
		}
	}
	&.grid-5 {
		& > [class*="w-1x"] {
			width: 20%;
		}
		& > [class*="w-2x"] {
			width: 40%;
		}
		& > [class*="w-3x"] {
			width: 60%;
		}
		& > [class*="w-4x"] {
			width: 80%;
		}
		& > [class*="w-5x"] {
			width: 100%;
		}
		&.grid-w-border {
			& > [class*="w-"] {
				border-width: 0 2px;
				border-style: solid;
				border-color: white;
				&:first-child {
					border-left: none;
				}
				&:last-child {
					border-right: none;
				}
			}
		}
	}

	//content
	.grid-img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		@extend %background-cover;
	}

	.grid-link {
		text-align: center;
		text-transform: uppercase;
		color: white;
		background-color: transparent;
		background-blend-mode: soft-light;
		transition-property: background-color;
		transition-timing-function: cubic-bezier(0.255, 0.695, 0.345, 0.940);;
		transition-duration: .2s;
		transition-delay: 0;

		display: flex;
		flex-flow: column nowrap;
		align-items: center;

		.lte-ie10 & {
			display: block;
			height: 100%;
		}

		&:hover {
			transition-delay: .2s;
		}

		.alt-sub-title {
			margin-top: auto;
			text-shadow: 0 0 rem(4px) rgba(black, .4);
		}
		.btn {
			margin-bottom: auto;
			filter: drop-shadow(0px 0px rem(4px) rgba(black, 0.2));
		}

		&.center {
			.icon {
				@include rem(margin-bottom, 35px);
			}
		}

		&.wide {
			flex-direction: row;
			@include rem(padding, 20px 25px);

			.icon {
				position: absolute;
				@include center;
				@include rem(max-height, 150px);
			}

			.alt-sub-title {
				flex: 1;
				margin-left: 0;
				margin-right: auto;
				text-align: left;
			}

			&.top {
				.alt-sub-title, .btn {
					margin-top: 0;
					margin-bottom: auto;
				}
				.icon {
					@include rem(margin-bottom, -15px);
				}
			}
			&.bottom {
				.alt-sub-title, .btn {
					margin-top: auto;
					margin-bottom: 0;
				}
				.icon {
					@include rem(margin-top, -15px);
				}
			}
		}


		.alt-sub-title {
			text-transform: uppercase;
			letter-spacing: .2em;
			@include rem(padding, 0 5px);
			@include rem(font-size, 22px);

			@include rem(margin-bottom, 25px);
		}

		@each $color, $hexa in $colors {
			&.theme-#{$color}:hover {
				@if $hexa != $black {
					background-color: $hexa;
				} @else {
					background-color: #545454;
				}
				.btn {
					border-color: $hexa;
					background-color: $hexa;
				}
			}
		}

		//mq
		@include mq($imac, false, max) {
			.alt-sub-title {
				@include rem(font-size, 21px);
			}
			&.wide .icon {
				@include rem(max-height, 125px);
			}
			.btn {
				@include rem(padding, 10px 25px);
			}
		}
		@include mq($macbook, false, max) {
			.alt-sub-title {
				@include rem(font-size, 20px);
				@include rem(margin-bottom, 20px);
			}
			&.center .icon {
				@include rem(margin-bottom, 25px);
			}
			&.wide .icon {
				@include rem(max-height, 100px);
			}
			.btn {
				@include rem(padding, 8px 20px);
			}
		}
		@include mq($ipad, false, max) {
			.alt-sub-title {
				@include rem(font-size, 18px);
				@include rem(margin-bottom, 15px);
			}
			&.center .icon {
				@include rem(margin-bottom, 20px);
			}
			&.wide .icon {
				@include rem(max-height, 75px);
			}
			.btn {
				@include rem(padding, 6px 15px);
				@include rem(font-size, 14px);
			}
		}
		@include mq($portrait, false, max) {
			.alt-sub-title {
				@include rem(font-size, $base-font-size);
			}
			&.wide .icon {
				@include rem(max-height, 95px);
			}
			.btn {
				@include rem(font-size, 13px);
				@include rem(padding, 5px 12px);
			}
		}
		@include mq($iphone, false, max) {
			.alt-sub-title {
				@include rem(font-size, 14px);
				@include rem(margin-bottom, 10px);
			}
			&.center .icon {
				@include rem(margin-bottom, 15px);
			}
			&.wide .icon {
				@include rem(max-height, 85px);
			}
			.btn {
				@include rem(padding, 4px 10px);
				@include rem(font-size, 12px);
			}
		}
	}

	@include mq($design) {
		&.grid-1, &.grid-2, &.grid-3, &.grid-4, &.grid-5 {
			& > [class*="w-"] {
  			&, &.contains-grid .grid > [class*="w-"] {
				  @include rem(height, 398px);
				  padding-bottom: 0;
				}
			}

			& > [class*="x2"] {
				@include rem(height, 398px * 2);
			}
		 }
	}

	@include mq($portrait, false, max) {
		&.grid-5 {
			& > [class*="w-"] {
				width: 50%;
				padding-bottom: 34.687%;
				&:nth-child(odd):last-child {
					width: 100%;
					padding-bottom: 38%;
				}
			}

			&.grid-w-border {
				& > [class*="w-"] {
					border-width: 1px 1px;
					&:nth-child(-n+2) {
						border-top-width: 0px;
					}
					&:nth-child(odd) {
						border-left: none;
					}
					&:nth-child(even) {
						border-right: none;
					}
					&:last-child {
						border-bottom: none;
					}
					&:nth-child(odd):last-child {
						border-right: none;
						border-bottom: none;
						border-left: none;
					}
				}
			}
		}
	}
	@include mq($iphone, false, max) {
		&.grid-1, &.grid-2, &.grid-3, &.grid-4, &.grid-5 {
			& > [class*="w-"] {
				float: none;
				&, &:nth-child(odd):last-child {
					width: 100%;
					padding-bottom: 66.408%;
				}
			}
		}
		&.grid-5.grid-w-border > [class*="w-"] {
			border-left: none!important;
			border-right: none!important;
			&:nth-child(2) {
				border-top-width: 1px;
			}
		}
	}
}

$directions: (
  t: "top",
  r: "right",
  b: "bottom",
  l: "left"
);

$block-size: (
  grid:       26px,
  xsmall:       8px,
  small:        10px,
  medium:       20px,
  xmedium:      32px,
  large:        40px,
  xlarge:       60px,
  xxlarge:       83px,
  xxxlarge:       120px
);

@each $size, $unit in $block-size {
  .block-padding--#{$size} {
    padding-top: $unit * 0.75;
    padding-bottom: $unit * 0.75;

    @media #{$medium-only} {
      padding-top: $unit * 0.9;
      padding-bottom: $unit * 0.9;
    }

    @media #{$large-up} {
      padding-top: $unit * 1;
      padding-bottom: $unit * 1;
    }
  }

  .block-margin--#{$size} {
    margin-top: $unit * 0.75;
    margin-bottom: $unit * 0.75;

    @media #{$medium-only} {
      margin-top: $unit * 0.9;
      margin-bottom: $unit * 0.9;
    }

    @media #{$large-up} {
      margin-top: $unit * 1;
      margin-bottom: $unit * 1;
    }
  }

  @each $direction, $string in $directions {
    .block-margin-#{$direction}--#{$size} {
      margin-#{$string}: $unit * 0.75;

      @media #{$medium-only} {
        margin-#{$string}: $unit * 0.9;
      }

      @media #{$large-up} {
        margin-#{$string}: $unit * 1;
      }
    }

    .block-padding-#{$direction}--#{$size} {
      padding-#{$string}: $unit * 0.75;

      @media #{$medium-only} {
        padding-#{$string}: $unit * 0.9;
      }

      @media #{$large-up} {
        padding-#{$string}: $unit * 1;
      }
    }
  }
}

//	Select
//------------------------------------------------------------------------------
%has-select {
  width: 100%;

  label {
    display: inline-block;
    position: relative;
    cursor: pointer;

    & > span {
	    display: block;
    }
  }

  select {
    @extend %opacity-0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    z-index: 1;
    width: 100%;
    -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
  }
}

%inline-select {
  position: relative;
  display: inline-block;
  width: auto;
  padding: 0 rem(15px);

  label > span:after {
    position: absolute;
    @include center(false, true);
		right: 0;
		display: block;
		height: 0;
		width: 0;

		content: '';
  }
}

.select {
	@extend %has-select;

	label > span {
		@include rem(font-size, $base-font-size);
		@include whitney;
	}
}

.select-language {
  @include mq($iphone) {
    display: none;
  }
  @extend %inline-select;
  order: 3;
	line-height: 3;

	label > span {
  	padding-right: rem(12px);
    font-size: rem(12px);
    font-weight: bold;
    text-transform: uppercase;

    &:after {
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid $black;
    }
  }
}

.select-inline {
  @extend %inline-select;
  font-size: 0;

  select {
     font-size: 16px;
  }

  .sites-label {
    padding-right: 10px;
    font-size: 12px;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    opacity: .7;
    line-height: 33px;
    display: inline-block;

    @media screen and (max-width: 700px) {
      display: none;
    }
  }

	line-height: 2.39;
  display: inline-block;

	.highlight & select {
		color: $black;
	}

	.nav-contact & {
		&:before {
			position: absolute;
			top: 0;
			right: 100%;
			bottom: 0;
			display: block;
			width: rem(50px);
			content: '';

			.fba & {
  			content: none;
			}

			.homepage & {
				background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjElIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iNzclIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
				background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,.9) 77%,rgba(255,255,255,.9) 100%); /* W3C */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-8 */
			}

			background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjIlIiBzdG9wLWNvbG9yPSIjZmFmYWZhIiBzdG9wLW9wYWNpdHk9IjAiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAxMDEwMSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
			background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(250,250,250,0) 2%,rgba(1,1,1,1) 100%); /* W3C */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#010101',GradientType=1 ); /* IE6-8 */

			.ie9 & {
				filter: none;
			}
		}
	}

  label > span {
    font-size: rem(14px);
    text-transform: uppercase;
    padding-right: rem(20px);
    padding-left: rem(30px);
    line-height: 34px;

    &:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid $red;
    }

    &:before {
      position: absolute;
      left: 0;
      @include center(false, true);
      display: block;
      width: 18px; //img width + border
      height: 18px; //img width + border
      background-color: $grey-f;
      @include bg-img('/media/images/assets/flags', 16px 64px, left top);
      border: 1px solid $black-15;
      border-radius: 50%;
      content: '';

      .highlight & {
        border-color: $black-80;
      }
    }

    &:after {
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;

      border-top: 4px solid $black;

      .highlight & {
        border-top-color: $black-15;
      }
    }
  }
  &.country--ck label > span:before {
    background-position: left top;
  }
  &.country--au label > span:before {
    background-position: left -16px;
  }
  &.country--usa label > span:before {
    background-position: left bottom;
  }
  &.country--fba label > span:before {
    background-position: left -32px;
  }

	@include mq($macbook, false, max) {
		line-height: 2.8;

		label > span {
			font-size: rem(12px);
		}
	}

	@media screen and (max-width: 890px) {
    label > span {
      .fba & {
        overflow: hidden;
        width: rem(200px);
        -o-text-overflow: ellipsis;
        text-overflow:    ellipsis;
        white-space: nowrap;
      }
    }
  }

  @media screen and (max-width: 828px) {
    label > span {
      .fba & {
        width: rem(125px);
        padding-left: 0;
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 760px) {
    label > span {
      .fba & {
        width: rem(30px);
        text-indent: -9999px;

        &:before {
          position: absolute;
          left: 0;
          @include center(false, true);
          display: block;
          width: 18px; //img width + border
          height: 18px; //img width + border
          background-color: $grey-f;
          @include bg-img('/media/images/assets/flags', 16px 64px, left top);
          border: 1px solid $black-15;
          border-radius: 50%;
          content: '';
        }

        &:after {
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-top: 4px solid $black;
        }
      }
    }
    &.country--fba label > span:before {
      background-position: left -32px;
    }
  }

	@media screen and (max-width: 700px) {
    label > span {
      width: rem(30px);
      padding-right: 0;
      text-indent: -9999px;
      &:before {
        position: absolute;
        left: 0;
        @include center(false, true);
        display: block;
        width: 18px; //img width + border
        height: 18px; //img width + border
        background-color: $grey-f;
        @include bg-img('/media/images/assets/flags', 16px 64px, left top);
        border: 1px solid $black-15;
        border-radius: 50%;
        content: '';

        .highlight & {
          border-color: $black-80;
        }
      }

      &:after {
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;

        border-top: 4px solid $black;

        .highlight & {
          border-top-color: $black-15;
        }
      }
    }
    &.country--ck label > span:before {
      background-position: left top;
    }
    &.country--au label > span:before {
      background-position: left -16px;
    }
    &.country--fba label > span:before {
      background-position: left -32px;
    }
  }

  @include mq($iphone, false, max) {
    order: 1;
    background-color: $grey-f!important;
    border-right: 1px solid $black-15;
    line-height: 3;
    .highlight & {
      background-color: $black!important;
      border-right-color: $black-80;
    }
  }
}

.select-line {
	.icon {
		position: absolute;
		@include rem(right, 5px);
		@include center(false, true);
	}
	label {
		border-bottom: 1px solid $black;
	}
	label > span {
		letter-spacing: .03em;
		@include hellip(100%);
		@include rem(padding-right, 25px);
	}

	//mq
	@include mq($iphone, false, max) {
		label > span {
			@include rem(font-size, 14px);
		}
	}
}

.select-box {
	@include rem(max-width, 420px);
	margin-left: auto;
	margin-right: auto;
	@include rem(margin-bottom, 40px);
	select, label > span {
		@include rem(padding, 10px 30px);
	}
	label > span {
		color: white;
		background-color: $black;
	}
	.icon {
		background-color: $red;
		color: white;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		@include rem(width, 30px);
		border-left: 1px solid white;
		svg {
			@include rem(width, 15px);
			left: 50%;
			transform: translate(-50%, 0);
		}
		.contrast & {
			border-left-color: $grey-f;
		}
	}

	//mq
	@include mq($imac, false, max) {
		@include rem(margin-bottom, 35px);
	}
	@include mq($macbook, false, max) {
		@include rem(margin-bottom, 30px);
	}
	@include mq($ipad, false, max) {
		@include rem(margin-bottom, 25px);
	}
	@include mq($portrait, false, max) {
		@include rem(margin-bottom, 20px);
		label > span {
			@include rem(font-size, 15px);
			@include rem(padding-left, 20px);
		}
	}
	@include mq($iphone, false, max) {
		max-width: 100%;
		@include rem(margin-bottom, 15px);
		label > span {
			@include rem(font-size, 14px);
			@include rem(padding-left, 15px);
		}
	}
}

.select-with-input {
  display: flex;
  flex-flow: row nowrap;

  input {
    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .select-field {
    width: rem(130px);
    border-left: 1px solid $grey-f;
    label > span, select {
      padding-left: rem(10px);
      padding-right: rem(10px);
    }
    .icon {
      border-left: none;
      background-color: transparent!important;
    }

    @include mq($iphone, false, max) {
      width: rem(100px);
    }
  }
}

.select-field {
	background-color: white;
	select, label > span {
		@include rem(padding, 10px 40px 10px 30px);
	}
	label{
		margin-bottom: 0!important;
	}
	.icon {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		color: white;
		background-color: $black;
		@include rem(width, 30px);
		border-left: 1px solid white;
		svg {
			@include rem(width, 15px);
			left: 50%;
			transform: translate(-50%, 0);
		}
		.contrast & {
			border-left-color: $grey-f;
		}

		.fba & {
  		color: $black;
  		background-color: white;
		}
	}

	//mq
	@include mq($portrait, false, max) {
		label > span {
			@include rem(font-size, 15px);
			@include rem(padding-left, 20px);
		}
	}
	@include mq($iphone, false, max) {
		label > span {
			@include rem(font-size, 14px);
			@include rem(padding-left, 15px);
		}
	}
}

.select-multiple {
	width: 100%!important;
}

.chosen-container {
	width: 100%!important;
}

.select-label {
  width: 100%;
  text-align: center;
}

.select--country--footer {
  @extend %has-select;

  position: relative;
  padding-right: rem(8px + 5px);
  padding-left: rem(18px + 10px);

  label, label > span {
    display: inline-block;
  }

  &:before {
    position: absolute;
    left: 0;
    @include center(false, true);
    display: block;
    width: 18px; //img width + border
    height: 18px; //img width + border
    background-color: $grey-f;
    @include bg-img('/media/images/assets/flags', 16px 64px, left top);
    border: 1px solid $black-15;
    border-radius: 50%;
    content: ''
  }

  &.country--ck:before {
    background-position: left top;
  }
  &.country--au:before {
    background-position: left -16px;
  }
   &.country--fba:before {
    background-position: left -32px;
  }

  &:after { //array
    position: absolute;
    right: 0;
    display: block;
    height: 0;
    width: 0;

    border-left: 3px solid transparent;
    border-right: 3px solid transparent;

    border-top: 4px solid $black;
    content: '';

    @include center(false, true);
  }
}

.select-label {
  display: block;
  margin-bottom: rem(10px);
  @include utopia;
  font-size: rem(16px);
}

//	Filter results
//------------------------------------------------------------------------------
.filter-results  {
	@include rem(padding-bottom, 7px);
	.label {
		display: inline-block;
		color: $black-80;
		@include rem(margin, 7px 10px 0px 0px);
		@include rem(font-size, $base-font-size);
	}
	.select {
		@include rem(margin-top, 7px);
	}

	//flex layout
	.columns {
		display: flex;
		flex-flow: row nowrap;
	}
	.label {
		flex: 1 0 auto;
		white-space: nowrap;
	}
	.select {
		flex: 1 auto;
	}

	//mq
	@include mq($iphone, false, max) {
		@include rem(padding-bottom, 15px);
		.label {
			@include rem(margin-top, 15px);
			margin-bottom: 0;
			@include rem(font-size, 14px);
		}
		.select {
			margin-top: 0;
		}

		//flex layout
		.columns {
			display: block;
		}
	}
}

.filter-results-show {
	@include rem(margin, 20px 0px 30px);
	@include rem(font-size, $base-font-size);
	@include whitney;
	.current {
		color: $black;
		@include utopia;
	}
	//mq
	@include mq($iphone, false, max) {
		@include rem(font-size, 14px);
		@include rem(margin, 10px 0px 20px);
	}
}

// Pagination
//------------------------------------------------------------------------------
.pagination {
	@include rem(padding, 15px);
	@include rem(margin-bottom, 20px);
	text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

	& > li {
    @include whitney;
    @include rem(font-size, 22px);
    @include rem(margin-left, 10px);
    @include rem(margin-right, 10px);
		display: inline-block;

		a {
      display: block;
      padding: 0 5px;
			transition: color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
      color: black;

			&:hover {
				color: $red;
			}
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

		&.current {
			border-bottom: 2px solid $red;
			position: relative;
			@include rem(margin-left, 18px);
			@include rem(margin-right, 18px);
		}

		&.prev, &.next {
      font-size: 0;

      a {
        position: relative;
        display: block;
      }

			i {
        @include rem(height, 22px);
			}
		}

    &.disabled {
      pointer-events: none;
    }

    &.disabled a:hover {
      color: inherit;
    }

		&.prev {
			@include rem(margin-right, 20px);
		}

		&.next {
			@include rem(margin-left, 20px);
		}
	}

	//mq
	@include mq($imac, false, max) {
		& > li {
			@include rem(font-size, 21px);
		}
	}
  @include mq($macbook, false, max) {
    & > li {
			@include rem(font-size, 20px);
		}
  }
  @include mq($ipad, false, max) {
    & > li {
			@include rem(font-size, 18px);
		}
  }
  @include mq($portrait, false, max) {
    & > li {
			@include rem(font-size, $base-font-size);
		}
  }
  @include mq($iphone, false, max) {
	  @include rem(margin-top, 20px);
  }
}

// Misc.
//------------------------------------------------------------------------------
.downloads-preview {
	.title {
		@include utopia(normal, italic);
		@include rem(margin-bottom, 15px);
		margin-left: 0;
	}
	& > * {
		@include rem(margin-left, 30px);
	}

	//mq
  @include mq($imac, false, max) {
		& > * {
			@include rem(margin-left, 25px);
		}
  }
  @include mq($macbook, false, max) {
    & > * {
			@include rem(margin-left, 20px);
		}
  }
  @include mq($ipad, false, max) {
    & > * {
			@include rem(margin-left, 15px);
		}
  }
  @include mq($portrait, false, max) {
    & > * {
			@include rem(margin-left, 10px);
		}
  }
  @include mq($iphone, false, max) {
    & > * {
			margin-left: 0;
		}
  }
}

// Form
//------------------------------------------------------------------------------
.masthead__search {
  form {
    position: relative;

    input {
      @include rem(font-size, 14px);
      @include rem(padding, 5px 20px 5px 20px);
      @include whitney(bold);
      @include hellip(165px);
      padding-top: 5px;
      background-color: transparent;
      transition: width .4s cubic-bezier(0.340, 0.965, 0.525, 0.990);
      border: 0;
      outline: 0;
      letter-spacing: .04em;
      -webkit-appearance: none;
      border-radius: 0;
      color: inherit;

      @include placeholder {
        @include whitney(400);
        color: inherit;
      }

      &:focus {
        @include rem(width, 250px);
        background-color: $white;
        color: $black;
        transition: width .4s cubic-bezier(0.070, 0.635, 0.415, 0.990);

        & + button:hover {
          color: $red;
        }
      }

      //mq
      @include mq($imac, false, max) {
        @include rem(padding-left, 20px);
      }

      @include mq($macbook, false, max) {
        @include rem(padding-top, 5px);
        @include rem(padding-bottom, 5px);
        @include rem(padding-left, 20px);

        &:focus {
          @include rem(width, 360px);
        }
      }

      @include mq($ipad, false, max) {
        @include rem(padding-top, 5px);
        @include rem(padding-bottom, 5px);
        @include rem(font-size, 12px);

        &:focus {
          @include rem(width, 300px);
        }
      }

      @include mq($portrait, false, max) {
        &:focus {
          @include rem(width, 250px);
        }
      }

      @include mq($iphone, false, max) {
        @include rem(font-size, 12px);
        @include rem(padding, 5px 20px 5px 20px);
        width: 100%;
        transition-property: padding, color;

        &:focus {
          @include rem(padding-top, 5px);
          @include rem(padding-bottom, 5px);
          transition-property: padding, color;
          width: 100%;
        }
      }
    }

    button {
      @include center(false, true);
      @include rem(right, 20px);
      @include rem(height, 20px);
      position: absolute;
      outline: none;
      box-shadow: none;
      transition: color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
      color: inherit;

      &:hover {
        color: $red;
      }

      @include mq($ipad, false, max) {
        @include rem(right, 20px);
      }

      @include mq($portrait, false, max) {
        @include rem(right, 20px);
      }
    }

    input:focus + button {
      color: $black;
    }

    @include mq($iphone, false, max) {
      width: 100%;
    }
  }

  @include mq($iphone, false, max) {
    flex: 1;
    order: 2;
  }
}

.form {
	abbr {
		color: $red;
		border-bottom: none;
	}
	.label-note {
  	color: #b3b2b2;
  	@include whitney;
	}
}

.label-note {
  .title-4 &, .form-field & {
    clear: both;
    padding-top: rem(10px);
    text-transform: none;
    letter-spacing: 0;
  }
}

.form-callback {
  max-width: rem(575px);
  padding: rem(50px) rem(75px);
  margin-left: auto;
  margin-right: auto;
  border: 1px solid $grey-f;
  text-align: left;
  background-color: white;
  box-shadow: 0px 2px 0px rgba($grey-f, .5);

  .icon-ui-logo-icon {
    width: rem(52px);
    margin-right: rem(25px);
    color: $red;
    vertical-align: middle;
  }

  @include mq($imac, false, max) {
    padding: rem(40px) rem(50px);

    .icon-ui-logo-icon {
      width: rem(50px);
    }
  }
  @include mq($macbook, false, max) {
    padding: rem(30px) rem(35px);

    .icon-ui-logo-icon {
      width: rem(45px);
      margin-right: rem(20px);
    }
  }
  @include mq($ipad, false, max) {
    padding: rem(25px) rem(25px);

    .icon-ui-logo-icon {
      width: rem(40px);
      margin-right: rem(15px);
    }
  }
  @include mq($portrait, false, max) {
    padding: rem(20px) rem(20px);
  }
  @include mq($iphone, false, max) {
    .icon-ui-logo-icon {
      width: rem(35px);
      margin-right: rem(10px);
    }
  }
}


ul.errors {
  li.error {
    @include rem(font-size, $base-font-size);
    @include rem(margin-bottom, 10px);
    @include rem(margin-top, 10px);
    display: inline-block;
    color: $red;
  }
}

.chosen-container-multi .chosen-choices {
	//reset
	border: none;
	background-image: none;
	box-shadow: none;

	//override styling
	@include rem(font-size, $base-font-size);
	@include rem(padding, 7.5px 30px);
	li.search-field {
		@include rem(padding, 4px 0px);
		input[type=text] {
			@include whitney;
			height: auto;
		}
	}

	li.search-choice {
		//reset
		background-image: none;
		border:none;
		line-height: normal;

		box-shadow: 0px 2px 0px rgba(0,0,0,.05);
		color: white;
		background-color: $black;
		@include rem(font-size, 14px);
		@include whitney(bold);
		@include rem(padding, 2px 25px 2px 8px);

		.search-choice-close {
			@include rem(right, 5px);
			@include center(false, true);
		}
	}

	//mq
	@include mq($portrait, false, max) {
		@include rem(padding, 6.5px 20px);
	}
	@include mq($iphone, false, max) {
		@include rem(padding, 6px 15px);
	}
}

.chosen-container .chosen-drop {
	border: none;
	border-top: 2px solid $black-15;
	box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.05);
}
.chosen-container .chosen-results {
	@include whitney;
	li {
		@include rem(font-size, $base-font-size);
		@include rem(padding, 10px 15px);
		&.highlighted {
			background-image: none;
			background-color: $black;
			font-weight: bold;
		}
		&.no-results {
			background-color: $black;
			color: white;
			span {
				font-weight: bold;
			}
		}
	}
}

.form-field {
	@include whitney;
	@include rem(margin-bottom, 20px);
	@include clearfix;
	label {
    width: 100%;
		@include rem(font-size, $base-font-size);
		@include rem(margin-bottom, 10px);
		display: inline-block;
	}
  .error {
    @include rem(font-size, $base-font-size);
    @include rem(margin-bottom, 10px);
    @include rem(margin-top, 10px);
    display: inline-block;
    color: $red;
  }
	input {
		@include hellip(100%);
		&[type=date] {
  	  &::-webkit-calendar-picker-indicator { //arrow
        //outline: 1px solid red;
      }
  	  &::-webkit-inner-spin-button { //up and down
        -webkit-appearance: none;
          display: none;
      }
    }
	}
	textarea {
		@include rem(min-height, 200px);
		@include rem(max-height, 380px);
		resize: vertical;
		overflow: scroll;
	}
	input, textarea {
		width: 100%;
		display: block;
		border: none;
		@include rem(padding, 12.5px 30px);
		&:focus {
			@include rem(outline, 5px solid #eee);
		}

		.form-contrast & {
  		background-color: $grey-f;
		}
	}
	[type=submit] {
		float: right;
	}
  [type=checkbox] {
    float: left;
    width: auto;
    margin: 5px 10px 5px 0;
  }

	//mq
	@include mq($portrait, false, max) {
		@include rem(margin-bottom, 15px);
		label, input, textarea {
			@include rem(font-size, 15px);
		}
		label {
			@include rem(margin-bottom, 5px);
		}
		input, textarea {
			@include rem(padding-left, 20px);
			@include rem(padding-right, 20px);
		}
	}
	@include mq($iphone, false, max) {
		label, input, textarea {
			@include rem(font-size, 14px);
		}
		textarea {
			@include rem(min-height, 100px);
			@include rem(max-height, 250px);
		}
		input, textarea {
			@include rem(padding-left, 15px);
			@include rem(padding-right, 15px);
		}
		[type=submit] {
			float: none;
		}
  }
}
.form-links {
  display: inline-block;
  margin-right: rem(15px);
  &:after {
    margin-left: rem(15px);
    content: "•";
    color: $grey-f;
  }
  a {
    text-decoration: underline;
  }
  &:last-child {
    margin-right: 0;
    &:after {
      content: none;
    }
  }
  @include mq($iphone, false, max) {
    display: block;
    margin-right: 0;
    &:after {
      content: none;
    }
  }
}
.form-radio {
  display: inline-block;
  margin-right: rem(20px);
  cursor: pointer;

  &:first-of-type {
    margin-left: rem(40px);

    &:first-child {
      margin-left: 0;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  a {
    text-decoration: underline;
  }

  input[type="radio"], input[type="checkbox"] {
    //reset iput
    appearance: none;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;

    //custom
    position: relative;
    top: rem(4px);
    width: rem(20px);
    height: rem(20px);
    margin-right: rem(6px);
    border: 1px solid white;
    background-color: white;
    transition: border-color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);

    .form-contrast & {
      background-color: $grey-f;
    }

    &:hover {
      border-color:#ddd;
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
      margin-top: -1px;
      opacity: 0;
      border: 2px solid $green;
      border-top: none;
      border-right: none;
      transform: translate(-50%, -50%) rotate(-45deg);
      transition: opacity .2s cubic-bezier(0.070, 0.635, 0.415, 0.990) .4s, width .2s cubic-bezier(0.070, 0.635, 0.415, 0.990), height .2s cubic-bezier(0.070, 0.635, 0.415, 0.990) .2s;
      content: "";
    }
    &:checked {
      border-color: white!important;
      &:after {
        width: 10px;
        height: 6px;
        opacity: 1;
        transition: opacity .2s cubic-bezier(0.070, 0.635, 0.415, 0.990), width .2s cubic-bezier(0.070, 0.635, 0.415, 0.990) .2s, height .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
      }
    }
  }

  @include mq($ipad, false, max) {
    input[type="radio"], input[type="checkbox"] {
      width: rem(18px);
      height: rem(18px);
    }
  }

  @include mq($portrait, false, max) {
    display: block;
    padding: rem(10px);
    margin-top: rem(15px);
    margin-right: 0;
    margin-bottom: rem(5px);
    font-size: rem(14px);
    background-color: $grey-f;

    .contrast & {
      background-color: #eaeaea;
    }

    &:first-of-type {
      margin-left: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    input[type="radio"], input[type="checkbox"] {
      .form-contrast & {
        background-color: white;
      }
    }
  }

  @include mq($iphone, false, max) {
    input[type="radio"], input[type="checkbox"] {
      width: rem(16px);
      height: rem(16px);
    }
  }
}

.clear-fix {
  @include clearfix
}

.form-note {
	@include rem(font-size, 13px);
	color: $black-80;
	font-style: italic;
	@include rem(margin-bottom, 20px);
}

// Maps
//------------------------------------------------------------------------------
.map-container {
	position: relative;
	overflow: hidden;
}

.map {
	background-color: $grey-f;

	.gm-style {
		background-color: $black;
	}

	.map-full & {
		width: 100vw;
		height: 100vh;
	}

	.map-resize & {
		@include rem(height, 530px);

		//mq
		@include mq($imac, false, max) {
			@include rem(height, 480px);
		}
		@include mq($macbook, false, max) {
			@include rem(height, 420px);
		}
		@include mq($ipad, false, max) {
			@include rem(height, 350px);
		}
		@include mq($portrait, false, max) {
			@include rem(height, 280px);
		}
		@include mq($iphone, false, max) {
			@include rem(height, 250px);
		}
	}
}

.map-key {
	position: absolute;
	top: 10px;
	z-index: 1;
	padding: rem(2px) rem(15px) rem(6px);
	@include center(true, false);
	background-color: white;
	border-radius: rem(3px);

	.sub-title {
		margin-right: rem(15px);
		display: inline-block;
	}
	.list-key {
		display: inline;
	}

	.sub-title, .list-key {
		font-size: rem(12px);
	}

	@include mq($portrait, false, max) {
		position: static;
		padding: rem(8px) rem(20px);
		transform: none;
	}
}

.map-popup {
	position: absolute;
	z-index: 1;
	width: 100%;
	background-color: white;
	@include rem(max-width, 288px);
  overflow: auto;

	@include rem(right, 50px);
	@include rem(box-shadow, -7px 0px 0px rgba($black, .05));
	transition: right .2s cubic-bezier(0.070, 0.635, 0.415, 0.990) .6s;

	.alt-sub-heading {
		@include rem(font-size, 20px);
		@include mq($imac, false, max) {
			@include rem(font-size, 18px);
		}
		@include mq($macbook, false, max) {
			@include rem(font-size, 18px);
		}
		@include mq($ipad, false, max) {
			@include rem(font-size, $base-font-size);
		}
	}


	[rel*="js-maps-office"] + & {
		& > header {
			line-height: 1;
		}
	}

	.map-resize & {
		@include rem(top, 90px);
		@include rem(bottom, 90px);
	}

	.map-full & {
		@include rem(height, auto);
		@include center(fasle, true);
		display: none;
	}

	&.offset {
		@include rem(right, -238px);
		transition-delay: 0s!important;
	}

	& > header {
		position: relative;
		@include rem(padding, 20px 30px);
		.icon-ui-logo-ck {
			@include rem(height, 36px);
			@include rem(width, 128px);
		}
		&:before {
			content: '';
			display: block;
			position: absolute;

			height: 0;
			width: 0;

			@include rem(border-width, 10px 10px 10px 0px);
			border-color: transparent white transparent transparent;
			border-style: solid;
			left: -10px;
			@include center(false, true);
		}
		&.contrast:before {
			border-right-color: $grey-f;
		}
		&.highlight:before {
			border-right-color: $black;
		}
		@each $color, $hexa in $colors {
			&.#{$color} {
				background-color: $hexa;
				.alt-sub-heading, .sub-title {
					color: white;
				}
				&:before {
					border-right-color: $hexa;
				}
			}
		}
	}

	& > section {
		@include rem(padding, 15px 25px);
		.alt-sub-heading {
			@include rem(margin-bottom, 25px);
		}
	}

	p {
		@include rem(margin-bottom, 10px);
		@include rem(font-size, 15px);
		color: $black-80;
		@include whitney;
		&:last-of-type {
			@include rem(margin-bottom, 25px);
      height: 400px;
      overflow: auto;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&.intro {
			line-height: 1.2;
			@include rem(margin-bottom, 20px);
		}

    &.mp-description {
      max-height: 450px;
      overflow: auto;
      font-size: 13px
    }
	}

	.btn {
		text-align: center;
	}

	@include mq($imac, false, max) {
		.map-resize & {
			@include rem(top, 60px);
			@include rem(bottom, 60px);
		}
		& > header, & > section {
			@include rem(padding, 15px 25px);
		}
		& > section {
			@include rem(top, 72px);
			.alt-sub-heading {
				@include rem(margin-bottom, 20px);
			}
		}
	}

	@include mq($macbook, false, max) {
		.map-resize & {
			@include rem(top, 40px);
			@include rem(bottom, 40px);
		}
		& > header, & > section {
			@include rem(padding, 10px 20px);
		}
		& > header {
			.icon-ui-logo {
				@include rem(height, 36px * 0.75);
				@include rem(width, 128px * 0.75);
			}
		}
		& > section {
			@include rem(top, 49px);
			.alt-sub-heading {
				@include rem(margin-bottom, 15px);
			}
		}
		p {
			@include rem(font-size, 14px);
			&:last-of-type {
				@include rem(margin-bottom, 20px);
			}

      &.mp-description {
        height: auto;
      }
		}
	}

	@include mq($ipad, false, max) {
		@include rem(right, 30px);
		.map-resize & {
			@include rem(top, 20px);
			@include rem(bottom, 20px);
		}
		& > section {
			@include rem(padding-bottom, 25px);
			.alt-sub-heading {
				@include rem(margin-bottom, 10px);
			}
		}
	}

	@include mq($portrait, false, max) {
		@include rem(max-width, 230px);
		p {
			&:last-of-type {
				@include rem(margin-bottom, 15px);
			}
		}
	}

	@include mq($iphone, false, max) {
		position: static;
		max-width: 100%;
		border-bottom: 1px solid $black-15;
		& > section {
			position: static;
		}
		.map-full & {
			height: auto;
			transform: none;
		}
		& > header {
			&:before {
				@include rem(border-width, 0px 10px 10px 10px);
				border-color: transparent transparent white transparent;
				@include center(true, false);
				@include rem(top, -10px);
			}
			&.contrast:before {
				border-color: transparent transparent $grey-f transparent;
			}
			&.highlight:before {
				border-color: transparent transparent $black transparent;
			}
			@each $color, $hexa in $colors {
				&.#{$color}:before {
					border-color: transparent transparent $hexa transparent;
				}
			}
		}
	}
}

// Modal
//------------------------------------------------------------------------------
[rel*="js-download-modal"],
[rel*="js-case-modal"] {
	display: none;
}

.modal {
	position: absolute;
	z-index: 1;

	text-align: center;
	height: 100%;
	width: 100%;
	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -0.25em; /* Adjusts for spacing */
	}
	.modal-box {
		@include rem(max-width, 550px);
		display: inline-block;
		text-align: left;
		vertical-align: middle;
		box-shadow: 0px 5px 0px 0px rgba($black, .05);
		opacity: 0;
		animation-name: fadeInUp;
		animation-duration: .4s;
		animation-fill-mode: forwards;
		animation-delay: 1s;
	}
	header {
		text-align: center;
		@include rem(padding, 20px 20px);
		position: relative;
		.icon {
			position: absolute;
			@include rem(left, 20px);
			@include center(false, true);
			@include rem(height, 55px);
		}
	}
	section {
		background-color: white;
		@include rem(padding, 40px 100px);
		p {
			@include rem(margin-bottom, 25px);
			&:last-of-type {
				@include rem(margin-bottom, 40px);
			}
		}
	}

	.btn {
		display: block;
		margin: auto;
	}

	//mq
	@include mq($imac, false, max) {
		header {
			.icon {
				@include rem(height, 50px);
			}
		}
		section {
			@include rem(padding, 35px 80px);
			p {
				@include rem(margin-bottom, 20px);
				&:last-of-type {
					@include rem(margin-bottom, 35px);
				}
			}
		}
	}
	@include mq($macbook, false, max) {
		header {
			@include rem(padding-top, 15px);
			@include rem(padding-bottom, 15px);
			.icon {
				@include rem(height, 40px);
			}
		}
		section {
			@include rem(padding, 30px 50px);
			p {
				@include rem(margin-bottom, 15px);
				&:last-of-type {
					@include rem(margin-bottom, 30px);
				}
			}
		}
	}
	@include mq($ipad, false, max) {
		header {
			@include rem(padding-top, 12px);
			@include rem(padding-bottom, 12px);
			.icon {
				@include rem(height, 35px);
			}
		}
		section {
			@include rem(padding, 25px 40px);
			p {
				@include rem(font-size, 15px);
				@include rem(margin-bottom, 10px);
				&:last-of-type {
					@include rem(margin-bottom, 25px);
				}
			}
		}
	}
	@include mq($portrait, false, max) {
		header {
			@include rem(padding-top, 10px);
			@include rem(padding-bottom, 10px);
			.icon {
				@include rem(height, 30px);
			}
		}
		section {
			@include rem(padding, 20px 30px);
			p {
				@include rem(font-size, 14px);
				&:last-of-type {
					@include rem(margin-bottom, 20px);
				}
			}
		}
	}
	@include mq($iphone, false, max) {
		header {
			@include rem(padding-top, 8px);
			@include rem(padding-bottom, 8px);
		}
		section {
			@include rem(padding, 15px 20px);
			p {
				&:last-of-type {
					@include rem(margin-bottom, 15px);
				}
			}
		}
	}
}
// News
//------------------------------------------------------------------------------
.news-single {
	.news-header {
		@include rem(margin-bottom, 25px);
		[class*="hairline"] {
			@include rem(padding, 15px 0px);
		}
		.list-links-inline {
			@include rem(margin-bottom, 5px);
		}
		//mq
		@include mq($imac, false, max) {
			@include rem(margin-bottom, 22px);
		}
		@include mq($macbook, false, max) {
			@include rem(margin-bottom, 20px);
		}
		@include mq($ipad, false, max) {
			@include rem(margin-bottom, 18px);
			[class*="hairline"] {
				@include rem(padding, 12px 0px);
			}
		}
		@include mq($portrait, false, max) {
			@include rem(margin-bottom, 15px);
			[class*="hairline"] {
				@include rem(padding, 10px 0px);
			}
		}
		@include mq($iphone, false, max) {
			@include rem(margin-bottom, 10px);
			[class*="hairline"] {
				@include rem(padding, 5px 0px);
			}
		}
	}
	.news-block {
		.news-content {
			p {
				@include whitney;
				color: $black-80;
				&.intro {
					@include utopia(normal, italic);
					color: black;
					@include rem(margin-bottom, 30px);
				}
			}
			.alt-sub-heading {
				@include rem(margin-bottom, 20px);
			}
		}
		.p-left {
			p:last-of-type, .list-share, .list-links-inline {
				@include rem(margin-bottom, 60px);
			}
		}

		//mq
		@include mq($imac, false, max) {
			.p-left {
				p:last-of-type, .list-share, .list-links-inline {
					@include rem(margin-bottom, 50px);
				}
			}
		}
		@include mq($macbook, false, max) {
			.p-left {
				p:last-of-type, .list-share, .list-links-inline {
					@include rem(margin-bottom, 40px);
				}
			}
		}
		@include mq($ipad, false, max) {
			.p-left {
				p:last-of-type, .list-share, .list-links-inline {
					@include rem(margin-bottom, 30px);
				}
			}
		}
		@include mq($portrait, false, max) {
			.news-content {
				@include rem(font-size, 15px);
				@include rem(margin-bottom, 25px);
				p.intro, .alt-sub-heading {
					@include rem(margin-bottom, 15px);
				}
				.alt-sub-heading {
					@include rem(margin-bottom, 20px);
				}
			}
			.p-left {
				p:last-of-type, .list-share, .list-links-inline {
					@include rem(margin-bottom, 25px);
				}
			}
		}
		@include mq($iphone, false, max) {
			.news-content {
				@include rem(font-size, 14px);
				p.intro, .alt-sub-heading {
					@include rem(margin-bottom, 10px);
				}
			}
			.p-left {
				p:last-of-type, .list-share, .list-links-inline {
					@include rem(margin-bottom, 20px);
				}
			}
		}
	}
}

.news-more {
	.btn-block {
		@include rem(margin-bottom, 5px);
	}
	.alt-sub-heading {
		@include rem(margin-bottom, 20px);
		&[class*="hairline-top"] {
			@include rem(padding-top, 20px);
		}
	}
	.news-single & {
		@include rem(padding-top, 20px);
		&:before {
			content: "";
			display: block;
			height: 1px;
			background-color: $black-15;
			position: absolute;
			top: 0;
			left: 1.25rem;
			right: 2.5rem;
		}
		@include mq($ipad, false, max) {
			&:before {
				left: 1rem;
				right: 2rem;
			}
		}
		@include mq($portrait, false, max) {
			&:before {
				position: static;
				@include rem(margin-bottom, 15px);
			}
		}
		@include mq($iphone, false, max) {
			&:before {
				@include rem(margin-bottom, 10px);
			}
		}
	}

	//mq
	@include mq($ipad, false, max) {
		@include rem(font-size, 15px);
		.alt-sub-heading {
			@include rem(margin-bottom, 15px);
			&[class*="hairline-top"] {
				@include rem(padding-top, 15px);
			}
		}
	}
	@include mq($portrait, false, max) {
		.alt-sub-heading {
			@include rem(margin-bottom, 10px);
			&[class*="hairline-top"] {
				@include rem(padding-top, 10px);
			}
		}
	}
}


/*===============================
=            Services           =
===============================*/

.service .btn {
  display: inline-block;
  font-size: 0.6em;
  margin-top: -0.5em;
  @include clearfix;

  @include mq($iphone) {
    font-size: 0.8em;
  }
  @include mq($ipad) {
    font-size: 1em;
    margin-top: 0;
  }
  @include mq($macbook) {
    margin-top: 0.75em;
  }
}

.service-heading {
  padding-right: 0;

  &.padded {
    min-height: 60px;
  }

  @media only screen and (max-width: 1024px) {
    padding-left: 0;
  }
}

.service-heading-image {
  width: 100%;
}

.service-intro {
  @include rem(margin-bottom, 30px);
}

.service-summary {
  font-size: 0.8em;
  text-align: left;
  @include rem(padding, 30px 35px);

  @include mq($iphone) {
    text-align: center;
  }

  @include mq($portrait) {
    text-align: left;
  }

  @include mq($macbook) {
    font-size: 1em;
  }

  //mq
  @include mq($imac, false, max) {
		@include rem(padding-left, 20px);
		@include rem(padding-right, 20px);
	}
	@include mq($macbook, false, max) {
		@include rem(padding-left, 15px);
		@include rem(padding-right, 15px);
	}
	@include mq($ipad, false, max) {
		@include rem(padding-left, 10px);
		@include rem(padding-right, 10px);
	}
	@include mq($portrait, false, max) {
		padding-left: 0;
		padding-right: 0;
	}
}

.service-summary-item {
  @include mq($iphone, false, max) {
    margin-bottom: 1.5em;
  }
}

.service-summary .title {
  color: $red;
  font-size: 1em;
  letter-spacing: 0.1em;
}

.service-summary p {
  font-size: 0.8em;
  margin: 0;
  color: $black-80;
}

.service-summary-body {
  display: inline-block;
  vertical-align: middle;

  @include mq($iphone) {
    display: block;
  }

  @include mq($portrait) {
    display: inline-block;
  }
}

.service-summary .icon-container {
  display: inline-block;
  vertical-align: middle;
  @include rem(margin-right, 25px);

  //mq
  @include mq($imac, false, max) {
	  @include rem(margin-right, 20px);
	  .icon {
      @include rem(width, 50px);
			@include rem(height, 50px);
		}
	}
	@include mq($macbook, false, max) {
	  @include rem(margin-right, 15px);
	  .icon {
			@include rem(width, 45px);
      @include rem(height, 45px);
		}
	}
	@include mq($ipad, false, max) {
	  @include rem(margin-right, 10px);
	  .icon {
			@include rem(width, 40px);
      @include rem(height, 40px);
		}
	}
}

.service-list li {
  margin-bottom: 1em;
}


// Browse all menu
//------------------------------------------------------------------------------
.browse {
	position: relative;
	z-index: 2;
	display: none;
	@include bg-img('/media/images/assets/ck-lion', 912px 641px, right bottom, 'png');
	box-shadow: 0px 5px 0px 0px rgba($black, .05);
	.highlight & {
		box-shadow: 0px 5px 0px 0px rgba($black, .15);
	}
	.sub-heading {
		text-align: center;
		@include rem(font-size, 48px);
	}

	.icon {
		color: $red;
	}

	//mq
	@include mq($imac, false, max) {
		background-size: 912px*.9 641px*.9;
		.sub-heading {
			@include rem(font-size, 40px);
		}
	}
	@include mq($macbook, false, max) {
		background-size: 912px*.75 641px*.75;
		.sub-heading {
			@include rem(font-size, 35px);
		}
	}
	@include mq($ipad, false, max) {
		background-size: 912px*.5 641px*.5;
		box-shadow: 0px 4px 0px 0px rgba($black, .15);
		.sub-heading {
			@include rem(font-size, 30px);
		}
	}
	@include mq($portrait, false, max) {
		background-size: 912px*.375 641px*.375;
		.sub-heading {
			@include rem(font-size, 25px);
		}
	}
	@include mq($iphone, false, max) {
		box-shadow: 0px 2px 0px 0px rgba($black, .15);
		background-size: 912px*.25 641px*.25;
		.sub-heading {
			@include rem(font-size, 18px);
		}
	}
}
.list-level {
	.contains-last-level {
		display: inline-block;
	}
	.alt-sub-heading {
		display: block;
		@include rem(margin-bottom, 25px);
	}
	& > li {
		@include rem(margin-bottom, 50px);
		&:last-child {
			//margin-bottom: 0;
		}
	}
	a {
		display: inline-block;
		transition: color .2s ease-in-out;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			height: 1px;
			background-color: $red;
			bottom: 0;
			left: 0;
			right: 0;
			@extend %opacity-0;
			transition: opacity .2s ease-in-out;
		}
		&:hover {
			color: $red!important;
			&:after {
				@extend %opacity-1;
			}
		}
		& + .level-3 {
			display: inline;
		}
	}
	.level-1, .level-2, .level-3 {
		line-height: 2;
	}
	.level-1, .level-2 {
		@include rem(font-size, 15px);
		text-transform: uppercase;
		@include whitney(bold);
		& > li > a {
			@include rem(margin-right, 30px);
			&:only-child {
				margin-right: 0;
			}
		}
	}
	.level-container {
		display: table;
		@include rem(margin-top, 15px);
		@include rem(margin-bottom, 25px);
		.level-2, .icon {
			display: table-cell;
		}
		.icon {
			vertical-align: middle;
			@include rem(width, 90px);
			border-width: 0px 20px;
			border-style: solid;
			border-color: transparent;
		}
		.level-2 {
			@include rem(padding-left, 15px);
		}
	}
	.level-1 {
		& > li {
			@include rem(padding, 5px 10px);
			@include rem(margin-left, -10px);
			@include rem(margin-right, -10px);
			transition: background-color .2s ease-in-out;
			width: intrinsic;
			&:hover {
				background-color: rgba(#f9f9f9, .5);
			}
			&:last-child > a {
				margin-bottom: 0;
			}
			& > a {
				display: inline-block;
				color: $black;
			}
		}
	}
	.level-2 {
		& > li {
			@include rem(padding, 5px 10px);
			transition: background-color .2s ease-in-out;
			width: intrinsic;
			&:last-child {
				margin-bottom: 0;
			}
			&:hover {
				background-color: $grey-f;
			}
			& > a {
				color: $black-80;
			}
		}
	}
	.level-3 {
		& > li {
			display: inline-block;
			@include rem(margin-right, 10px);
			@include rem(font-size, $base-font-size);
			font-weight: normal;
			text-transform: none;
			&, a {
				color: $black-80;
			}
			&:after {
				content: "•";
				display: inline-block;
				@include rem(margin-left, 10px);
				color: $black-15;
			}
			&:last-child {
				margin-right: 0;
				&:after {
					content: none;
				}
			}
		}
	}

	//mq
	@include mq($imac, false, max) {
		& > li {
			@include rem(margin-bottom, 40px);
		}
		.alt-sub-heading {
			@include rem(margin-bottom, 22px);
		}
		.level-3 > li {
			@include rem(font-size, 15px);
		}
		.level-container {
			@include rem(margin-top, 12px);
			@include rem(margin-bottom, 20px);
			.icon {
				@include rem(width, 80px);
			}
			.level-2 {
				@include rem(padding-left, 12px);
			}
		}
		.level-1, .level-2 {
			& > li > a {
				@include rem(margin-right, 25px);
			}
		}
	}
	@include mq($macbook, false, max) {
		& > li {
			@include rem(margin-bottom, 35px);
		}
		.alt-sub-heading {
			@include rem(margin-bottom, 20px);
		}
		.level-1, .level-2 {
			@include rem(font-size, 14px);
			& > li > a {
				@include rem(margin-right, 20px);
			}
		}
		.level-3 > li {
			@include rem(font-size, 14px);
		}
		.level-container {
			@include rem(margin-top, 10px);
			@include rem(margin-bottom, 15px);
			.icon {
				@include rem(width, 70px);
				border-width: 0 15px;
			}
			.level-2 {
				@include rem(padding-left, 10px);
			}
		}
	}
	@include mq($ipad, false, max) {
		& > li {
			@include rem(margin-bottom, 30px);
		}
		.alt-sub-heading {
			@include rem(margin-bottom, 15px);
		}
		.level-1, .level-2 {
			@include rem(font-size, 12px);
			& > li > a {
				@include rem(margin-right, 15px);
			}
		}
		.level-container {
			@include rem(margin-bottom, 10px);
		}
	}
	@include mq($portrait, false, max) {
		& > li {
			@include rem(margin-bottom, 25px);
		}
		a {
			& + .level-3 {
				@include rem(margin, 5px 0);
				display: block;
			}
		}
		.alt-sub-heading {
			@include rem(margin-bottom, 10px);
		}
		.level-container {
			&, .level-2, .icon {
				display: block;
			}
			@include rem(margin-top, 20px);
			margin-bottom: 0;
			.icon {
				border: none;
				width: auto;
				@include rem(height, 50px);
				@include rem(margin-bottom, 25px);
				position: relative;
				z-index: 0;
				&:after {
					position: absolute;
					content: '';
					display: block;
					background-color: $grey-f;
					@include rem(height, 60px);
					@include rem(width, 60px);
					border-radius: 50%;
					@include center;
					z-index: -1;
				}
			}
			.level-2 {
				padding-left: 0;
				border: none;
				@include rem(margin-left, -10px);
				@include rem(margin-right, -10px);
			}
		}
		.level-1, .level-2 {
			& > li {
				width: auto;
				& > li {
					margin-right: 0;
				}
			}
		}
		.level-2 {
			& > li > a {
				&:before {
					content: '› ';
					font-weight: normal;
					color: $red;
					display: inline-block;
					@include rem(margin-right, 10px);
				}
			}
		}
	}
	@include mq($iphone, false, max) {
		& > li {
			@include rem(margin-bottom, 20px);
		}
		.alt-sub-heading {
			background-color: $grey-f;
			width: intrinsic;
			@include rem(padding, 5px 20px);
			@include rem(margin-left, -20px);
		}
		.level-1, .level-2 {
			@include rem(font-size, 14px);
		}
		.level-container {
			.icon {
				@include rem(height, 40px);
				@include rem(height, 50px);
				@include rem(width, 50px);
			}
		}
	}
}

// Line break
//------------------------------------------------------------------------------
.br-ck {
	@include rem(height, 2px);
	background-color: $grey-f;
	color: $grey-f;
	@include rem(margin, 27px 0 55px);
	position: relative;
	.icon-container {
		background-color: white;
		@include rem(padding, 0 60px);
		position: absolute;
		@include center;
	}
	.icon {
		@include rem(width, 172px);
		@include rem(height, 48px);
	}

	//mq
	@include mq($imac, false, max) {
		@include rem(margin-top, 25px);
		@include rem(margin-bottom, 50px);
		.icon-container {
			@include rem(padding, 0 55px);
		}
		.icon {
			@include rem(width, 172px * .9);
			@include rem(height, 48px * .9);
		}
	}
	@include mq($macbook, false, max) {
		@include rem(margin-top, 22px);
		@include rem(margin-bottom, 45px);
		.icon-container {
			@include rem(padding, 0 45px);
		}
		.icon {
			@include rem(width, 172px * .8);
			@include rem(height, 48px * .8);
		}
	}
	@include mq($ipad, false, max) {
		@include rem(margin-top, 20px);
		@include rem(margin-bottom, 40px);
		.icon-container {
			@include rem(padding, 0 30px);
		}
		.icon {
			@include rem(width, 172px * .7);
			@include rem(height, 48px * .7);
		}
	}
	@include mq($portrait, false, max) {
		@include rem(margin-top, 18px);
		@include rem(margin-bottom, 35px);
		.icon-container {
			@include rem(padding, 0 20px);
		}
		.icon {
			@include rem(width, 172px * .6);
			@include rem(height, 48px * .6);
		}
	}
	@include mq($iphone, false, max) {
		@include rem(margin-top, 15px);
		@include rem(margin-bottom, 30px);
		.icon-container {
			@include rem(padding, 0 10px);
		}
		.icon {
			@include rem(width, 172px * .5);
			@include rem(height, 48px * .5);
		}
	}
}

// Flash messages
//------------------------------------------------------------------------------
.flash {
	background-color: white;
	@include rem(padding, 50px);
	box-shadow: 0px 5px 0px 0px rgba($black, .05);
	position: relative;

	overflow: hidden;

	display: flex;
	flex-flow: row nowrap;

	& > .icon {
		color: $red;
		@include rem(min-width, 60px);
		@include rem(margin-right, 25px);
	}
	.title {
		@include whitney(bold);
		color: $red;
		@include rem(font-size, 14px);
		text-transform: uppercase;
		display: inline-block;
		@include rem(margin-right, 25px);
		letter-spacing: .04em;
	}

	.btn-close {
		position: absolute;
		@include rem(right, 20px);
		@include rem(top, 15px);
		@include rem(padding, 5px);
		outline: none;
		box-shadow: none;
		.icon {
			@include rem(height, 15px);
			@include rem(width, 15px);
			color: $black-30;
			transition: color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
		}
		&:hover, &:focus {
			.icon {
				color: $red;
			}
		}
	}

	//mq
	@include mq($imac, false, max) {
		@include rem(padding, 40px);
		.title {
			@include rem(margin-right, 20px);
		}
		& > .icon {
			@include rem(min-width, 55px);
			@include rem(margin-right, 22px);
		}
		.btn-close {
			@include rem(right, 15px);
		}
	}
	@include mq($macbook, false, max) {
		@include rem(padding, 30px);
		.title {
			@include rem(font-size, 13px);
			@include rem(margin-right, 15px);
		}
		& > .icon {
			@include rem(min-width, 50px);
			@include rem(margin-right, 20px);
		}
		.btn-close {
			@include rem(top, 10px);
			@include rem(right, 10px);
		}
	}
	@include mq($ipad, false, max) {
		box-shadow: 0px 4px 0px 0px rgba($black, .05);
		@include rem(padding, 25px);
		.title {
			@include rem(font-size, 12px);
			@include rem(margin-right, 10px);
		}
		& > .icon {
			@include rem(min-width, 40px);
			@include rem(margin-right, 15px);
		}
		.btn-close {
			@include rem(top, 8px);
		}
	}
	@include mq($portrait, false, max) {
		@include rem(padding, 20px 25px 20px 20px);
		.title {
			@include rem(font-size, 11px);
			@include rem(margin-right, 7px);
		}
		& > .icon {
			@include rem(min-width, 35px);
		}
		.btn-close {
			@include rem(top, 3px);
			.icon {
				@include rem(width, 12px);
				@include rem(height, 12px);
			}
		}
	}
	@include mq($iphone, false, max) {
		box-shadow: 0px 2px 0px 0px rgba($black, .05);
		@include rem(padding, 15px 25px 15px 15px);
		display: block;
		.title {
			@include rem(font-size, 10px);
			@include rem(margin-right, 5px);
		}
		& > .icon {
			display: none;
		}
		.btn-close {
			top: 0;
			@include rem(right, 8px);
			.icon {
				@include rem(width, 10px);
				@include rem(height, 10px);
			}
		}
	}
}

[rel*="cookie-target"] {
	display: none;
}

.content-block ul li {
  list-style: disc;
  margin-left: 25px;
}

.content-block ul.grid li {
  margin-left: 0;
}


// Lightbox
//------------------------------------------------------------------------------
%lightbox {
  display: none;
	user-select: none;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9999;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	@include rem(padding, 50px 0);
	text-align: center;
	@include v-center-parent;
}
[id="lightbox-container"] {
  @extend %lightbox;
  background-color: rgba(white, .95);
  [id="lightbox-content"] {
    max-width: none;
    max-height: none;
  }
}
[id="lightbox"] {
	@extend %lightbox;
	background-color: rgba(0, 0, 0, .88);

	video, iframe, object {
		@include rem(max-width, 768px);
		width: 100%;
		height: 100%;
	}
	iframe {
		outline: rem(4px) solid black;

		@include mq($ipad, false, max) {
			outline-width: rem(2px);
		}
		@include mq($ipad, false, max) {
			outline-width: rem(1px);
		}
	}
	&.loading {
		background-image: url('/media/images/assets/loading.gif');
		background-size: 16px;
		background-position: center;
		background-repeat: no-repeat;
	}
}
[id="lightbox-content"] {
	position: relative;
	display: inline-block;
	@include v-center;
	width: 100%;
	height: 100%;
	max-height: rem(400px);
	max-width: rem(720px);

	@include mq($ipad, false, max) {
		position: static;
	}
}
[id="lightbox-close"] {
	position: absolute;
	top: rem(-60px);
	right: rem(-60px);
	z-index: 1;
	display: block;
	width: rem(60px);
	height: rem(60px);
	color: $red;
	cursor: pointer;
	user-select: none;
	transition: color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
	&:hover, &:focus {
		color: darken($red, 10);
	}
	&:focus {
		box-shadow: none;
		outline: none;
	}
	.icon {
		@include rem(height, 15px);
		@include rem(width, 15px);
	}
	@include mq($ipad, false, max) {
		top: 0;
		right: 0;
	}
}

// Cookies
//------------------------------------------------------------------------------
.cookies {
	position: fixed;
	z-index: 9999;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px rem(-5px) 0px rgba($black-15, .3);
	.columns {
		display: flex;
		flex-flow: row nowrap;
		@include mq($iphone, false, max) {
			display: block;
		}
	}
	.cookies-content {
		flex: 0 auto;
		@include rem(padding-right, 15px);
		align-self: center;
		@include mq($iphone, false, max) {
			padding-top: 0;
			padding-right: 0;
		}
	}
	.v-flex {
		margin-left: auto;
	}
	.btn {
		margin: auto;
		@include mq($iphone, false, max) {
			width: 100%;
			text-align: center;
		}
	}

	@include mq($ipad, false, max) {
		box-shadow: 0px rem(-4px) 0px rgba($black-15, .3);
	}
	@include mq($iphone, false, max) {
		box-shadow: 0px rem(-2px) 0px rgba($black-15, .3);
	}
}

@media print {
	@import "./print";
}

// Diagram
//------------------------------------------------------------------------------

%diagram {
  position: relative;
  padding: 0px rem(25px);

  @include mq($iphone, false, max) {
		display: none;
	}
}
.diagram-container {
  @extend %diagram;
	height: rem(280px);

	@include mq($design, false, max) {
		height: 0;
		padding-bottom: 21%;
	}

	.icon {
		position: absolute;
		top: 0;
		left:0;
		right: 0;
		margin: auto;
		overflow: visible;
	}
	.icon-ui-city-sky {
		z-index: 1;
	}
	.icon-ui-city-bg {
		z-index: 2;
	}
	.icon-ui-city-fg {
		z-index: 3;
	}

	.ie & {
		margin-top: rem(80px);
		@include bg-img('/media/images/assets/diagram', contain, center, 'png');
		border-style: none solid;
		border-color: transparent;
		border-width: 0px rem(50px);
		.diagram-layer {
			display: none;
		}
		@include mq($imac, false, max) {
			margin-top: rem(70px);
			border-width: 0px rem(40px);
		}
		@include mq($macbook, false, max) {
			margin-top: rem(60px);
			border-width: 0px rem(35px);
		}
		@include mq($ipad, false, max) {
			margin-top: rem(50px);
			border-width: 0px rem(30px);
		}
		@include mq($portrait, false, max) {
			margin-top: rem(40px);
			border-width: 0px rem(20px);
		}
	}
}

[id="diagram-fba"] {
  @extend %diagram;

  .ie & {
    height: rem(647.5px);
    height: 39vw;
  }
}

#sky, #far, #back, #middle, #front {
  will-change: transform;
}
//---- SKY -----//
#sun {
  animation: rising 4s ease-in-out;
  animation-fill-mode: backwards;
  will-change: opacity, transform;
}

#sunshines {
  animation: shine 3s ease-in-out, sun (60s*5) linear infinite 3s;
  transform-origin: 46.2% 55.6%;
  will-change: transform;

  .ie &, .firefox & {
    display: none;
  }
  @supports (-ms-ime-align:auto) {
    display: none;
  }
}

.sunshine {
  fill: $red;
  fill: url(#gradient);
  opacity: .2;
  transform: translate(-50%) rotate(0deg) translate(50%) translate(75px);
  transform-origin: center;

  &:nth-child(2n+1) {
    fill: url(#gradient-alt);
  }
  &:nth-child(1) {
    transform: translate(-50%) rotate(0deg) translate(50%) translate(75px);
  }
  &:nth-child(2) {
    transform: translate(-50%) rotate(215deg) translate(50%) translate(75px);
  }
  &:nth-child(3) {
    transform: translate(-50%) rotate(75deg) translate(50%) translate(75px);
  }
  &:nth-child(4) {
    transform: translate(-50%) rotate(130deg) translate(50%) translate(75px);
  }
  &:nth-child(5) {
    transform: translate(-50%) rotate(160deg) translate(50%) translate(75px);
  }
  &:nth-child(6) {
    transform: translate(-50%) rotate(190deg) translate(50%) translate(75px);
  }
  &:nth-child(7) {
    transform: translate(-50%) rotate(35deg) translate(50%) translate(75px);
  }
  &:nth-child(8) {
    transform: translate(-50%) rotate(250deg) translate(50%) translate(75px);
  }
  &:nth-child(9) {
    transform: translate(-50%) rotate(280deg) translate(50%) translate(75px);
  }
  &:nth-child(10) {
    transform: translate(-50%) rotate(320deg) translate(50%) translate(75px);
  }
}

%plane {
  opacity: 0;
  transform-origin: center;
  animation-fill-mode: both;
  will-change: transform, opacity;
}
#avion-1 {
	animation: planeClose 12s linear infinite 5s;
	@extend %plane;
}
#avion-2 {
  animation: planeFar 90s linear infinite 10s;
	@extend %plane;
}
#plane-l {
  animation: planeFar-alt1 40s linear infinite 5s;
	@extend %plane;
}
#plane-r {
  animation: planeFar-alt2 90s linear infinite 3s;
  @extend %plane;
}
#plane-big {
  animation: planeClose-alt 15s linear infinite;
	@extend %plane;
}

@keyframes sun {
  0% { transform:  rotate(0deg);}
  100% { transform: rotate(360deg);}
}
@keyframes shine {
  0% { transform:  scale(0);}
  100% { transform: scale(1);}
}
@keyframes rising {
  0% { opacity: .2; transform: translateY(100px);}
  100% { opacity: 1; transform: translateY(0);}
}

@keyframes planeFar {
  0% { transform:  scale(.25) translateX(1000px); opacity: 0;}
  5% {opacity: 1;}
  25% { transform: scale(.25) translateX(0); opacity: 1;}
  50% { opacity: 0;}
  65% {transform: scale(.25) translateX(-1000px);}
  100% { transform: scale(.25) translateX(1000px); opacity: 0;}
}
@keyframes planeFar-alt1 {
  0% { transform: scale(.5) translateY(20px); opacity: 0;}
  5% {opacity: 1;}
  95% { opacity: 1; }
  100% {opacity: 0; transform: scale(.5) translateX(-2000px) translateY(20px);}
}
@keyframes planeFar-alt2 {
  0% { transform: scale(.25); opacity: 0;}
  5% {opacity: 1;}
  95% { opacity: 1; }
  100% {opacity: 0; transform: scale(.25) translateX(4000px);}
}

@keyframes planeClose {
  0% { transform: translate(-600px, 25px) scale(1); opacity: 0;}
  3% {opacity: 1;}
  10% { transform: translate(0, 0) scale(.5); opacity: 1;}
  20% { opacity: 0;}
  25% {transform: translate(600px, -50px) scale(.2); opacity: 0;}
  100% { transform: translate(600px, -50px) scale(.2); opacity: 0; }
}
@keyframes planeClose-alt {
  0% {opacity: 0; transform: translateX(-370px) translateY(150px) scale(.5);}
  70% {opacity: 0; transform: translateX(-370px) translateY(150px) scale(.5);}
  71% {opacity: 0;}
  75% {opacity: 1;}
  95% {opacity: 1;}
  100% {opacity: 0;transform: translateX(370px) translateY(0) scale(.25);}
}

%nuage {
  animation-name: cloud;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  will-change: transform, opacity;
}
#nuage-1, #cloud-1, #cloud-6 {
  @extend %nuage;
  animation-duration: 20s;
  animation-delay: -20s;
}
#nuage-2, #cloud-2 {
  @extend %nuage;
  animation-duration: 40s;
  animation-delay: -2s;
}
#nuage-3, #cloud-3, #cloud-7 {
  @extend %nuage;
  animation-duration: 90s;
}
#nuage-4, #cloud-4 {
  @extend %nuage;
  animation-duration: 50s;
  animation-delay: -47s;
}
#nuage-5, #cloud-5 {
  @extend %nuage;
  animation-duration: 30s;
  animation-delay: -15s;
}

@keyframes cloud {
  0% { transform: translateX(0); opacity: 0;}
  25% {opacity: 1;}
  50% { transform: translateX(50px); opacity: 1;}
  100% { transform: translateX(100px); opacity: 0;}
}

//---- Foreground -----//
#steam-big {
	animation: steamBig 5s linear infinite;
	will-change: opacity, transform;
	.firefox & {
		animation-name: steamBigFirefox;
	}
	.no-firefox & {
		transform-origin: 50% 50%;
	}
}
#steam-small {
	animation: steamSmall 5s linear infinite 2.5s;
	animation-fill-mode: both;
	will-change: opacity, transform;
	.firefox & {
		animation-name: steamSmallFirefox;
	}
	.no-firefox & {
		transform-origin: 50% 50%;
	}
}

@keyframes steamBig {
  0% { transform: scale(.5) translateY(45px); opacity: 0;}
  25% { transform: scale(.8) translateY(20px); opacity: 1;}
  50% {transform: scale(1) translateY(0px); opacity: .7;}
  100% { transform: scale(1) translateY(-10px); opacity: 0;}
}
@keyframes steamSmall {
  0% { transform: scale(.9) translate(17px, 25px); opacity: 0;}
  25% { transform: scale(1) translate(17px, 0px); opacity: 1;}
  50% {transform: translate(17px, -5px); opacity: .7;}
  100% { transform: translate(17px, -10px); opacity: 0;}
}
@keyframes steamBigFirefox {
  0% { transform: translateY(20px); opacity: 0;}
  25% { transform: translateY(10px); opacity: 1;}
  50% {transform: translateY(0px); opacity: .7;}
  100% { transform: translateY(-10px); opacity: 0;}
}
@keyframes steamSmallFirefox {
  0% { transform: translate(17px, 15px); opacity: 0;}
  25% { transform: translate(17px, 0px); opacity: 1;}
  50% {transform: translate(17px, -5px); opacity: .7;}
  100% { transform: translate(17px, -10px); opacity: 0;}
}

#boat-1, #boat-3 {
	animation: boatBig 5s cubic-bezier(0.935, 0.195, 0.285, 1.030) infinite .5s;
	transform-origin:  50% 100%;
	will-change: transform;
	.firefox & {
		animation-name: boatBigFirefox;
	}
	.no-firefox & {
		transform-origin: 50% 100%;
	}
}
#boat-2, #boat-big {
	animation: boatSmall 5s cubic-bezier(0.935, 0.195, 0.285, 1.030) infinite;
	will-change: transform;
	.firefox & {
		animation-name: boatSmallFirefox;
	}
	.no-firefox & {
		transform-origin: 50% 100%;
	}
}
@keyframes boatBig {
  0% { transform: translate(0, 0);}
  12.5% { transform: translate(1px, 1px) rotate(1deg);}
  25% {transform: translate(2px, 0px) rotate(0deg);}
  50% { transform: translate(1px, -1px) rotate(-1deg);}
}
@keyframes boatSmall {
  0% { transform: translate(0, 0);}
  12.5% { transform: translate(-1px, 1px) rotate(-1deg);}
  25% {transform: translate(-2px, 0px) rotate(0deg);}
  50% { transform: translate(-1px, -1px) rotate(1deg);}
}
@keyframes boatBigFirefox {
  0% { transform: translate(0, 0);}
  12.5% { transform: translate(1px, 1px);}
  25% {transform: translate(2px, 0px);}
  50% { transform: translate(1px, -1px);}
}
@keyframes boatSmallFirefox {
  0% { transform: translate(0, 0);}
  12.5% { transform: translate(-1px, 1px);}
  25% {transform: translate(-2px, 0px);}
  50% { transform: translate(-1px, -1px);}
}

%wave {
  animation-timing-function: cubic-bezier(0.935, 0.195, 0.285, 1.030);
  animation-iteration-count: infinite;
  animation-duration: 5s;
  will-change: transform;
}
#wave-1, #wave-3, #wave-6 {
  @extend %wave;
	animation-name: wave2;
	transform-origin: 50% 100%;
}
#wave-2, #wave-4, #wave-5 {
  @extend %wave;
	animation-name: wave1;
	animation-delay: .5s;
	transform-origin:  50% 100%;
}

@keyframes wave1 {
  0% { transform: translate(0, 0);}
  12.5% { transform: translate(-1px, -1px)}
  25% {transform: translate(-2px, 0px)}
  50% { transform: translate(-1px, 1px)}
}
@keyframes wave2 {
  0% { transform: translate(0, 0);}
  12.5% { transform: translate(1px, 1px)}
  25% {transform: translate(2px, 0px)}
  50% { transform: translate(1px, -1px)}
}

#truck {
	animation: truck .2s cubic-bezier(0.175, 0.700, 0.410, 0.960) infinite;
	will-change: transform;
}
#truck-r-1, #truck-r-2, #truck-l {
  opacity: 0;
  transform-origin: center;
  will-change: transform, opacity;
}

#truck-r-1 {
  animation: truck-alt-l 5s linear infinite 2s;
}
#truck-r-2 {
  animation: truck-alt-l2 7s linear infinite;
}
#truck-l {
  animation: truck-alt-r 7s linear infinite 5s;
}

@keyframes truck {
  0% { transform: translate(0, 0);}
  25% { transform: translate(0, -0.5px)}
  50% {transform: translate(0, 0px)}
  75% { transform: translate(0, -0.25px)}
  100% { transform: translate(0, 0px)}
}
@keyframes truck-alt-l {
  0%{ opacity: 0; transform: translateX(50px) skewX(-5deg);}
  50% { opacity: 0; transform: translateX(50px) skewX(-5deg);}
  55% {opacity: 1; transform: translateX(50px) skewX(-5deg);}
  65% {opacity: 1; transform: translateX(-50px) skewX(-5deg);}
  70% {opacity: 0; transform: translateX(-50px) skewX(-5deg);}
  70.1% {transform: translateX(50px) skewX(-5deg);}
  100% {opacity: 0; transform: translateX(50px) skewX(-5deg);}
}
@keyframes truck-alt-l2 {
  0%{ opacity: 0; transform: translateX(50px) skewX(-5deg);}
  50% { opacity: 0; transform: translateX(50px) skewX(-5deg);}
  52.25% {opacity: 1; transform: translateX(50px) skewX(-5deg);}
  57.25% {opacity: 1; transform: translateX(-50px) skewX(-5deg);}
  60% {opacity: 0; transform: translateX(-50px) skewX(-5deg);}
  60.1% {transform: translateX(50px) skewX(-5deg);}
  100% {opacity: 0; transform: translateX(50px) skewX(-5deg);}
}
@keyframes truck-alt-r {
  0%{ opacity: 0; transform: translateX(-50px) skewX(5deg);}
  30% { opacity: 0; transform: translateX(-50px) skewX(5deg);}
  35% {opacity: 1; transform: translateX(-50px) skewX(5deg);}
  45% {opacity: 1; transform: translateX(50px) skewX(5deg);}
  50% {opacity: 0; transform: translateX(50px) skewX(5deg);}
  50.1% {transform: translateX(-50px) skewX(5deg);}
  100% {opacity: 0; transform: translateX(-50px) skewX(5deg);}
}

@keyframes movingtruck {
  0% { opacity: 0; transform: translateY(0) translateX(-100px);}
  10% { opacity: 1; }
  25% { transform: translateY(-0.5px)}
  50% { transform: translateY(0px)}
  75% { transform: translateY(-0.25px)}
  90% { opacity: 1; }
  100% { opacity: 0; transform: translateY(0px) translateX(100px);}
}

#parcel-1, #parcel-2, #parcel-3 {
	animation: parcel 4s cubic-bezier(0.175, 0.700, 0.410, 0.960) infinite;
	will-change: transform, opacity;
}

@keyframes parcel {
  0% { transform: translateX(-10px) translateY(0); opacity: 0;}
  10% { transform: translateX(-8px) translateY(-0.5px);}
  20% {transform: translateX(-6px) translateY(0px);}
  30% { transform: translateX(-4px) translateY(-0.25px); opacity: 1;}
  40% { transform: translateX(-2px) translateY(0px);}
  50% { transform: translateX(0px) translateY(-0.5px);}
  60% {transform: translateX(2px) translateY(0px);}
  70% { transform: translateX(4px) translateY(-0.25px); opacity: 1;}
  80% { transform: translateX(6px) translateY(0px);}
  90% { transform: translateX(8px) translateY(-0.5px);}
  100% { transform: translateX(10px) translateY(0px); opacity: 0;}
}

#clark {
	animation: clark 8s cubic-bezier(0.280, 0.360, 0.595, 1.295) infinite;
	will-change: transform;
}

@keyframes clark {
  0% {transform: translateX(0px);}
  50% {transform: translateX(35px);}
  100% {transform: translateX(0px);}
}

#parcel-top, #parcel-bottom {
	animation: disappear 8s cubic-bezier(0.280, 0.360, 0.595, 1.295) infinite -0.5s;
	will-change: opacity;
}

@keyframes disappear {
  0% {opacity: 1;}
  45% {opacity: 1;}
  50% {opacity: 0;}
  95% {opacity: 0;}
  100% {opacity: 1;}
}

#aos {
  position: relative;
  display: block;
  height: rem(228px);
  width: rem(175px);
  margin-left: auto;
  margin-right: auto;
  color: $red;
}

.aos-flags {
  position: absolute;
  top: 155px;
  z-index: -1;
  display: block;
  width: 22px;
  height: 22px;
  background-color: $grey-f;
  border-radius: 50%;
  transform: translate(0, -50%);
  will-change: opacity, transform, background-position;

  @include bg-img('/media/images/assets/flags-fba', 22px 110px, left top);

  &.export {
    left: -50px;
    background-position: 0 -22px; //China
    animation: aosExport 25s cubic-bezier(.31,.81,.28,1) infinite;
  }
  &.import {
    right: -50px;
    background-position: 0 0;
    animation: aosImport 25s cubic-bezier(.31,.81,.28,1) infinite;
  }
}
.aos-container {
  position: relative;
  max-width: rem(175px);
  margin-left: auto;
  margin-right: auto;
}

#aos #box {
  animation: aosBox 5s cubic-bezier(.31,.81,.28,1) infinite;
  will-change: opacity, transform;
}

#aos #bubble {
  animation: aosBubble 5s cubic-bezier(.31,.81,.28,1) infinite;
  will-change: opacity, transform;
}

$flag-offset: 40px;
@keyframes aosImport { //uk - de - us - us - eu
  0%  {opacity: 0; background-position: 0 0; transform: translate(-$flag-offset, -50%);} //UK
  6.66%  {opacity: 1; transform: translate(0, -50%);}
  13.33% {opacity: 1; transform: translate(0, -50%);}
  19.99% {opacity: 0; background-position: 0 0;}
  20% {opacity: 0; background-position: 0 -44px; transform: translate(-$flag-offset, -50%);} //DE
  26.66% {opacity: 1; transform: translate(0, -50%);}
  33.33% {opacity: 1; transform: translate(0, -50%);}
  39.99% {opacity: 0; background-position: 0 -44px;}
  40% {opacity: 0; background-position: 0 -66px; transform: translate(-$flag-offset, -50%);} //US
  46.66% {opacity: 1; transform: translate(0, -50%);}
  53.33% {opacity: 1; transform: translate(0, -50%);}
  59.99% {opacity: 0; background-position: 0 -66px;}
  60% {opacity: 0; background-position: 0 -66px; transform: translate(-$flag-offset, -50%);} //US
  66.66% {opacity: 1; transform: translate(0, -50%);}
  73.33% {opacity: 1; transform: translate(0, -50%);}
  79.99% {opacity: 0; background-position: 0 -66px}
  80% {opacity: 0;  background-position: 0 -88px; transform: translate(-$flag-offset, -50%);} //EU
  86.66% {opacity: 1; transform: translate(0, -50%);}
  93.33% {opacity: 1; transform: translate(0, -50%);}
  99.99% {opacity: 0; background-position: 0 -88px;}
  100%{opacity: 0; background-position: 0 0; transform: translate(-$flag-offset, -50%);}

}

@keyframes aosExport { //china - china - china - eu - us
  0%  {opacity: 0; background-position: 0 -22px; transform: translate($flag-offset, -50%);} //china
  6.66%  {opacity: 1; transform: translate(0, -50%);}
  13.33% {opacity: 1; transform: translate(0, -50%);}
  19.99% {opacity: 0; background-position: 0 -22px;}
  20% {opacity: 0; background-position: 0 -22px; transform: translate($flag-offset, -50%);} //china
  26.66% {opacity: 1; transform: translate(0, -50%);}
  33.33% {opacity: 1; transform: translate(0, -50%);}
  39.99% {opacity: 0; background-position: 0 -22px;}
  40% {opacity: 0; background-position: 0 -22px; transform: translate($flag-offset, -50%);} //china
  46.66% {opacity: 1; transform: translate(0, -50%);}
  53.33% {opacity: 1; transform: translate(0, -50%);}
  59.99% {opacity: 0; background-position:0 -22px;}
  60% {opacity: 0; background-position:  0 -88px; transform: translate($flag-offset, -50%);} //US}
  66.66% {opacity: 1; transform: translate(0, -50%);}
  73.33% {opacity: 1; transform: translate(0, -50%);}
  79.99% {opacity: 0; background-position: 0 -88px}
  80% {opacity: 0; background-position: 0 -66px; transform: translate($flag-offset, -50%);} //EU
  86.66% {opacity: 1; transform: translate(0, -50%);}
  93.33% {opacity: 1; transform: translate(0, -50%);}
  99.99% {opacity: 0; background-position: 0 -66px;}
  100%{opacity: 0; background-position: 0 -22px; transform: translate($flag-offset, -50%);}
}

@keyframes aosBox {
  0% {opacity: 0; transform: translateX(-50px);}
  5% {opacity: 1;}
  25% {transform: translateX(0);}
  75% {transform: translateX(0);}
  95% {opacity: 1;}
  100% {opacity: 0; transform: translateX(50px);}
}

@keyframes aosBubble {
  0% {opacity: 0; transform: translateY(15px);}
  30% {opacity: 0; transform: translateY(15px);}
  40% {opacity: 1; transform: translateY(0);}
  75% {opacity: 1;}
  85% {opacity: 0;}
  100% {opacity: 0;}
}

.news-single .news-block .news-content p {
  margin-bottom:15px;
}

p#footer-cta a {
  text-decoration: underline;
}

.switcher-container {
  overflow: hidden;
}

.language-switcher {
  position: relative;
  display: block;
  width: max-content;
  float: right;
  padding-left: rem(23+10px);
  margin-bottom: rem(20px);
  color: black;
  font-size: rem(23px);
  font-family: "Arial Unicode MS", "arial", sans-serif;
  transition: color .2s ease-in-out;

  &[lang="en"] {
    font-size: rem(18px);
    @include whitney;

    @include mq($ipad, false, max) {
      font-size: rem(16px);
    }

    @include mq($iphone, false, max) {
      font-size: rem(14px);
    }
  }

  .fba-top & {
    width: auto;
  }

  &:before {
    position: absolute;
    left: 0;
    @include center(false, true);
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    @include bg-img('/media/images/assets/flags-fba', 22px 110px, left top);
    content: "";
  }

  &:hover {
    color: $red;
  }

  &[lang*="zh"]:before {
    background-position: 0 -22px;
  }
  &[lang*="en"]:before {
    background-position: 0 0;
  }

  @include mq($ipad, false, max) {
    margin-bottom: rem(15px);
    font-size: rem(20px);
  }
  @include mq($iphone, false, max) {
    margin-bottom: rem(10px);
    font-size: rem(18px);
  }
}

.fba-heading {
  display: block;
  width: 100%;
  max-width: rem(380px);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem(25px);

  @include mq($imac, false, max) {
    max-width: rem(330px);
    margin-bottom: rem(20px);
  }
  @include mq($macbook, false, max) {
    max-width: rem(280px);
    margin-bottom: rem(15px);
  }
  @include mq($ipad, false, max) {
    max-width: rem(200px);
    margin-bottom: rem(10px);
  }
  @include mq($iphone, false, max) {
    max-width: rem(150px);
  }
}

.fba-top {
  position: relative;
  overflow: hidden;
  @include mq($portrait, false, max) {
    padding-top: rem(15px);
  }
  @include mq($iphone, false, max) {
    text-align: center;
    padding-top: rem(10px);
  }
}

.fba-corner {
  float: left;
  max-width: rem(280px);

  @include mq($imac, false, max) {
    max-width: rem(250px);
  }
  @include mq($macbook, false, max) {
    margin-top: rem(5px);
    max-width: rem(200px);
  }
  @include mq($ipad, false, max) {
    max-width: rem(180px);
  }
  @include mq($iphone, false, max) {
    float: none;
    max-width: rem(150px);
  }
}

.fba-form-header-icons {
  display: block;
  color: $red;
  margin-left: rem(-15px);
  margin-right: rem(-15px);
  .icon {
    margin-left: rem(15px);
    margin-right: rem(15px);
  }

  .icon-ui-plane {
    width: rem(42px);
  }
  .icon-ui-truck {
    width: rem(65px);
  }
  .icon-ui-boat {
    width: rem(65px);
  }

  @include mq($ipad, false, max) {
    .icon-ui-plane {
      width: rem(42px * .85);
    }
    .icon-ui-truck {
      width: rem(65px * .85);
    }
    .icon-ui-boat {
      width: rem(65px * .85);
    }
  }
  @include mq($iphone, false, max) {
    .icon-ui-plane {
      width: rem(42px * .65);
    }
    .icon-ui-truck {
      width: rem(65px * .65);
    }
    .icon-ui-boat {
      width: rem(65px * .65);
    }
  }
}


.tax-country-selector {
  &:before {
    position: relative;
    top: rem(3px);
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: rem(15px);
    background-color: $grey-f;
    @include bg-img('/media/images/assets/flags-fba', 22px 110px, left top);
    background-repeat: none;
    border-radius: 50%;
    content: "";
  }

  &[data-country="uk"]:before {
    background-position: 0 0;
  }
  &[data-country="zh"]:before {
    background-position: 0 -22px;
  }
  &[data-country="de"]:before {
    background-position: 0 -44px;
  }
  &[data-country="us"]:before {
    background-position: 0 -66px;
  }
  a {
    margin-left: rem(15px);
    display: inline-block;
    border-bottom: 1px solid;
    color: $red;

    &:hover {
      color: lighten($red, 15);
    }
  }
}

.breadcrumb {
  display: table;
  width: max-content;
  margin-right: auto;
  margin-left: auto;
  list-style: none;

  @include mq($portrait, false, max) {
    display: none;
  }

  & > li {
    position: relative;
    display: table-cell;
    @include whitney(600);
    @include rem(font-size, 12px);
    text-transform: uppercase;
    letter-spacing: .12em;
    text-align: center;

    a {
      @include rem(padding, 25px 5px 5px);
      margin-left: rem(20px);
      margin-right: rem(20px);

      &:before {
        position: absolute;
        top: rem(-23px);
        @include center(true, false);
        z-index: 1;
        display: block;
        width: rem(10px);
        height: rem(10px);
        background-color: white;
        border: 2px solid #d0d0d0;
        border-radius: 50%;
        content: "";
        pointer-events: none;
      }

      @include mq($macbook, false, max) {
        margin-left: rem(15px);
        margin-right: rem(15px);
      }
      @include mq($ipad, false, max) {
        margin-left: rem(10px);
        margin-right: rem(10px);
      }
    }

    &, a {
      color: black;
      transition: color .2s ease-in-out;
      &:hover {
        color: lighten($red, 10)!important;
      }
    }

    &.completed, &.active {
      &, a {
        color: $red;
      }
    }

    &:before, &:after {
      position: absolute;
      display: block;
      content: "";
      pointer-events: none;
      top: rem(-19px);
      width: 50%;
      height: 2px;
      background-color: #d0d0d0;
    }
    &:before {
      left: 0;
    }
    &:after {
      left: 50%;
    }
    &:first-child:before, &:last-child:after {
      content: none;
    }

    &:first-child {
      margin-left: auto;
    }
    &:last-child {
      margin-right: auto;
    }

    &.active a:before {
      border-color: $red;
    }

    &.completed:before,  &.completed:after, &.active:before {
        background-color: $red;
    }

    &.completed a:before {
      width: rem(10px);
      height: 6px;
      border-color: $red;
      border-style: none none solid solid;
      border-width: 0px 0px 2px 2px;
      border-radius: 0;
      outline: 10px solid white;
      transform: rotate(-45deg);
    }
  }
}

// Tab & Toggle
//------------------------------------------------------------------------------
.fake-tab--trigger, .fake-toggle--trigger {
  cursor: pointer;
}

.fake-tab--trigger {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.fake-tab--target, .fake-toggle--target {
  display: none;
  pointer-events: none;
}

.fake-tab--target:checked + .fake-tab--content {
  display: block;
}

.fake-toggle--target:checked ~ .fake-toggle--content {
  display: block;
}

.fake-tab--content, .fake-toggle--content {
  display: none;
}

.diagram-path {
  position: relative;
  width: 723px;
  height: 631px;
  margin-right: auto;
  margin-bottom: rem(150px);
  margin-left: auto;
  list-style: none;
  background-image: url('/media/images/assets/path-line.svg');
  background-size: contain;
  background-repeat: no-repeat;
  counter-reset: diagram;

  @include mq($macbook, false, max) {
    margin-bottom: rem(125px);
  }
  @include mq($ipad, false, max) {
    margin-bottom: rem(125px);
  }

  @media only screen and (max-width: 900px) {
    width: 723px * .75 ;
    height: 631px * .75 ;
    margin-bottom: rem(125px);
  }

  @include mq($iphone, false, max) {
    width: auto;
    height: auto;
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: rem(25px);
    background-image: none;
  }
}

.diagram-item {
  position: absolute;
  counter-increment: diagram;

  &:nth-child(1) {
    top: -50px;
    left: 110px;

    @media only screen and (max-width: 900px) {
      top: -35px;
      left: 90px;
    }
  }
  &:nth-child(2) {
    top: -50px;
    left: 465px;

    @media only screen and (max-width: 900px) {
      top: -35px;
      left: 345px;
    }
  }
  &:nth-child(3) {
    top: 144px;
    left: 573px;

    @media only screen and (max-width: 900px) {
      top: 115px;
      left: 430px;
    }
  }
  &:nth-child(4) {
    top: 155px;
    left: 160px;

    @media only screen and (max-width: 900px) {
      top: 120px;
      left: 120px;
    }
  }
  &:nth-child(5) {
    top: 345px;
    left: 25px;

    @media only screen and (max-width: 900px) {
      top: 255px;
      left: 25px;
    }
  }
  &:nth-child(6) {
    top: 360px;
    left: 395px;

    @media only screen and (max-width: 900px) {
      top: 275px;
      left: 295px;
    }
  }
  &:nth-child(7) {
    top: 565px;
    left: 510px;

    @media only screen and (max-width: 900px) {
      top: 425px;
      left: 385px;
    }
  }
  &:nth-child(8) {
    top: 565px;
    left: 115px;

    @media only screen and (max-width: 900px) {
      top: 425px;
      left: 75px;
    }
  }

  @include mq($iphone, false, max) {
    position: static;
    padding: rem(15px) rem(20px);

    &:nth-child(even) {
      background-color: $grey-f;
    }
  }
}

.diagram-circle {
  position: relative;
  width: rem(110px);
  height: rem(110px);
  background-color: white;
  border-radius: 50%;

  .icon {
    position: absolute;
    @include center;
    max-width: rem(60px);
    max-height: rem(60px);
    color: $red;
  }

  @media only screen and (max-width: 900px) {
    width: rem(80px);
    height: rem(80px);

    .icon {
      max-width: rem(60px * .75);
      max-height: rem(60px * .75);
    }
  }

  @include mq($iphone, false, max) {
    margin-right: auto;
    margin-bottom: rem(10px);
    margin-left: auto;

    box-shadow: 0px 0px 0px 1px $grey-f;
  }
}

.diagram-title {
  width: max-content;
  @include whitney(600);
  font-size: rem(16px);
  transition: color .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);

  .ie & {
    width: rem(230px);
    text-align: center;

    @include mq($iphone, false, max) {
      width: auto;
    }
  }

  @supports (-ms-ime-align:auto) {
    width: rem(230px);
    text-align: center;
  }

  &:before {
    position: relative;
    top: rem(-2px);
    display: inline-block;
    width: rem(20px);
    height: rem(20px);
    margin-right: rem(10px);
    border-radius: 50%;
    color: white;
    background-color: $red;
    font-size: rem(12px);
    line-height: rem(20px);
    text-align: center;
    vertical-align: middle;
    content: counter(diagram);
  }

  &.no-list {
    width: auto;
    &:before {
      content: none;
    }
  }

  .diagram-path & {
    position: absolute;
    @include center(true, false);

    @include mq($iphone, false, max) {
      position: static;
      width: auto;
      margin-right: auto;
      margin-bottom: rem(10px);
      margin-left: auto;
      text-align: center;
      transform: none;
    }
  }

  .diagram-item:hover & {
    color: $red;
  }
  .list-centered & {
    margin-right: auto;
    margin-bottom: rem(25px);
    margin-left: auto;
    text-align: center;
  }
}

.diagram-popup {
  position: absolute;
  bottom: 100%;
  @include center(true, false);
  z-index: 1;
  display: none;
  width: rem(345px);
  padding: rem(20px) rem(25px);
  background-color: #010202;
  color: white;
  @include whitney;
  font-size: rem(16px);
  text-align: center;

  a {
    color: white;
    font-weight: bold;
  }

  &:after {
    position: absolute;
    top: 100%;
    @include center(true, false);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 4px solid #010202;
    content: "";
  }

  .diagram-item:hover & {
    display: block;
  }

  @include mq($imac, false, max) {
    width: rem(300px);
  }
  @include mq($macbook, false, max) {
    width: rem(275px);
    padding: rem(15px) rem(20px);
    font-size: rem(14px);
  }
  @include mq($ipad, false, max) {
    padding: rem(10px) rem(15px);
    width: rem(250px);
  }

  @include mq($iphone, false, max) {
    position: static;
    display: block;
    width: auto;
    background-color: transparent;
    transform: none;

    &:after {
      content: none;
    }

    &, a {
      color: $black-80;
    }
  }
}

.list-centered {
  max-width: rem(675px);
  margin-right: auto;
  margin-left: auto;
  list-style: none;
  text-align: center;
  counter-reset: diagram;

  & > li {
    margin-bottom: rem(45px);
    counter-increment: diagram;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.more-mb > li {
    margin-bottom: rem(70px);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-title {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    color: $red;
    .icon {
      top: -2px;
      transition: transform .2s cubic-bezier(0.070, 0.635, 0.415, 0.990);
    }

    .fake-toggle--target:checked ~ & {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  p {
    color: $black-80;
  }

  .text {
    padding-top: 0;
  }

  .icon-container {
    position: relative;
    display: block;
    width: rem(114px);
    height: rem(114px);
    margin-left: auto;
    margin-bottom: rem(25px);
    margin-right: auto;
    border-radius: 50%;
    background-color: $grey-f;

    .icon,
    span {
      position: absolute;
      @include center;
      color: $red;
      max-width: rem(90px);
      max-height: rem(63px);
    }

    span {
      @include utopia;
      display: inline-block;
      text-align: center;
      font-size: 60px;
      font-weight: 700;
      line-height: 1;
    }
  }

  @media only screen and (max-width: 900px) {
    &.more-mb > li {
      margin-bottom: rem(45px);
    }

    .icon-container {
      width: rem(80px);
      height: rem(80px);
      .icon {
        max-width: rem(90px * .75);
        max-height: rem(63px * .75);
      }
    }
  }
  @include mq($iphone, false, max) {
    margin-left: -20px;
    margin-right: -20px;
    & > li {
      padding: rem(15px) 20px rem(20px);
      &:nth-child(even) {
        background-color: $grey-f;
      }
    }
    &.more-mb > li {
      margin-bottom: 0;
    }

    .icon-container {
      margin-bottom: rem(10px);
      background-color: white;
      box-shadow: 0px 0px 0px 1px $grey-f;
    }
  }
}

.warning {
  position: relative;
  min-height: rem(50px);
  padding-left: rem(85px);
  margin-bottom: rem(40px);
  color: $red;
  font-size: rem(16px);
  box-sizing: content-box;

  &.line-to-bottom {
    padding-bottom: rem(40px);
  }

  .icon {
    position: absolute;
    top: rem(5px);
    left: 0;
    width: rem(58px);
    height: 100%;
    max-height: rem(51px);
  }

  strong {
    text-transform: uppercase;
    letter-spacing: .12em;
  }

  @include mq($macbook, false, max) {
    padding-left: rem(85px * .9);
    margin-bottom: rem(30px);

    &.line-to-bottom {
      padding-bottom: rem(30px);
    }

    .icon {
      width: rem(58px * .9);
    }
  }

  @include mq($ipad, false, max) {
    min-height: rem(30px);
    padding-left: rem(85px * .8);
    margin-bottom: rem(20px);

    &.line-to-bottom {
      padding-bottom: rem(20px);
    }

    strong {
      letter-spacing: .08em;
    }

    .icon {
      top: 0;
      width: rem(58px * .8);
    }
  }

  @include mq($iphone, false, max) {
    padding-left: rem(85px * .5);
    margin-bottom: rem(15px);
    font-size: rem(14px);

    &.line-to-bottom {
      padding-bottom: rem(15px);
    }

    strong {
      letter-spacing: 0;
    }

    .icon {
      top: rem(2px);
      width: rem(58px * .5);
      height: auto;
      transform: none;
    }
  }
}

.success {
  p:first-child {
    color: $black-80;
    @include whitney(bold);
    font-size: rem(18px);
    letter-spacing: .08em;
    text-transform: uppercase;
    margin-bottom: rem(15px);
    &:before {
      position: relative;
      top: -3px;
      display: inline-block;
      width: rem(14px);
      height: rem(8px);
      margin-right: rem(10px);
      border-style: none none solid solid;
      border-width: 3px;
      border-color: $red;
      transform: rotate(-45deg);
      content: "";
    }
    @include mq($portrait, false, max) {
      margin-bottom: rem(10px);
      font-size: rem(16px);

      &:before {
        width: rem(12px);
        height: rem(6px);
        border-width: 2px;
      }
    }

    @include mq($iphone, false, max) {
      margin-bottom: rem(5px);
      font-size: rem(14px);
    }
  }
  p:not(:first-child) {
    @extend .text;
  }
}

.fba-stamp {
  width: rem(300px);
  height: auto;
  margin-right: auto;
  margin-left: auto;

  @include mq($macbook, false, max) {
    width: rem(250px);
    height: auto;
  }

  @include mq($ipad, false, max) {
    width: rem(200px);
    height: auto;
  }

  @include mq($iphone, false, max) {
    width: rem(200px);
    height: auto;
  }

  .footer-nav & {
    width: rem(130px);
    height: rem(130px);
    margin-right: 0;

    @media all and (max-width: 1200px) {
      position: absolute;
      right: 2.5rem;
      bottom: 2.5rem;
      width: rem(110px);
      height: rem(110px);
    }

    @include mq($ipad, false, max) {
      right: rem(30px);
      bottom: rem(30px);
      width: rem(100px);
      height: rem(100px);
    }

    @media all and (max-width: 880px) {
      position: static;
      width: rem(70px);
      height: rem(70px);
      margin-right: auto;
      margin-left: 0;
    }
  }
}

.stand-out {
  padding-top: rem(20px);
  padding-bottom: rem(20px);
  margin-left: rem(-20px);
  margin-right: rem(-20px);
  color: $red;
  font-size: rem(22px);
  @include utopia;
  text-align: center;

  @include mq($imac, false, max) {
    font-size: rem(20px);
  }

  @include mq($ipad, false, max) {
    font-size: rem(18px);
  }

  @include mq($portrait, false, max) {
    margin-left: 0;
    margin-right: 0;
    padding-top: rem(10px);
    padding-bottom: rem(10px);
    text-align: left;
  }

  @include mq($iphone, false, max) {
    font-size: rem(16px);
  }
}

.user-menu {
  .nav-contact & {
    @include rem(padding-top, 9px);
    @include rem(padding-bottom, 8px);

    & > li {
      float: left;
    }

    //mq
    @include mq($ipad, false, max) {
      @include rem(padding-top, 10px);
      @include rem(padding-bottom, 8px);
    }
  }

  & > li {
    margin-right: rem(10px);
    @include whitney(bold);
    font-size: rem(11px);
    text-transform: uppercase;
    letter-spacing: .2em;
    a {
      color: $black-80;
      transition: color .2s ease-in-out;
      &:hover {
        color: $red;
      }
    }

    &:after {
      display: inline-block;
      width: rem(3px);
      height: rem(3px);
      background-color: $black-15;
      vertical-align: middle;
      content: "";
      margin-left: rem(6px);
    }

    &:last-child {
      margin-right: 0;
      &:after {
        content: none;
      }
    }

    @include mq($ipad, false, max) {
      font-size: rem(10px);
    }
  }
}

.footer-text {
  .text {
    font-size: 0.9em;
    color: black;
  }
}

.register-fm {

  p {
    @extend .text;
  }

  ul {
    @extend .text;
    @extend .list-check;
    @extend .block-m-mini;
  }
}

.xlarge-6 {
  @include mq($design) {
    width: 50%;
  }
}

.pull-right {
  float: right !important;
}

.services h1.service-heading.title-20 {
  font-size: 1em;
}

.btn-search {
  padding: 7px 20px 6px;
  width: 100%;
  text-transform: none;
  font-weight: bold;
  border-radius: 0;

  .icon {
    top: -2px;
    left: 2px;
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.networking-container {
  .icon {
    width: 125px;
    color: red;
  }
}

.division-text {
  h2, ul, ol, p, img {
    margin-top: 20px;
  }

  ul li {
    margin-bottom: 5px;
  }

  ol li {
    margin-bottom: 5px;
  }

  ol + ol,
  ul + ul {
    margin-top: 50px;
  }
}

.text-center {
  text-align: center;
}

.text-smaller {
  font-size: 0.8em;
}

.icon-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0;
  padding-bottom: 0;

  &.hairline-double {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  &.hairline-top {
    padding-top: 20px;
  }

  &.hairline-bottom {
    padding-bottom: 30px;
  }

  .block-container &.hairline-bottom {
    padding-bottom: 50px;
  }
}

.icon-list__item {
  display: flex;
  align-items: center;
  padding-left: 0 !important;
  padding-right: 20px !important;
  margin-top: 15px;
  margin-bottom: 15px;

  .icon-container {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .large-up-1 & .icon-container {
    margin-right: 30px;
  }

  .service-summary-body {
    flex-grow: 1;
  }
}

.icon-grid {
  .service-inner--large .icon {
      height: 70px;
      width: 90px;
      margin-bottom: 0px;
    }
}

.icon-grid--no-float .columns:last-child {
  float: left;
}

.icon-simple-grid {
  padding: 50px 0;

  .intro-title {
    padding-top: 25px;
  }
  .icon-container {
    padding: 30px 0;
    min-height: 135px;

    .icon {
      color: black;
    }
  }
  .icon-row-title {
    padding: 20px 0;
  }
  .text-intro {
    margin-top: 50px;
  }
}

