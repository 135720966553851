$mosaic-colors: (
  blue:         $blue   left,
  black:        $black      left,
  orange:       $orange   left,
  green:        $green   right,
  bronze:       $bronze   right,
  gold:         $gold   left,
	brown:        $brown   right,
	blue-dark:    $blue-dark   left,
	purple:       $purple   right
);

$mosaic-colors-wide: (
  blue:         $blue   top,
  black:        $black      bottom,
  orange:       $orange   top,
  green:        $green   bottom,
  bronze:       $bronze   top,
  gold:         $gold   bottom,
	brown:        $brown   top,
	blue-dark:    $blue-dark   bottom,
	purple:       $purple   top
);

.mosaic  {
  font-size: 0;
  .alt-sub-title {
	  text-transform: uppercase;
	  letter-spacing: .2em;
	  @include rem(margin-bottom, 25px);
  }
}

.mosaic__container {
  font-size: 0;
  display: inline-block;
  vertical-align: top;
}

.mosaic__item {
  display: inline-block;
  font-size: 1rem;
  position: relative;
  vertical-align: top;
}

.mosaic__color {
  color: white;
  font-size: 0.75em;
  padding-top: 100%;
  position: relative;

  @media #{$large-up} {
    font-size: 1em;
  }
}

.mosaic__color--wide {
  color: white;
  left: 0;
  font-size: 0.75em;
  position: absolute;
  width: 100%;
  z-index: 3;

  @media #{$large-up} {
    font-size: 1em;
  }
}

.mosaic__color-long {
	padding-top: 100%;
}

 // Loop through each color and arrow direction
.mosaic__color {
  $arrow-size: 1em;

  @each $class-name, $properties in $mosaic-colors {
    $color: nth($properties, 1);
    &.theme-#{$class-name} {
      background: $color;
    }
  }
}
 // Loop through each color and arrow direction
.mosaic__color--wide {
  $arrow-size: 1em;
	&.top {
		top: 0;
	}
	&.bottom {
		bottom: 0;
	}
  @each $class-name, $properties in $mosaic-colors-wide {
    $color: nth($properties, 1);
    $offset: nth($properties, 2);

    $direction: top;

    @if($offset == top ) {
      $direction: bottom;
    }
  }
}

.mosaic__inner {
  padding: 0 1.5em;
  position: absolute;
  @include center(false, true);
  text-align: center;
  width: 100%;
}

.mosaic__inner--top {
  @extend .mosaic__inner;
  position: relative;
  transform: none;
  top: 0;
  .alt-sub-title {
	  text-align: left;
	  margin: 0;
    padding: 2.5em 0;
  }

  .btn {
    float: right;
    margin: 2em 0;
  }
}

.mosaic__image {
  padding-top: 100%;
}

.mosaic__image--short {
  height: 20em;
}

.mosaic__bg {
	display: block;
  @extend %background-cover;
  color: white;
  position: relative;
  background-color: transparent;
  transition: all .2s ease-in-out;
  background-blend-mode: soft-light;
	cursor: pointer;

  .mosaic__inner {
	  z-index: 2;
  }

  &:before, &:after {
  	content: '';
  	display: block;
  	position: absolute;
  	top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
  }
  &:before {
	  background-color: $black-30;
	  z-index: -1;
  }

  &:after {
	  z-index: 0;
	  background-color: rgba($black, .1);
	  @extend %opacity-0;
	  transition: all .2s ease-in-out;
  }
  &:hover {
		&:after {
			@extend %opacity-1;
		}
  }

  @each $color, $hexa in $colors {
	  &.theme-#{$color}:hover {
		  &, .btn {
			  background-color: $hexa;
		  }
		  .btn {
		  	border-color: $hexa;
			}
		}
	}
}

.mosaic__icon {
	margin-top: -2em;
	@include rem(max-width, 85px);
  @include center;
  position: absolute;
  z-index: 2;
}
