.icon-group {
  border-top: 1px solid $grey-f;
  padding-top: 30px;
  padding-bottom: 30px;
}

.icon-group__item {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;

  h3 {
    @include utopia;
    display: inline-block;
    border-top: 1px solid $grey-f;
    border-bottom: 1px solid $grey-f;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  p {
    @include utopia;
    font-size: 16px;
  }
}

.icon-group__icon {
  height: 80px;

  i {
    width: 60px;
    max-height: 60px;
  }
}
