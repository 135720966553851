.mosaicGallery {
  overflow: hidden;
}

.mosaicGallery__row {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  &:first-child {
    .mosaicGallery__item:after {
      display: none;
    }
  }
}

.mosaicGallery__item {
  padding: 0;
  position: relative;
  $border-width: 2px;

  @media screen and (min-width: 769px) {
    display: inline-block;

    &:before,
    &:after {
      content: "";
      position: absolute;
      z-index: 30;
      background-color: #fff;
    }

    &:before {
      top: 0;
      left: 0;
      height: 100%;
      width: $border-width;
    }

    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: $border-width;
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
    width: 100% !important;
    margin-top: 4px !important;
  }
}

.mosaicGallery__item:last-child {
  float: none;
  overflow: hidden;
  width: auto;

  // Stop image getting too large if it's the only row item.
  &:first-child {
    max-width: 482px;
  }
}

.mosaicGallery__item-link {
  display: block;
  z-index: 10;

  &:hover,
  &:focus,
  &:active {
    .mosaicGallery__item-overlay {
      @include opacity(1);
    }
  }
}

.mosaicGallery__item-image {
  width: 100%;
  height: auto;
  margin: 0;
  position: relative;
  z-index: 10;
}
