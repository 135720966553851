.case-studies {
  position: relative;
}

.case-studies__item {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: $row-width-px;

  @include breakpoint(medium up) {
    padding-left: 0;

    &:nth-child(odd) {
      flex-direction: row-reverse;
      padding-right: 0;
      padding-left: 20px;
    }

    .case-studies--reverse &:nth-child(odd) {
      flex-direction: row;
      padding-left: 20px;
      padding-left: 0;
    }

    .case-studies--reverse &:nth-child(even) {
      flex-direction: row-reverse;
      padding-right: 0;
      padding-left: 20px;
    }
  }
}

.case-studies__image {
  width: 100%;
  padding: 20px;

  @include breakpoint(medium up) {
    width: 33.33%;
    padding-left: 0;

    .case-studies__item:nth-child(odd) & {
      padding-right: 0;
      padding-left: 20px;
    }
  }
}

.case-studies__content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  padding: 20px;

  @include breakpoint(medium up) {
    width: 66.66%;
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
  }

  h3,
  p {
    @include utopia;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 30px;

    @include breakpoint(medium up) {
      font-size: 45px;
    }
  }

  ul,
  ol {
    @include utopia;
    margin-bottom: 20px;

    & > li {
      @include rem(padding-left, 15px);
      margin-bottom: 10px;

      a:hover {
        text-decoration: underline;
      }

      &:before {
        content: '›';
        color: $red;
        display: inline-block;
        @include rem(margin-right, 10px);
        @include rem(margin-left, -15px);
      }
    }
  }

  a.btn {
    border: 2px solid;
  }

  a:not(.btn) {
    color: $red;
  }
}

.case-studies__content__inner {
  width: 100%;

  p {
    max-width: 500px;
  }
}

.case-studies__content__footer {
  width: 100%;
  text-align: right;
  align-self: flex-end;
}
