.header-list {

  @include mq($ipad, false, max) {
    display: none;
  }

  li {
    display: inline-block;
    padding-top: 1px;
    padding-bottom: 1px;
  }

  a {
    position: relative;
    font-size: rem(14px);
    padding-right: rem(10px);
    padding-left: rem(10px);
    line-height: 34px;
    color: black;
  }

  a:before {
    @include bg-img('/media/images/assets/flags', 16px 64px, left top);
    position: relative;
    top: 3px;
    margin-right: 5px;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: $grey-f;
    border: 1px solid $black-15;
    border-radius: 50%;
    content: '';
  }

  li.country--48 a:before {
    @include bg-img('/media/images/assets/preview-full-RGB_L48_Mark_Colour_96px', 10px, center);
  }

  li.country--ck a:before {
    background-position: left top;
  }

  li.country--au a:before {
    background-position: left -16px;
  }

  li.country--usa a:before {
    background-position: left bottom;
  }

  li.country--fba a:before {
    background-position: left -32px;
  }
}

.header-list__select {
  @include mq($ipad, false, min) {
    display: none!important;
  }
}
