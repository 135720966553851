.first-letter-text {
  @include rem(font-size, 22px);
  @include utopia(normal, normal);
  line-height: 2;

  &:first-letter {
    font-size: 74px;
    vertical-align: bottom;
    display: inline-block;
    padding-left: 20px;
    margin-right: 10px;
    margin-bottom: 10px;
    line-height: 1;
    float: left;
  }
}

.columned {
  @include whitney(normal, normal);
  margin-top: 40px;
  margin-bottom: 60px;
  line-height: 1.7;

  @include mq($ipad, false, min) {
    column-gap: 70px;
    column-count: 2;
    column-rule: 1px solid #f2f2f2;
  }
}
