.icon-blocks {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $red;
  border-left: 1px solid $red;
  max-width: none;
}

.icon-blocks__item {
  position: relative;
  text-align: center;
  display: block;
  padding: 26px;
  height: 200px;
  box-shadow: 1px 1px 0 0 $red;
  margin-bottom: 0;
  transition: background-color 0.25s;

  &--empty:hover {
    color: $white;
    background-color: $red;
  }

  @include breakpoint(small down) {
    padding: 15px;
    height: 170px;

    &:nth-child(2n + 2) {
      border-right: 1px solid $red;
    }
  }

  @include breakpoint(medium only) {
    padding: 40px 26px 26px;

    &:nth-child(3n + 3) {
      border-right: 1px solid $red;
    }
  }

  @include breakpoint(large up) {
    padding: 40px 26px 26px;

    .large-up-4 &:nth-child(4n + 4) {
      border-right: 1px solid $red;
    }

    .large-up-3 &:nth-child(3n + 3) {
      border-right: 1px solid $red;
    }

    .large-up-2 &:nth-child(2n + 2) {
      border-right: 1px solid $red;
    }
  }
}

.icon-blocks__inner {
  width: 100%;
  transition: 0.25s;

  @include breakpoint(medium up) {
    .icon-blocks__item:not(.icon-blocks__item--empty):hover & {
      opacity: 0;
      visibility: hidden;
    }
  }

  i {
    width: 50px;
    height: 50px;
  }

  h3 {
    @include whitney;
    position: relative;
    font-size: 18px;
    letter-spacing: 0.28em;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 10px;

    @include breakpoint(small down) {
      font-size: 16px;
      letter-spacing: 0.05em;
    }
  }

  h3::after {
    width: 75px;
    height: 2px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    content: '';
    background-color: currentColor;
  }
}

.icon-blocks__overlay {
  @include utopia;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: left;
  background-color: $red;
  color: $white;
  padding: 13px;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  display: none;

  .icon-blocks__item:hover & {
    opacity: 1;
    visibility: visible;
  }

  @include breakpoint(medium up) {
    padding: 26px;
    display: block;
  }

  h3 {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    line-height: 1.5;
  }
}
