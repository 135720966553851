//  Variables
//  ----------------------------------------

$doc-font-size: 16;
$padding: 48px;

//  Color Variables
//  ----------------------------------------

$black:          #010101;
$black-15:       rgb(217,217,217);
$black-30:       rgb(179,179,179);
$black-40:       rgb(153,153,153);
$black-50:       rgb(128, 128, 128);
$black-60:       rgb(102,102,102);
$black-80:       rgb(88,87,84);
$white:          #FFF;
$grey:           #d3d3d3;

$grey-f:         #f2f2f2;
$bright-color:   #e20019;
$contrast-color: #0173af;

$red:            rgb(226, 0, 26);

$gold:           rgb(203, 182, 117);
$bronze:         #8a7967;
$brown:          rgb(138, 121, 103);
$orange:         rgb(241, 139, 29);
$green:          rgb(97, 144, 128);
$blue:           #4e82d0;
$blue-dark:      #015480;
$purple:         #8a5779;

// These are in a specific order to match the timeline colours.
$colors:         (red, $red),
                 (gold, $gold),
                 (orange, $orange),
                 (black, $black),
                 (brown, $brown),
                 (green, $green),
                 (blue, $blue),
                 (blue-dark, $blue-dark),
                 (purple, $purple),
                 (bronze, $bronze);


//  Media Queries
//  ----------------------------------------

// these should be unitless


$small:          460;
$medium:         670;
$regular:        850;
$large:          1000;
$big:            1200;
$container:      1440;
$design:         1530;


$iphone:          640;
$portrait:        768;
$ipad:            1024;
$macbook:         1280;
$imac:            1440;
