.freeform {}

.freeform-row .freeform-column .freeform-label {
  font-weight: normal !important;
}

.freeform-row {
  align-items: flex-start;

  @include breakpoint(small down) {
    flex-wrap: wrap;
  }
}

.freeform-column {
  position: relative;
  padding-bottom: 0 !important;
  margin-bottom: 20px !important;
  overflow: hidden;

  @include breakpoint(small down) {
    width: 100%;
    flex: auto !important;
  }

  input {
    position: relative;
    z-index: 3;
    background-color: white;
  }

  select {
    position: relative;
    z-index: 0;
    appearance: none;
    background-color: black;
    color: white;
    padding: 12.5px 30px;
    border-radius: 0;
  }

  &.freeform-type--select::before {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 44px;
    background-color: red;
    content: '';
    z-index: 1;
  }

  &.freeform-type--select::after {
    position: absolute;
    bottom: 20px;
    right: 6px;
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 5px solid white;
    content: '';
    z-index: 2;
  }
}
