.full-banner {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.full-banner__item {
  position: relative;
  width: 100%;

  @include mq($iphone, false, min) {
    width: 50%;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(#7B7B7B, 0.6);
  }

  img {
    width: 100%;
  }
}

.full-banner__title {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 450px;
  color: white;
  text-align: center;

  h1 {
    line-height: 1.7;
    font-weight: 500;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 0.3em;

    @include mq($ipad, false, min) {
      font-size: 50px;
    }
  }

  p {
    font-size: 16Px;
    font-family: "utopia-std", Georgia, serif;
  }
}

.full-banner__icon {
  position: absolute;
  z-index: 1;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #fff;
  width: 76px;
  text-align: center;
  color: $red;

  @include mq($ipad, false, min) {
    width: 115px;
  }

  &::before {
    display: block;
    padding-bottom: 100%;
    content: '';
  }

  h2,
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
  }

  h2 {
    font-size: 16Px;
    font-weight: 500;
    font-family: "utopia-std", Georgia, serif;
    line-height: 1.5;

    @include mq($ipad, false, min) {
      font-size: 22Px;
    }
  }
}

.full-banner__dot {
  position: absolute;
  width: 4%;
  border-radius: 50%;
  background-color: $red;
  z-index: 1;

  &::before {
    display: block;
    padding-bottom: 100%;
    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    transform: translate(-50%, -50%);
    content: '';
    border: 2px solid $red;
    border-radius: 50%;
  }

  span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    background-color: $red;
    transform-origin: top left;
    transition: transform 1s 0.5s;
    transform: translatex(-50%) translatey(-100%) scaley(0);
  }

  &.is-active span {
    transform: translatex(-50%) translatey(-100%) scaley(1);
  }
}

.full-banner__video {
  position: relative;
  overflow: hidden;
  width: 100%;

  &::before  {
    display: block;
    content: '';
    padding-bottom: percentage(523 / 1465);
  }

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    transition: 0.5s 1.5s;
    opacity: 0;
    visibility: hidden;
  }

  .full-banner.is-active & video {
    opacity: 1;
    visibility: visible;
  }
}
