.main-nav__list {
  border-bottom: 1px solid $white;
  padding: 32px 0 16px;
  width: 100%;
  @include mq($small) {
    border-bottom: 0;
  }
  @include mq(1000) {
    display: table;
    float: left;
    table-layout: fixed;
  }
}

.main-nav__item {
  width: 100%;
  position: relative;
  text-align: center;
  @include mq($small) {
    float: left;
    width: 50%;
  }
  @include mq(850) {
    width: 33.3%;
  }
  @include mq(1000) {
    display: table-cell;
    width: 12.5%;
  }
}

.main-nav__link {
  border-bottom: 2px solid transparent;
  color: $white;
  display: inline-block;
  font-size: rem(15px);
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  vertical-align: middle;
  &:hover {
    border-bottom: 2px solid $white;
  }
}




.sub-nav__list {
  height: 48px;
  position: absolute;
  width: 100%;
  display: table;
  display: none;
  li {
    display: table-cell;
  }
}


.has-subnav:hover {
  .sub-nav__list {

  }
}

@include mq(1200) {
  .main-nav {
    padding-bottom: 0;
  }
}

@include mq(1536) {
  .main-nav {
    clear: none;
    display: inline-block;
    float: none;
    position: relative;
    top: 62px;
    width: 77.3385417%;
  }
  .main-nav__list {
    display: table;
    padding: 0;
    table-layout: fixed;
    width: 100%;
  }

  .main-nav__item {
    display: table-cell;
    position: relative;
    text-align: center;
  }
}
