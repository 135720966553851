.info-columns {
  @include utopia;
}

.info-columns__header {
  text-align: center;
  padding: 50px 26px 0;

  h2 {
    @include rem(font-size, 54px);
    letter-spacing: .04em;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
  }
}

.info-columns__items {
  display: flex;
  flex-wrap: wrap;
  padding: 13px;

  @include breakpoint(medium up) {
    padding: 26px;
  }
}

.info-columns__item {
  position: relative;
  padding: 13px;
  width: 100%;

  @include breakpoint(medium up) {
    width: 50%;
    padding: 26px;
  }

  @include breakpoint(large up) {
    width: 33.33%;
    padding: 26px 50px;

    &:not(:last-child)::before {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      height: 70%;
      width: 1px;
      background-color: $red;
      content: '';
    }
  }
}

.info-columns__inner {
  h3 {
    @include rem(font-size, 36px);
    letter-spacing: .04em;
    line-height: 1.2;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    text-align: center;
    margin-bottom: 20px;
  }

  li {
    @include whitney;
    position: relative;
    color: $black-80;
    padding-left: 15px;
    margin-bottom: 15px;
  }

  li::before {
    position: absolute;
    top: 0.5em;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: $red;
    border-radius: 50%;
    content: '';
  }

  p {
    @include whitney;
    color: $black-80;
    margin-bottom: 15px;
  }

  p:first-of-type > strong {
    @include utopia;
    color: $black;
    font-weight: 500;
    font-size: 18px;
    font-style: italic;
  }
}
