.service-image-grid {}

.service-image-grid__item {
  text-align: center;

  img {
    display: inline-block;
    width: 50%;
  }

  p {
    display: inline-block;
    font-weight: 700;
    font-size: 2rem;
    border-bottom: 1px solid;
    margin-top: 15px;
    color: black;
    transition: 0.25s;
  }

  a:hover p {
    color: $red;
  }
}

.service-image-grid__inner {
  display: block;
  padding: 40px;
  border: 7px solid #f2f2f2;
}
