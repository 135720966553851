.banner {
  padding: 90px 26px;
  text-align: center;
  background-color: $red;
  color: white;

  p {
    @include utopia;
    font-size: 18px;
    line-height: 1.7;
    display: block;
    margin-bottom: 20px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
