.table-suppliers {
  @extend %table;

  tr {
    border-bottom: 0!important;
  }

  td {
    position: relative;
    border-right: 1px solid $black-15;
    @include rem(padding, 10px 30px);
    div {
      display: block;
      img {
        margin: auto;
      }
    }
    &:last-child {
      border-right: none;
      padding-right: 0;
      div img {
        margin-right: 0;
      }
    }
    &:first-child {
      padding-left: 0;
      div img {
        margin-left: 0;
      }
    }
  }

  //mq
  @include mq($imac, false, max) {
    td {
      @include rem(padding-left, 25px);
      @include rem(padding-right, 25px);
    }
  }
  @include mq($macbook, false, max) {
    td {
      @include rem(padding-left, 20px);
      @include rem(padding-right, 20px);
    }
  }
  @include mq($ipad, false, max) {
    td {
      @include rem(padding, 7px 15px);
    }
  }
  @include mq($portrait, false, max) {
    td {
      @include rem(padding-top, 5px);
      @include rem(padding-bottom, 5px);
    }
  }
  @include mq($iphone, false, max) {
    td {
      display: table;
      width: 50%;
      float: left;
      border-right: none;
      border-bottom: 1px solid $black-15;
      @include rem(padding-top, 30px);
      @include rem(padding-bottom, 30px);
      text-align: center;
      @include rem(min-height, 80px);
      div {
        display: table-cell;
        vertical-align: middle;
        transition: background-color .2s ease-in-out;
        &:hover {
          background-color: rgba(white, .5);
        }
      }
      &:last-child, &, &:first-child {
        div img {
          margin: auto;
          @include rem(max-width, 80px);
          @include rem(max-height, 80px);
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.table-suppliers--footer {
  width: 100%;

  td {
    padding: 0;
  }

  a {
    position: relative;
    display: block;
    width: 100%;
    height: 66px;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    max-width: 70%;
    max-height: 70%;
  }

  @include mq($iphone, false, max) {
    td {
      width: 20%;
      min-height: auto;
    }

    img {
      max-width: 80%;
      max-height: 50%;
    }
  }
}

.table-suppliers-vertical {
  @extend %table;
  @extend %table-text;
  width: 100%;
  margin-left: 0!important;
  margin-right: 0!important;

  tbody {
    width: 100%;
  }

  tr {
    border: none;
  }

  td {
    padding: 30px;

    &.title,
    &.image {
      vertical-align: top;
    }

    &.desc {
      font-size: 0.85em;
    }
  }

  tr:nth-child(even) {
    background-color: #eeeeee;
  }

  @include mq($iphone, false, max) {
    td.title,
    td.image {
      width: 50%;
    }

    td.desc {
      width: 100%;
      border-top: 1px solid $black-15;
      border-bottom: 1px solid $black-15;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
    }

    td {
      display: inline-block;
      border-right: 0;
      @include rem(padding-top, 30px);
      @include rem(padding-bottom, 30px);
    }
  }
}

