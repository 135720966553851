.tabs {
	&:not(:last-child) {
		@include rem(margin-bottom, 35px);

		//mq
		@include mq($imac, false, max) {
			@include rem(margin-bottom, 30px);
		}
		@include mq($macbook, false, max) {
			@include rem(margin-bottom, 25px);
		}
		@include mq($ipad, false, max) {
			@include rem(margin-bottom, 20px);
		}
		@include mq($portrait, false, max) {
			@include rem(margin-bottom, 15px);
		}
		@include mq($iphone, false, max) {
			@include rem(margin-bottom, 10px);
		}
	}

	.alt-heading {
		text-align: center;
	}
}

.tab-label {
	display: inline-block;
	font-weight: normal;
}
a.tab-label {
	text-decoration: underline;
}

.tab-title {
	@include utopia(bold);
}

.tab-controls {
  border-bottom: 1px solid $black-15;
  border-top: 1px solid $black-15;
  color: $black-40;
  overflow: hidden;
  margin-bottom: 1em;
  padding: 0.3em 0;
  &:first-child { //quick fix
	  //margin-bottom: 0;
  }
  & + & {
	  //border-top: none;
  }
}

[rel*="js-tab-select"]{
  @include mq($iphone) {
  	display: none;
  }
}


.tab-controls-inner {
  height: 1.5em;
  text-align: justify;
}

.tab-controls-inner:after {
  content: '';
  display: inline-block;
  width: 100%;
}

.tab-control {
  cursor: pointer;
  display: none;
  transition: 0.3s;

  @include mq($iphone) {
    display: inline-block;
  }

  &:after {
    background: $black;
    bottom: 2px;
    content: '';
    height: 1px;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
  }

  &.is-active {
    color: $black;
    position: relative;

    &:after {
      opacity: 1;
    }
  }
}

.tab-content {
  &.is-active {
    display: block;
  }
}
