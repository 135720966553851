.overlay {}

.overlay__close {
  position: absolute;
  top: 5px;
  right: 20px;
  z-index: 2;
  color: $bright-color;
}

.overlay__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(lighten(black, 20), 0.25);
  transition: 0.5s;
  z-index: 10;

  .overlay:not(.is-active) & {
    opacity: 0;
    visibility: hidden;
  }
}

.overlay__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  max-height: 100%;
  overflow: auto;
  padding: 30px;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#f2f2f2, 0.8);
    content: '';
    z-index: 1;
    transition: 0.5s
  }

  .overlay:not(.is-submitted) &::after {
    opacity: 0;
    visibility: hidden;
  }
}


.overlay__content {
  width: 100%;
  height: 100%;
  padding: 5%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #fff;

  img {
    display:inline-block;
    max-height: 250px;
  }

  .overlay:not(.is-submitted) & {
    opacity: 0;
    visibility: hidden;
  }
}

.overlay__link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #fff;

  .overlay:not(.is-submitted) & {
    opacity: 0;
    visibility: hidden;
  }
}

.overlay__hide {
  .overlay:not(.is-submitted) & {
    display: none;
  }
}
