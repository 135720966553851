.hover-lists {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  max-width: 1400px;
}

.hover-lists__item {
  padding: 0;
  margin: 0;
}

.hover-lists__image {
  position: relative;
  overflow: hidden;

  &::before {
    display: block;
    padding-bottom: percentage(200 / 400);
    content: '';
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba($black-80, 0.5);
    z-index: 1;
    transition: 0.25s;
  }

  &:hover::after {
    background-color: rgba($red, 0.5);
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: 105%;
    max-height: 105%;
    min-width: 100%;
    min-height: 100%;
  }

  h2 {
    @include utopia;
    font-size: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: auto;
    border-bottom: 1px solid $white;
    z-index: 2;
    line-height: 1.2;
    text-align: center;
  }

  a {
    color: $white;
  }
}

.hover-lists__text {
  padding: 26px;

  @include breakpoint(medium up) {
    padding: 35px 50px;
  }

  @include breakpoint(large up) {
    padding: 35px 75px;

    .hover-lists.large-up-4 &,
    .hover-lists.large-up-3 & {
      padding: 35px;
    }
  }

  h3 {
    @include whitney;
    position: relative;
    font-size: 18px;
    letter-spacing: 0.28em;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 25px;
  }

  ul,
  ol {
    & > li {
      @include rem(padding-left, 15px);
      margin-bottom: 10px;

      a:hover {
        text-decoration: underline;
      }

      &:before {
        content: '›';
        color: $red;
        display: inline-block;
        @include rem(margin-right, 10px);
        @include rem(margin-left, -15px);
      }
    }
  }
}
