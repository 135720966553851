.previews {
  margin-top: 20px;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.preview {
  float: left;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  margin-bottom: 2.5rem;
}

.preview-inner {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 53%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  .icon {
    color: #e2001a;
  }
}

.preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 30px 0 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  text-align: center;
  background-image: linear-gradient(rgba(white, 0.0001), rgba(black, 0.5));
  overflow: auto;
  transition: 0.25s;
  color: white;

  > * {
    width: 100%;
  }

  h1 {
    @include whitney(500);
    width: 100%;
    margin-bottom: 15px;
    font-size: rem(15px);
    text-transform: uppercase;
    letter-spacing: .28em;
  }

  p {
    width: 100%;
    @include whitney(500);
    width: 100%;
    margin-bottom: 25px;
    font-size: rem(12px);
    text-transform: uppercase;
    letter-spacing: .28em;
  }
}

.preview-title {
  @include rem(font-size, 25px);
  @include utopia(normal, normal);
  padding-bottom: 30px;
}

.preview-link {
  @include rem(font-size, 14px);
  @include whitney(normal, normal);
  margin-top: 30px;
  display: inline-block;
  color: #0173af;

  &:hover {
    color: darken(#0173af, 10);
  }
}
