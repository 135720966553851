.typed {
  position: relative;

  > span:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  div {
    display: inline;
  }

  > span:last-child {
    opacity: 0;
    visibility: hidden;
  }
}

.typed-cursor{
  color: $blue;
  display: inline-block;
  @include rem(font-size, 50px);
  opacity: 1;
  animation: blink 0.7s infinite;
  line-height: 0;
  width: 0;

  //mq
  @include mq($imac, false, max) {
    @include rem(font-size, 47px);
  }
  @include mq($macbook, false, max) {
    @include rem(font-size, 42px);
  }
  @include mq($ipad, false, max) {
    @include rem(font-size, 35px);
  }
  @include mq($portrait, false, max) {
    @include rem(font-size, 30px);
  }
  @include mq($iphone, false, max) {
    @include rem(font-size, 25px);
  }
}

@keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}
