.top-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: black;
  color: white;
  font-size: 14px;
}

.top-nav__left {
  display: flex;

  @include mq($ipad, false, max) {
    border-bottom: 1px solid rgba(white, 0.5);
    flex-grow: 1;

    .masthead__search {
      flex-grow: 1;
    }
  }

  .list-social,
  .masthead__search {
    display: inline-block;
    border-right: 1px solid rgba(white, 0.5);
  }

  .masthead__search {
    display: flex;
    flex-wrap: wrap;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  .list-social > li > a {
    color: $white;
  }
}

.top-nav__list {
  display: flex;
  justify-content: flex-end;

  @include mq($ipad, false, max) {
    flex-grow: 1;
  }
}

.top-nav__item {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  border-left: 1px solid rgba(white, 0.5);

  a {
    display: block;
    padding: 5px 25px;
    line-height: 1;
    color: $white;

    @include mq($ipad, false, max) {
      padding: 5px 15px;
    }
  }

  i {
    width: 17px;
    margin-left: 5px;
    display: none;
    vertical-align: bottom;
  }

  @include mq($ipad, false, min) {
    .top-nav__trigger a {
      padding-right: 35px;
    }
  }

  &--contact {
    background-color: $red;

    i {
      display: inline-block;
    }
  }
}

.top-nav__dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 8px solid #D8D8D8;
  border-left: 8px solid #D8D8D8;
  border-right: 8px solid #D8D8D8;
  background-color: $white;
  z-index: 3;
  width: 278px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
  opacity: 0;
  visibility: hidden;
  transition: 0.25s;

  @include mq($ipad, false, max) {
    display: none;
  }

  .top-nav__item.open & {
    opacity: 1;
    visibility: visible;
  }

  li {
    width: 47%;
    padding-top: 1px;
    padding-bottom: 1px;
    border-bottom: 1px solid #d8d8d8;
  }

  li a {
    position: relative;
    font-weight: normal;
    display: block;
    width: 100%;
    letter-spacing: 0;
    text-transform: none;
    padding: 5px 5px 6px 15px;
    transition: 0.25s;
    color: $black;
  }

  a:before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 0;
    width: 0;
    border-width: 8px 4px 0px 4px;
    border-color: #D8D8D8 transparent transparent transparent;
    border-style: solid;
    transition: transform .3s cubic-bezier(0.070, 0.635, 0.415, 0.990);
    transform: translate(0, -50%) rotate(-90deg);

    @include mq($ipad, false, min) {
      content: '';
    }
  }

  a:hover {
    background-color: #f2f2f2;
    color: $red;
  }

  a:hover:before {
    border-color: black transparent transparent transparent;
  }
}

.top-nav__trigger__chevron {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  height: calc(100% + 1px);
  z-index: 1;
  cursor: pointer;

  @include mq($ipad, false, max) {
    display: none;
  }

  &:before {
    position: absolute;
    top: 52%;
    right: 15px;
    height: 0;
    width: 0;
    border-width: 8px 4px 0px 4px;
    border-color: $white transparent transparent transparent;
    border-style: solid;
    content: '';
    transition: transform .3s cubic-bezier(0.070, 0.635, 0.415, 0.990);
    transform: translate(-50%, -50%);
  }
}

