.half-map {
  display: flex;
  flex-wrap: wrap;
  background-color: $grey-f;
  margin-top: 50px;
}

.half-map__text {
  .text {
    padding: 50px;
    max-width: 450px;

    @include breakpoint(medium up) {
      padding: 75px;
    }
  }

  .text h2 {
    @include utopia(normal);
    font-size: 24px;
  }

  .text p {
    font-size: 18px;
  }
}

.half-map__map {
  display: flex;
  flex-wrap: wrap;

  .map-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .map {
    width: 100%;
    min-height: 300px;
    height: auto !important;
  }
}
