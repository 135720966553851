.accredations {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #eee;
  padding: 13px;

  @include breakpoint(medium up) {
    padding: 26px;
  }
}

.accredations__item {
  display: flex;
  padding: 13px;

  @include breakpoint(medium up) {
    padding: 26px;
  }
}

.accredations__item__inner {
  @include utopia;
  width: 100%;
  background-color: white;
  border-top: 1px solid $black;
  border-left: 1px solid $black;
  border-right: 1px solid $black;
  border-bottom: 5px solid $black;
  padding: 13px;
  transition: 0.25s;

  &:hover {
    border-color: $red;
  }

  @include breakpoint(medium up) {
    padding: 26px;
  }

  figure {
    position: relative;
  }

  figure::before {
    display: block;
    padding-bottom: percentage(200 / 400);
    content: '';
  }

  figure img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: 70%;
    max-height: 70%;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 45px;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
    text-align: center;
    transition: 0.25s;
  }

  &:hover h3 {
    color: $red;
    border-color: $red;
  }

  p {
    font-size: 16px;
  }

  a.btn {
    margin-top: 20px;
  }

  a.btn:hover {
    border-color: $red;
    background-color: $red;
  }
}

.accredations__item__text {
  overflow: auto;
  max-height: 200px;
}
